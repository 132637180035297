import React from 'react'
import { Row, Col, Image, Typography } from 'antd'
import './index.css'

const PartnerComponent = () => {

    const logosdata = [
        {
            img:'brand-1.png'
        },
        {
            img:'brand-2.png'
        },
        {
            img:'brand-3.png'
        },
        {
            img:'brand-4.png'
        },
        {
            img:'brand-5.png'
        },
        {
            img:'brand-6.png'
        },
        {
            img:'brand-7.png'
        },
        {
            img:'brand-8.png'
        },
        {
            img:'brand-9.png'
        },
        {
            img:'brand-10.png'
        },
        {
            img:'brand-11.png'
        },
        {
            img:'brand-12.png'
        },


        {
            img:'brand-5.png'
        },
        {
            img:'brand-6.png'
        },
        {
            img:'brand-7.png'
        },
        {
            img:'brand-8.png'
        },
        {
            img:'brand-9.png'
        },
        {
            img:'brand-10.png'
        },
      ]

  return (
    <div className='justify-center py-5'>
        <div className='w-93'>
            <Row align='middle'>
                <Col span={24}>
                    <Typography.Title level={2} className='brand-color text-center'>
                        We Focus on Product Engineering for...
                    </Typography.Title>
                </Col>
                {
                logosdata?.map((log,d)=>
                    <Col lg={{span:4}} md={{span:6}} xs={{span:12}} sm={{span:12}} className='border-btm pt-pb'>
                        <div className='text-center size-brand'>
                            <Image src={'/assets/newimg/'+log?.img} preview={false} />
                        </div>
                    </Col>
                    )
                }
            </Row>
        </div>
    </div>
  )
}

export {PartnerComponent}