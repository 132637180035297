import  {Link, NavLink} from "react-router-dom"
import {FacebookFilled,LinkedinFilled,TwitterSquareFilled,InstagramFilled,WhatsAppOutlined} from '@ant-design/icons';
import { Typography, Row, Col, Space, Divider, Image } from 'antd';
import { Breadcrumb, Menu } from 'antd';
import './footer.css'
const {Text}= Typography
const Footer=()=>
{
    const company = (
        <Menu
          className="bg-white pe-5"
          items={[
            {
              key: '1',
              label: (
                <Link to='/about-us'>
                  About us
                </Link>
              ),
            },
            {
              key: '2',
              label: (
                <Link to='/projects'>
                  Projects
                </Link>
              ),
            },
            {
              key: '3',
              label: (
                <Link to='/faqs'>
                  FAQS
                </Link>
              ),
            },
          ]}
        />
      )      
      const services = (
        <Menu
          className="bg-white"
          items={[
            {
              key: '1',
              label: (
                <Link to='/services/0'>
                    Software development
                </Link>
              ),
            },
            {
              key: '2',
              label: (
                <Link to='/services/1'>
                  Web development
                </Link>
              ),
            },
            {
              key: '3',
              label: (
                <Link to='/services/2'>
                  App development
                </Link>
              ),
            },
            {
                key: '4',
                label: (
                  <Link to='/services/3'>
                    Game development
                  </Link>
                ),
            },
            {
                key: '5',
                label: (
                  <Link to='/services/4'>
                    Blockchain
                  </Link>
                ),
            },
            {
              key: '6',
              label: (
                <Link to='/services/5'>
                  Desktop app development
                </Link>
              ),
            },
            {
              key: '7',
              label: (
                <Link to='/services/6'>
                  Digital marketing
                </Link>
              ),
            },
          ]}
        />
      )   
      
      const products = (
        <Menu
          className="bg-white text-white"
          items={[
            {
              key: '1',
              label: (
                <Link to='/products/0'>
                    Point Of Sale
                </Link>
              ),
            },
            {
              key: '2',
              label: (
                <Link to='/products/1'>
                  Cement Industry Software
                </Link>
              ),
            },
            {
              key: '3',
              label: (
                <Link to='/products/2'>
                  Accounting Software
                </Link>
              ),
            }
          ]}
        />
      )   
    return(
        <div className='footer py-5' style={{background:'#222528'}}>
            <div style={{width:'85%'}} className='justify-center'>
                <Breadcrumb className='drops'>
                    <Breadcrumb.Item className='footer-text footer-text-hover'>
                        <Link to='/' className="text-white">
                            Home
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item overlay={company} className="text-white" style={{cursor:'pointer'}}>
                        Company
                    </Breadcrumb.Item>
                    <Breadcrumb.Item overlay={services} className="text-white" style={{cursor:'pointer'}}>
                        Services
                    </Breadcrumb.Item>
                    <Breadcrumb.Item overlay={products} className="text-white" style={{cursor:'pointer'}}>
                        Products
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className='footer-text footer-text-hover'>
                        <Link to='/blogs' className="text-white">
                            Blogs
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className='footer-text footer-text-hover'>
                        <Link to='/contact-us' className="text-white">
                            Contact us
                        </Link>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <Divider style={{borderColor:'rgba(255, 255, 255, 0.1)'}} className='my-5'/>
            <Row gutter={[64,32]} style={{width:'90%'}}>
                <Col lg={{span:5}} md={{span:12}} sm={{span:24}} className='align-center' style={{borderRight:'1px solid rgba(255, 255, 255, 0.1)'}}>
                    <Space>
                        <FacebookFilled 
                            className='footer-text footer-text-hover icons text-white' 
                            onClick={()=>{window.location.href='https://www.facebook.com/replatechechnologies'}}
                        />
                        <LinkedinFilled 
                            className='footer-text footer-text-hover icons text-white' 
                            onClick={()=>{window.location.href='https://www.linkedin.com/company/replatech/'}}
                        />
                        <TwitterSquareFilled 
                            className='footer-text footer-text-hover icons text-white' 
                            onClick={()=>{window.location.href='https://twitter.com/replatech'}}
                        />
                        <InstagramFilled 
                            className='footer-text footer-text-hover icons text-white'
                            onClick={()=>{window.location.href='https://www.instagram.com/replatechnologies/'}}
                        />
                        <WhatsAppOutlined 
                            className='footer-text footer-text-hover icons text-white' 
                            onClick={()=>{window.location.href='https://api.whatsapp.com/send?phone=923363334161'}}
                        /> 
                    </Space>
                    
                </Col>
                <Col lg={{span:7}} md={{span:12}} sm={{span:24}} className='align-center' style={{borderRight:'1px solid rgba(255, 255, 255, 0.1)'}}>
                    <Text strong className='footer-text text-white text-justify'>
                      Repla Security and Technology innovates businesses with effective and adaptable digital solutions that satisfy the today’s competition and liberate the opportunities of the future. 
                    </Text>
                </Col>
                <Col lg={{span:7}} md={{span:12}} sm={{span:24}} className='align-center' style={{borderRight:'1px solid rgba(255, 255, 255, 0.1)'}}>
                    <Space direction='vertical'>
                        <Space className='footer-text' style={{alignItems:'flex-start'}}>
                            <Image src='/assets/mail-f.png' preview={false} style={{width: '18px',marginRight:"5px"}} />
                            <Space direction="vertical" size={3}>
                                <a href='mailto:info@replatechnologies.co.uk' >
                                  <Text className='footer-text footer-text-hover text-white'>
                                    info@replatechnologies.co.uk
                                  </Text>  
                                </a> 
                                <a href='mailto:hr.replatech@gmail.com' >
                                  <Text className='footer-text footer-text-hover text-white'>
                                    hr.replatech@gmail.com
                                  </Text>  
                                </a>  
                            </Space>                          
                        </Space>
                        <Space className='footer-text text-white'>
                            <Image src='/assets/phone-call.png' preview={false} style={{width: '18px',marginRight:"5px"}} /> 
                            <Space direction="vertical" size={3}>
                              <Text className='footer-text footer-text-hover text-white fontf fontsrobo'>
                                +92 336 3334161
                              </Text>
                              <Text className='footer-text footer-text-hover text-white fontf fontsrobo'>
                                +923369441595
                              </Text>
                            </Space>
                        </Space>
                        <Space className='footer-text' style={{alignItems:'flex-start'}}>
                          <Image src='/assets/location.png' preview={false} style={{width: '18px',marginRight:"5px"}} />  
                            <Space direction="vertical" size={3}>
                              <a href='https://maps.app.goo.gl/YLRZxRsURdZxbaTs5' >
                                <Text className='footer-text footer-text-hover text-white'>
                                  Islamabad, Pakistan
                                </Text>  
                              </a> 
                              <a href='https://maps.app.goo.gl/J2tHwQVE6QqLMuXA9' >
                                <Text className='footer-text footer-text-hover text-white'>
                                  Rawalpindi, Pakistan
                                </Text>  
                              </a> 
                            </Space>
                        </Space>
                    </Space>
                </Col>
                <Col lg={{span:5}} md={{span:12}} sm={{span:24}} className='align-center'>
                    <Space direction="vertical">
                      <Text strong className='footer-text text-white'>© 2023</Text>
                      <Text strong className='footer-text text-white'>Repla Technologies PVT LTD.</Text>
                      <Text strong className='footer-text text-white'>All Rights Reserved</Text>
                    </Space>
                </Col>
            </Row>
            </div>    
    )
}
export default Footer;