import { Typography } from "antd";
const {Title} =Typography
const SectionTop=({title,description,left})=>
    {
        return(
            <div className={left?'mb-5 py-3 flex-col':'mb-5 py-3 flex-col-center'} style={{width:'93%'}}>
                <Title level={2} className='brand-color mb-1 text-center'>{title}</Title>
                <Title level={4} className='mt-0 text-center'>{description}</Title>
                <div className='mt-1 d-flex' style={{width:"150px"}}>
                    <span style={{width:"90%"}} className="me-2 brand-border"></span>
                    <span style={{width:"10%"}} className="secondary-border"></span>
                </div>
            </div>    
        )
    }
export {SectionTop};