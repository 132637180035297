import './index.css'
import {Row, Col, Typography, Space, Image} from "antd"
const AllServices=()=>
    {
        const state=[
            {
                title:'Software Development',
                description:`
                At Repla, we specialize in full-stack development, web design, mobile app development, and digital marketing services to help businesses succeed in the digital world. Our team of skilled developers has expertise in web technologies such as Laravel, PHP, NodeJS, Angular, Bootstrap, ASP.NET, and ReactJS. We are also professional in desktop-based applications with expertise in C#. 
                `,
                bgImage:'software-light.png',
                icon1:'software.png',
                icon2:'software-white.png'
            },
            {
                title:'Website development',
                description:`
                    With Repla, businesses can rely on our expertise to create innovative and intuitive websites that ensure product quality and customer satisfaction, all resulting in a high conversion rate. We understand the full scope of the project as well as the technical requirements to create a digital product that meets our clients' needs.
                `,
                bgImage:'web-development-light.png',
                icon1:'web.png',
                icon2:'web-development-white.png'
            },
            {
                title:'App Development',
                description:`
                When it comes to mobile app development, we build iOS and Android applications that efficiently complement web solutions. We have developed over 1000+ mobile apps and ensure mobile app success by delivering professional UI and secure app code.
                `,
                bgImage:'mobile-development-light.png',
                icon1:'mobile-development.png',
                icon2:'mobile-development-white.png'
            },
            {
                title:'Game Development',
                description:'Repla provides game development services which includes building iOS, Android and Windows games',
                bgImage:'game-development-light.png',
                icon1:'game-development.png',
                icon2:'game-development-white.png'
            },
            {
                title:'Blockchain',
                description:'Repla provides Blockchain development services which includes banking, marketing, industry applications.',
                bgImage:'blockchain-light.png',
                icon1:'blockchain.png',
                icon2:'blockchain-white.png'
            },
            // {
            //     title:'Desktop App Development',
            //     description:``,
            //     bgImage:'desktop-app-light.png',
            //     icon1:'desktop-app.png',
            //     icon2:'desktop-app-white.png'
            // },
            {
                title:'UI/UX Design',
                description:'Repla’s innovative approach to UI/UX Design ensures customer satisfaction as they are meant for developing visually attractive, efficient digital interfaces that attracts customers. Repla understands the full scope of the project as well as the technical requirements to create a digital product. ',
                bgImage:'ui-light.png',
                icon1:'ui.png',
                icon2:'ui-white.png'
            },
            {
                title:'Digital Marketing',
                description:'Repla takes an innovative approach to digital advertising and marketing, focused on targeted customers at every level of the digital marketing.',
                bgImage:'digital-light.png',
                icon1:'digital.png',
                icon2:'digital-white.png'
            },
            {
                title:'Search Engine Optimization',
                description:`Repla has the most advanced testing platform in SEO and build calibrated search engine models against any existing search engine to ensure our clients' websites rank high on Google.`,
                bgImage:'seo-light.png',
                icon1:'seo.png',
                icon2:'seo-white.png'
            }
        ]
        return(
            <>
                <Row gutter={[2,2]} className='justify-center'>
                    {
                        state?.map((data,d)=>
                            <Col key={'services-'+d} lg={{span:8}} md={{span:12}}>
                                <div className="service-box animation h-100">
                                    <div className='service-info flex-col' style={{justifyContent:"center",textAlign:"center"}}>
                                        <Image src={'/assets/'+data?.icon1} alt='repla tech' preview={false} className='service-icon-black mb-3' width='95px'/>
                                        <Image src={'/assets/'+data?.icon2} alt='repla tech' preview={false} className='service-icon-white mb-3' width='95px'/>
                                        <Typography.Title level={3} className='secondary-color service-title'>{data?.title}</Typography.Title>
                                        <Typography.Text className='txt-white service-description justify-text'>
                                            {
                                                data?.description
                                            }
                                        </Typography.Text>
                                    </div>
                                    {/* <img src={'/assets/'+data?.bgImage} alt='repla tech' className='service-bg-image animation'/> */}
                                </div>
                            </Col>
                        )
                    }
                </Row>
               
            </>
        )
    }
export default AllServices;