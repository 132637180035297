import { NavLink } from 'react-router-dom';
import { SectionTop} from '../../ui-elements';
import Slider from "react-slick";
import { Typography, Image, Space } from 'antd';
import {ClockCircleOutlined, UserOutlined} from "@ant-design/icons"
import "./index.css"
const NewsSlider=()=>
{
    const news=[
        {
            title:'Your most definitve source for the technology and startup',
            date:'10 Feb 2023',
            url:'blog-1a.jpg',
        },
        {
            title:'Your most definitve source for technology and startup news',
            date:'10 Feb 2023',
            url:'blog-2.jpg',
        },
        {
            title:'Your most definitve source for technology',
            date:'10 Feb 2023',
            url:'blog-3.jpg',
        },
        {
            title:'Your most definitve source for technology and startup news',
            date:'10 Feb 2023',
            url:'blog-4.jpg',
        }
    ]
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 0
            }
          }
        ]
      }
    return(
          <>
            <div className="py-5" style={{overflowX:'hidden'}}>
                <div className='py-5 flex-col-center'>
                    <SectionTop title='News & Blogs' description=''/>
                    <div style={{width:'93%'}}>
                    <Slider {...settings}>
                        {
                            news?.map((news,n)=>{
                                return(
                                    <div className='px-2' key={'news-'+n}>
                                        <div className=" rounded-1 news-slider">
                                                <div className='news-slider-image-box'>
                                                    <Image src={'/assets/'+news?.url} preview={false} alt='bct' className='img-fluid one animation' style={{maxWidth:'100%',maxHeight:'100%',borderRadius:'12px'}}/>
                                                    <div className='two animation'>
                                                        <Image src={'/assets/plus.png'} alt='olive'/>
                                                    </div>
                                                    {/* <span className='tag px-3 py-1'>
                                                    <Typography.Text strong className='text-white'>
                                                        {
                                                            news?.type
                                                        }
                                                    </Typography.Text>
                                                    </span> */}
                                                </div>
                                                <Space direction='vertical' className='align-center py-3 px-2' style={{width:'100%'}}>
                                                    <Space direction='horizontal' size={10}>
                                                        <Space>
                                                            <ClockCircleOutlined 
                                                                style={{transform:'translateY(-4px)'}}
                                                                className='brand-color'
                                                            />
                                                            <Typography.Text className='my-0' strong>8 Jan, 2022</Typography.Text>
                                                        </Space>
                                                        <Space>
                                                            <UserOutlined 
                                                                style={{transform:'translateY(-4px)'}}
                                                                className='brand-color'
                                                            />
                                                            <Typography.Text className='my-0' strong>by Admin</Typography.Text>
                                                        </Space>
                                                    </Space>
                                                    <NavLink to='/blogs'>
                                                        <Typography.Title className='w-100 my-0 text-center news-heading' level={4}>
                                                            {
                                                                news?.title
                                                            }
                                                        </Typography.Title>    
                                                    </NavLink> 
                                                </Space>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Slider>
                    </div>
                </div>
            </div>
        </>
        )
    }
    
export default NewsSlider;
