const data=[
    {
        title:'Point Of Sale',
        img:"pos.jpg",
        paras:[            
            {
                title:'Repla POS System: A Comprehensive Solution for Retail Businesses',
                subhead:"Streamline Operations and Drive Growth with Repla's Advanced POS System",
                description:"A point of sale (POS) system is a powerful software package that can streamline the way you manage and operate your retail business. It is typically bundled with compatible hardware and can handle a variety of functions, such as sales transactions, inventory management, and customer relationship management. POS systems are a critical focus for marketers because they offer a strategic location for promoting high-margin products or services, and they can influence customers at various points in the sales funnel."
            },
            {
                description:"Traditionally, businesses set up POS systems near store exits to increase the rate of impulse purchases as customers leave. However, varying POS locations can provide retailers with more opportunities to micro-market specific product categories and reach customers at earlier stages of the sales funnel."
            },
            {
                description:"Using a POS system can be a game-changer for your business. It can save you time and money by streamlining processes, such as sales transactions, inventory management, and reporting. With an efficient sales programming, automated inventory reconciliation, expiration and ordering alerts, and theft prevention features, your business can run smoothly and efficiently."
            },
            {
                description:"At Repla, we offer tailored POS solutions for retail businesses of all sizes. Our systems can improve accuracy, grow your business with loyalty programs and rewards, and access an integrated CRM dashboard and e-commerce platform that caters to your customers' needs. With our POS solutions, you can breakdown comprehensive data for a thorough understanding of customer details and sales reports, which can be retrieved efficiently and organized by customer. Most systems also provide customized categories for optimizing comparisons and applying data to future planning."
            },
            {
                description:"In summary, a POS system is an essential tool for any retail business that wants to improve efficiency, increase sales, and enhance the customer experience. With a tailored POS solution from Repla, your business can streamline operations, improve accuracy, and grow your customer base."
            }
        ],
        slider:[
            {
                title:'Inventory management',
                description:"Inventory management software allows you to keep tabs on all your products. Some automated inventory software can connect with your sales data and let you know when an item is running low."
            },
            {
                title:'POS Reports',
                description:'POS reports give you a quick look into how much you’re selling and earning. With clear reports, you can sell more and make better business decisions.'
            },
            {
                title:'Employee management',
                description:'Team management software lets you know when your employees are working and how they’re performing. Your team can also use it to clock in and out, and some types of software can grant permissions so employees can get access to certain tasks.'
            }
        ],
        benefits:{
            title:"Our Significance",
            list:[
                "Repla creates POS system which speed up purchase transactions with quicker responding for better customer service.",
                "Repla Strengthen efficiency.",
                "Repla Manages stock at ease.",
                "Repla POS track staff’s activities easily.",
                "With Repla POS optimize checkout process.",
                "Generate accurate reports with Repla POS."
            ]
        },
        accordin:[
            {
                title:'Why should you use POS Software?',
                description:"The POS software links your customer service, inventory, accounting, payroll, and other departments within one program, which helps centralize your administrative tasks. Using a POS is tremendously time-saving and efficient compared with the alternative of using separate software to manage each area."
            },
            {
                title:'How much does POS Software Cost?',
                description:"The cost of a POS depends on the complexity, quantity and number of users of the software and hardware."
            }
        ]
    },

    {
        title:'Cement Industry Software',
        img:"cement-ind-soft.jpg",
        paras:[
            {
                title:'Repla- Digital Transformation Consultancy for Optimized Operations Management in Cement Industry',
                subhead:"Tailored Software and Automation Solutions for Cement Manufacturing Support Mechanism",
                description:"Digital transformation has become increasingly important for businesses across all industries, and the cement industry is no exception. As the market continues to evolve, optimizing operations management has become critical for cement manufacturers looking to improve performance, reduce costs, and increase efficiency."
            },
            {
                description:"Our enterprise digital transformation consultancy offers a range of tailored software and automation solutions that are specifically designed to meet the unique needs of the cement industry. By leveraging real-time data, our solutions enable constant monitoring and analysis of processes and assets, resulting in significant improvements in key performance metrics throughout the value chain."
            },
            {
                description:"Our comprehensive software systems are capable of managing the entire cement manufacturing support mechanism, from raw material grinding and preparation to processing and delivery of the final product. We also offer enterprise mission systems that enable effective management of equipment, maintenance, logistics, production, and personnel."
            },
            {
                description:"At our consultancy, we pride ourselves on delivering market-leading engineering, equipment, and service solutions that help our customers achieve their business objectives. Our team of solution consultants has extensive experience in the cement industry, and will work closely with you to review your business operations and provide a customized solution that meets your specific needs."
            },
            {
                description:"In addition to improving performance and reducing costs, our solutions are also designed to help cement manufacturers reduce their environmental impact. By leveraging cutting-edge technology and industry best practices, we help our customers achieve sustainable and responsible operations."
            },
            {
                description:"With our comprehensive solutions and expertise, we help cement manufacturers optimize their operations, increase efficiency, and achieve long-term success in today's rapidly changing market."
            }
        ],
        slider:[
            {
                title:'Efficiency',
                description:"Manage your operations and raw material requirements efficiently."
            },
            {
                title:'Delivery on time',
                description:'Deliver cement to your customers on time, every time.'
            },
            {
                title:'Cost effective',
                description:'Reduce your cost.'
            }
        ],
        benefits:{
            title:"Our Significance",
            list:[
                "Through cement industry software use power optimally.",
                "With Repla control your business end to end.",
                "With Repla keep your plant up and running 24/7.",
            ]
        },
        accordin:[
            {
                title:'How do you provide quality for our business?',
                description:"We deliver market-leading engineering, equipment and service solutions that enable our customers to improve performance, drive down costs and reduce environmental impact."
            },
            {
                title:'How do you provide the best solution for our business?',
                description:"We allow our expert team of solution consultants to review your business operations so that the best possible solution is given to you to meet all your industry specific needs."
            }
        ]
    },

    {
        title:'Accounting Software',
        img:"accounting-soft.jpg",
        paras:[
            {
                title:'Accounting Software Program - Streamline Your Financial Management',
                subhead:"Affordable and Easy-to-Use Accounting Applications",
                description:"Accounting software programs are essential tools that help businesses of all sizes to efficiently manage their financial transactions. These applications help organizations to automate their accounting processes, which saves time and reduces the risk of errors that can occur when manually entering data."
            },
            {
                description:"The core modules of accounting software include accounts payable and receivable, billing, and bookkeeping. Accounts payable involves the management of the organization's bills and payments to vendors and suppliers, while accounts receivable manages the collection of payments from customers. Bookkeeping involves the recording of financial transactions such as sales, purchases, and payments."
            },
            {
                description:"In addition to the core modules, accounting software also offers non-core modules that include debt collection, fees, timesheets, purchase reconciliation, payroll, and reporting. These modules provide businesses with a comprehensive suite of tools that enable them to manage their finances effectively."
            },
            {
                description:"There are two types of accounting software: cloud-based and desktop-based. Cloud-based accounting software is hosted on the internet, and users can access it from anywhere with an internet connection. Desktop-based accounting software is installed locally on a user's computer, and it is accessed through that device."
            },
            {
                description:"At Repla, we specialize in creating affordable, easy-to-use accounting applications that provide time-saving features. Our software includes automatic bank feeds that import transactions from a user's bank account directly into the accounting software, which eliminates the need for manual data entry. We also offer automated payment reminders, which help users stay on top of their accounts receivable and avoid late payments. Additionally, we provide online invoicing and payment acceptance, which makes it easy for customers to pay their bills and for businesses to receive payments."
            },
            {
                description:"We understand that financial reporting is critical for monitoring and understanding your business finances. Therefore, we create software with complete, customizable, real-time financial reporting. This feature allows users to generate reports that provide insight into their organization's financial health, enabling them to make informed decisions and plan for the future."
            },
            {
                description:"In summary, our accounting software is designed to help businesses streamline their financial processes, save time, and reduce errors. With our software's comprehensive suite of tools, users can manage their finances effectively, and with our customizable financial reporting, they can gain valuable insights into their business's financial performance."
            }
        ],
        slider:[
            {
                title:'Financial Report',
                description:"When choosing an accounting application, it's important to ensure that it includes the specific types of financial reports that your business needs. Comprehensive reporting features allow you to interpret financial data and make informed decisions for your business."
            },
            {
                title:'Distribution Business',
                description:'For e-commerce and distribution business, you need an application that enables with stock tracking and buy orders. If you want more advanced inventory features, look for software that integrates with a dedicated inventory management system.'
            },
            {
                title:'Wholesale Business',
                description:'Accounting software helps wholesale businesses grow smarter and faster. Get started today to send custom invoices, track your business expenses, and accept online payments. Accounting software in wholesale business offers seamless integrations to track inventory, oversee your ecommerce store and manage payroll directly from your account.'
            }
        ],
        benefits:{
            title:"Our Significance",
            list:[
                "Repla provides accounting software which helps you stay on top of your financial data.",
                "Repla manages your finances for the vital success of your business.",
                "Repla promotes data accuracy.",
                "Repla provides comprehensive insights.",
                "Repla simplifies payroll."
            ]
        },
        accordin:[
            {
                title:'On which accounting software attributes Repla focuses?',
                description:"Repla prioritizes easy collaboration between you and your accountant. We develop accounting software which are easy to install, simple to use, functional and easily integrates."
            },
            {
                title:'What is the purpose to use accounting software?',
                description:"The purpose to use accounting software is to automate manual processes. Accounting software speeds up accounting and lessens human error."
            }
        ]
    },
]
export default data