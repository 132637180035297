import { Col, Row, Image, Progress, Typography ,Space } from 'antd'
import './index.css'
import Videoslider from '../videoSlider'
const {Title}= Typography
function Technology() {

    const data = {
        title: "Repla tech",
        subtitle:"Repla",
        blocktext:[
            {
                innertitle:"Technology Growth",
                subinnertitle:"Our Expertise development and diversity workspace strategy accelerates business growth.",
                desc:"Our expertise team spans all fundamental technologies and structures, and advances to innovative technology developments. Our team is constantly open to sharing their expertise all around the globe about developing professional IT content. With years of experience in software development and a team of seasoned professionals, we offer a range of cutting-edge tools and technologies to help our clients stand out from the crowd.",
                desc1:"Our expertise in developing reliable and scalable software solutions allows us to combine deep industry knowledge with the latest IT advancements, resulting in custom solutions and products that perfectly align with the needs and behavior of their users. From concept to implementation, our end-to-end approach ensures that each project is tailored to our clients' unique requirements and is delivered on time and on budget. Whether you're looking to streamline your operations, enhance customer engagement, or gain a competitive edge in your industry, Repla has the experience and expertise to help you achieve your goals. Contact us today to learn more about how our innovative digital solutions can transform your business.",
            },
        ]

    }

  return (
    <div className='flex-col-center py-5 my-5'>
        <Row style={{width:'93%'}}>
            <Col span={24}>
                {/* <div className='technology'>
                    <div className='one'>
                        <Image src="assets/we-stand-shape.png" alt="bct"/>
                    </div>
                    <h2 style={{backgroundImage: "url(assets/tech-bg-1-1.jpg)"}} className="we-stand__top-title two">Repla Tech</h2>
                    <h2 style={{backgroundImage: "url(assets/tech-bg-1-1.jpg)"}} className="we-stand__top-title two two-2">Repla Tech</h2>
                </div> */}
            </Col>
        </Row>
        <Row gutter={[32,32]} style={{width:'93%'}} className='mt-5' >
            <Col lg={{span:16}} sm={{span:24}} xs={{span:24}}>
                {
                    data?.blocktext?.map((innertext,tb)=> 
                    <Space direction='vertical'>
                        <Title level={2} className=' brand-color'>{innertext?.innertitle}</Title>
                        <Title level={4} className='my-0'>
                            {
                                innertext?.subinnertitle
                            }
                        </Title>
                        <p style={{textAlign:"justify"}}>
                            {
                                innertext?.desc
                            }
                        </p>
                        <p style={{textAlign:"justify"}}>
                            {
                                innertext?.desc1
                            }
                        </p>
                    </Space>
                    )
                }
            </Col>
            <Col lg={{span:8}} sm={{span:24}} xs={{span:24}}>
                <Space direction='vertical' className='w-100' >
                    <Space direction='vertical' className='w-100 fontsrobo'>
                        <Title level={5} className='my-0'>Software Development</Title>
                        <Progress
                            strokeColor={{
                                '0%': 'rgb(228, 84, 84)',
                                '100%': 'rgb(189, 48, 48)',
                            }}
                            percent={97.9}
                            />
                    </Space>
                    <Space direction='vertical' className='w-100 fontsrobo'>
                        <Title level={5} className='my-0'>Innovation and Research</Title>
                        <Progress
                            strokeColor={{
                                '0%': 'rgb(228, 84, 84)',
                                '100%': 'rgb(189, 48, 48)',
                            }}
                            percent={95.5}
                            />
                    </Space>
                    <Space direction='vertical' className='w-100 fontsrobo'>
                        <Title level={5} className='my-0'>Customer Statisfaction</Title>
                        <Progress
                            strokeColor={{
                                '0%': 'rgb(228, 84, 84)',
                                '100%': 'rgb(189, 48, 48)',
                            }}
                            percent={99.9}
                            />
                    </Space>
                    <Space direction='vertical' className='w-100 fontsrobo'>
                        <Title level={5} className='my-0'>Quality Assurance and Testing</Title>
                        <Progress
                            strokeColor={{
                                '0%': 'rgb(228, 84, 84)',
                                '100%': 'rgb(189, 48, 48)',
                            }}
                            percent={97.9}
                            />
                    </Space>
                    <Space direction='vertical' className='w-100 fontsrobo'>
                        <Title level={5} className='my-0'>Data Analytics</Title>
                        <Progress
                            strokeColor={{
                                '0%': 'rgb(228, 84, 84)',
                                '100%': 'rgb(189, 48, 48)',
                            }}
                            percent={97.9}
                            />
                    </Space>
                    <Space direction='vertical' className='w-100 fontsrobo'>
                        <Title level={5} className='my-0'>Adaptability and Scalability</Title>
                        <Progress
                            strokeColor={{
                                '0%': 'rgb(228, 84, 84)',
                                '100%': 'rgb(189, 48, 48)',
                            }}
                            percent={97.9}
                            />
                    </Space>
                    <Space direction='vertical' className='w-100 fontsrobo'>
                        <Title level={5} className='my-0'>Customer Relationship Management</Title>
                        <Progress
                            strokeColor={{
                                '0%': 'rgb(228, 84, 84)',
                                '100%': 'rgb(189, 48, 48)',
                            }}
                            percent={93.9}
                            />
                    </Space>
                </Space>
            </Col>
        </Row>
    </div>
  )
}

export default Technology