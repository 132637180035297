import React from 'react'
import { Col, Row, Space, Input, Typography, Image } from 'antd'
import { Link } from 'react-router-dom'
import './index.css'
import {FacebookFilled,LinkedinFilled,TwitterSquareFilled,InstagramFilled,WhatsAppOutlined} from '@ant-design/icons';

const { Search } = Input;
const NewFooter = () => {

  const listdata = [
    {
      title:'Services',
      list:[
        {
          path:'/',
          listcontent:'Custom software development'
        },
        {
          path:'/',
          listcontent:'UI and UX design'
        },
        {
          path:'/',
          listcontent:'Web development'
        },
        {
          path:'/',
          listcontent:'Mobile development'
        },
        {
          path:'/',
          listcontent:'QA and software testing'
        },
        {
          path:'/',
          listcontent:'DevOp'
        },
        {
          path:'/',
          listcontent:'Cybersecurity'
        },
        {
          path:'/',
          listcontent:'IT staff augmentation'
        },
      ]
    },
    {
      title:'Domains',
      list:[
        {
          path:'/',
          listcontent:'Big data'
        },
        {
          path:'/',
          listcontent:'Data analytics'
        },
        {
          path:'/',
          listcontent:'Artificial intelligence'
        },
        {
          path:'/',
          listcontent:'NFT'
        },
        {
          path:'/',
          listcontent:'Blockchain'
        },
        {
          path:'/',
          listcontent:'Internet of things'
        },
        {
          path:'/',
          listcontent:'Embedded'
        },
        {
          path:'/',
          listcontent:'Robotics'
        },
      ]
    },
    {
      title:'About us',
      list:[
        {
          path:'/',
          listcontent:'About us'
        },
        {
          path:'/',
          listcontent:'Services'
        },
        {
          path:'/',
          listcontent:'Products'
        },
        {
          path:'/',
          listcontent:'Blogs'
        },
        {
          path:'/',
          listcontent:'Contact us'
        },
        {
          path:'/',
          listcontent:'Internship'
        },
        {
          path:'/',
          listcontent:'Products'
        },
        {
          path:'/',
          listcontent:'FAQs'
        },
      ]
    },
  ]

  const logosdata = [
    {
      img:'ISO-9001.svg'
    },
    {
      img:'ISO-27001-2.svg'
    },
    {
      img:'Google_Cloud_Partner-1.svg'
    },
    {
      img:'Aws_partner.svg'
    },
    {
      img:'Salesforce_Partner.svg'
    },
    {
      img:'Forbes.svg'
    },
  ]
  
  const brandlogo = [
    {
      img:'Microsoft_partner.svg'
    },
    {
      img:'shopify.svg'
    },
    {
      img:'IAOP.svg'
    },
  ]

  return (
    <div className='justify-center pt-5' style={{background:'#222528'}}>
      <div className='w-93'>
        <Row gutter={[64,24]} style={{justifyContent:'space-between'}}>
          <Col lg={{span:8}} md={{span:10}} xs={{span:24}} sm={{span:24}}>
            <Space direction='vertical' size={30}>
                <Typography.Title className='text-white' level={1}>
                  Let<sup class="brand-color">●</sup>s develop software together!
                </Typography.Title>
                <div>
                  <Typography.Title level={4} className='text-white'>
                    Contact Us
                  </Typography.Title>
                  <ul className='listtext'>
                    <li className='link'>
                      <Typography.Text>
                        Islamabad, Pakistan
                      </Typography.Text>
                      <Link to={'/'}>
                          <Typography.Text >
                            +92 336 3334161
                          </Typography.Text>
                      </Link>
                    </li>
                    <li className='link'>
                      <Typography.Text>
                        Rawalpindi, Pakistan
                      </Typography.Text>
                      <Link to={'/'}>
                          <Typography.Text>
                            +92 336 9441595
                          </Typography.Text>
                      </Link>
                    </li>
                  </ul>
                </div>
                <Space direction='vertical' size={15}>
                  <Typography.Title className='text-white' level={5}>
                    Repla Security and Technology innovates businesses with effective and adaptable digital solutions that satisfy the today’s competition and liberate the opportunities of the future. 
                  </Typography.Title>
                  <Search
                    placeholder="Enter your email address"
                    allowClear
                    enterButton="Sign Up"
                    className='btn-brand'
                    size="large"
                  />
                  <Typography.Text className='text-white fs-12'>
                    By signing up you agree to our <Link to={'/'} className='text-white'>Terms of Use</Link> and <Link to={'/'} className='text-white'>Privacy Policy</Link>, including the use of cookies and transfer of your personal information.
                  </Typography.Text>
                </Space>
            </Space>
          </Col>
          <Col lg={{span:15}} md={{span:14}} xs={{span:24}} sm={{span:24}}>
            <Row gutter={[24,24]}>
              {
                listdata?.map((lst,l)=>
                <Col lg={{span:8}} md={{span:12}} xs={{span:24}} sm={{span:24}}>
                  <div>
                  <Typography.Title level={4} className='text-white'>
                    {
                      lst?.title
                    }  
                  </Typography.Title>
                  <ul className='listtext'>
                    {
                      lst?.list?.map((inlst,t)=>
                      <li className='link mb-3'>
                        <Link to={'/'}>
                            <Typography.Text >
                              {
                                inlst?.listcontent
                              }
                            </Typography.Text>
                        </Link>
                      </li>
                      )
                    }
                  </ul>
                </div>
              </Col>
                )
              }
              {
                logosdata?.map((log,d)=>
                <Col lg={{span:4}} md={{span:6}} xs={{span:12}} sm={{span:12}}>
                  <Image src={'/assets/footer/'+log?.img} />
                </Col>
                )
              }
              {
                brandlogo?.map((brand,b)=>
                <Col lg={{span:6}} md={{span:6}} xs={{span:12}} sm={{span:12}}>
                  <Image src={'/assets/footer/'+brand?.img} />
                </Col>
                )
              }
              <Col lg={{span:6}} md={{span:6}} xs={{span:24}} sm={{span:24}}>
                <Space>
                  <div>
                    <Typography.Title level={5} className='fs-12 text-white'>
                      REVIEWED ON
                    </Typography.Title>
                    <Image src='/assets/footer/cu.svg' />
                  </div>
                  <div>
                    <Space size={3}>
                      <Image src='/assets/footer/star.png' className='star-s' preview={false}/>
                      <Image src='/assets/footer/star.png' className='star-s' preview={false}/>
                      <Image src='/assets/footer/star.png' className='star-s' preview={false}/>
                      <Image src='/assets/footer/star.png' className='star-s' preview={false}/>
                      <Image src='/assets/footer/star.png' className='star-s' preview={false}/>
                    </Space>
                    <Link to={'/'}>
                      <Typography.Title level={5} className='
                      fs-12 text-white'>
                        REVIEWED ON
                      </Typography.Title>
                    </Link>
                  </div>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
        <hr style={{background:"#fff",marginTop:'50px'}} />
        <Row gutter={[24,24]} align='middle'>
          <Col lg={{span:8}} md={{span:24}} xs={{span:24}} sm={{span:24}}>
            <div>
              <Image src='/assets/logo.png' width={'150px'} />
            </div>
          </Col>
          <Col lg={{span:8}} md={{span:24}} xs={{span:24}} sm={{span:24}}>
            <Typography.Text className='text-white'>
              © 2024 Repla Security & Technology. All Rights Reserved.
            </Typography.Text>
          </Col>
          <Col lg={{span:8}} md={{span:24}} xs={{span:24}} sm={{span:24}}>
            <Space style={{justifyContent:'end',width:"100%"}}>
                <FacebookFilled 
                    className='footer-icons' 
                    onClick={()=>{window.location.href='https://www.facebook.com/replatechechnologies'}}
                />
                <LinkedinFilled 
                    className='footer-icons' 
                    onClick={()=>{window.location.href='https://www.linkedin.com/company/replatech/'}}
                />
                <TwitterSquareFilled 
                    className='footer-icons' 
                    onClick={()=>{window.location.href='https://twitter.com/replatech'}}
                />
                <InstagramFilled 
                    className='footer-icons'
                    onClick={()=>{window.location.href='https://www.instagram.com/replatechnologies/'}}
                />
                <WhatsAppOutlined 
                    className='footer-icons' 
                    onClick={()=>{window.location.href='https://api.whatsapp.com/send?phone=923363334161'}}
                /> 
            </Space>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export {NewFooter}