export const data = 
    {
        title: 'Services we’re providing to our customers',
        subtitle: 'What we’re offering',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. quis nostrud exercitation ullamco laboris.',
        separatBlock:[
            {
                topicon: 'software-brand.png',
                path:"/services/0",
                blocktitle: 'Software development',
                blockdescription: 'Develop Quality Software with Innovative Techniques',
            },
            {
                topicon: 'web-development-brand.png',
                path:"/services/1",
                blocktitle: 'Web development',
                blockdescription: 'We specialize in developing websites for various industries',
            },
            {
                topicon: 'mobile-development-brand.png',
                path:"/services/2",
                blocktitle: 'Mobile App Development',
                blockdescription: 'Your Ultimate Partner for Mobile App Development',
            },
            {
                topicon: 'ui-brand.png',
                path:"/services/3",
                blocktitle: 'UI/UX Design',
                blockdescription: 'Create Visually Attractive Digital Interfaces with Our Expertise',
            },
            {
                topicon: 'digital-brand.png',
                path:"/services/4",
                blocktitle: 'Digital marketing',
                blockdescription: 'We takes a innovative approach to digital advertising and marketing',
            },
            {
                topicon: 'seo-brand.png',
                path:"/services/5",
                blocktitle: 'SEO',
                blockdescription: 'Our Effective SEO Strategies Elevating Your Brand Reputation',
            },
            {
                topicon: 'blockchain-brand.png',
                path:"/services/6",
                blocktitle: 'Blockchain',
                blockdescription: 'Our Effective SEO Strategies: Elevating Your Brand Reputation',
            },
            {
                topicon:'game-development-brand.png',
                path:"/services/7",
                blocktitle: 'Game Development',
                blockdescription: 'Our Effective SEO Strategies: Elevating Your Brand Reputation',
            },
            
        ]
    }
