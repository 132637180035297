import React from 'react'
import {Row, Col, Typography} from "antd"
import './index.css'
import {ArrowRightOutlined} from "@ant-design/icons"
const {Title}=Typography 
const ProjectCards = () => {
    const state=[
        {
            title:'Epic Chauffeur',
            description:'Platform Integration',
            bg:'project-1.png',
            path:'/',
        },
        {
            title:'NF Sound',
            description:'Platform Integration',
            bg:'project-2.png',
            path:'/',
        },
        {
            title:'Gangsta City',
            description:'Platform Integration',
            bg:'project-3.png',
            path:'/',
        },
        {
            title:'Golden Chauffeur',
            description:'Platform Integration',
            bg:'project-4.png',
            path:'/',
        },
        {
            title:'BDV',
            description:'Platform Integration',
            bg:'project-5.png',
            path:'/',
        },
        {
            title:'Kings Bet',
            description:'Platform Integration',
            bg:'project-6.png',
            path:'/',
        },
        {
            title:'Israel Tv',
            description:'Platform Integration',
            bg:'project-7.png',
            path:'/',
        },
        {
            title:'Fastreel By Movavi',
            description:'Platform Integration',
            bg:'project-8.png',
            path:'/',
        },
        {
            title:'Esquiretek',
            description:'Platform Integration',
            bg:'project-9.png',
            path:'/',
        },
        {
            title:'CRCST Exam Question',
            description:'Platform Integration',
            bg:'project-10.png',
            path:'/',
        },
        {
            title:'Bullion By Post',
            description:'Platform Integration',
            bg:'project-11.png',
            path:'/',
        },
        {
            title:'Amroz',
            description:'Platform Integration',
            bg:'project-12.png',
            path:'/',
        },
        {
            title:'Spot Lite',
            description:'Platform Integration',
            bg:'project-13.png',
            path:'/',
        },
        {
            title:'Sentinel Vaults',
            description:'Platform Integration',
            bg:'project-14.png',
            path:'/',
        },
        {
            title:'Tender Wise',
            description:'Platform Integration',
            bg:'project-15.png',
            path:'/',
        },
        {
            title:'Vector',
            description:'Platform Integration',
            bg:'project-16.png',
            path:'/',
        },
        {
            title:'Zoobla',
            description:'Platform Integration',
            bg:'project-17.png',
            path:'/',
        },
    ]
  return (
    <div className='justify-center'>
        <div style={{width:'93%'}} className='py-5'>       
            <Row gutter={[32,32]}>
                {
                    state?.map((data,d)=>
                    <Col xs={24} sm={24} md={12} lg={8}>
                        <div>
                            <div key={d} className='project-box flex-col-center rounded '>
                                <img src={'/assets/project/'+data?.bg} alt='repla' width={'100%'} className='one'/>
                                <div className="two animation"></div>
                                <div className="project-button px-4 py-4 justify-center shadow-sm bg-white">
                                    <div className="project-button-effect animation">
                                    </div>
                                    <div  className="project-button-circle animation">
                                        <ArrowRightOutlined className="brand-color"/>
                                    </div>
                                    <div className='my-0 project-button-text-cover'>
                                        <Title level={5} className='brand-color project-button-text my-0'>
                                            {
                                                data?.title
                                            }
                                        </Title>
                                        <Title level={3} className='project-button-text my-0'>
                                            {
                                                data?.description
                                            }
                                        </Title>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    )
                }
            </Row>
        </div>
    </div>
  )
}

export default ProjectCards