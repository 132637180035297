import React from 'react'
import { Card, Col, Image, Row, Space, Typography } from 'antd'
import './index.css'
const FocusProduct = () => {

    const data = [
        {
            img:'skinny-1.png',
            title:'ENTERPRISES',
            desc:'Increasing profitability and efficiency of businesses through digitalization of processes. We design and construct the cornerstone of your digital transformation.',
            authorimg:'about-us.jpg',
            authordesc:'"We operate what we build. We build for growth. And we have been doing IT for a decade now."',
            authortitle: <span>NENAD ILIJIĆ, <br />CEO</span>
        },
        {
            img:'skinny-2.png',
            title:'STARTUPS',
            desc:'From developing an MVP to a fully integrated product, our startup services deliver end-to-end. And we value outcomes over output.',
            authorimg:'about-us.jpg',
            authordesc:'"We operate what we build. We build for growth. And we have been doing IT for a decade now."',
            authortitle: <span>NENAD ILIJIĆ, <br />CEO</span>
        },
        {
            img:'skinny-3.png',
            title:'MSMES',
            desc:'We are result-driven, and our mission resonates with MSMEs looking for a technology partner to elevate and transform their business.',
            authorimg:'about-us.jpg',
            authordesc:'"We build business and tech empires through digitalization and digital transformations."',
            authortitle: <span>NENAD ILIJIĆ, <br />CEO</span>
        },
    ]

  return (
    <div className='justify-center py-5'>
        <div className='w-93'>
            <Row gutter={[32,32]} style={{justifyContent:"space-around"}}>
                <Col span={24}>
                    <Typography.Title level={2} className='brand-color text-center'>
                        We Focus on Product Engineering for...
                    </Typography.Title>
                </Col>
                {
                    data?.map((cardd,d)=>
                    <Col lg={{span: 7}} md={{span:8}} xs={{span: 24}} sm={{span: 24}}>
                        <Card 
                            className='text-center card-bg' 
                            cover={
                                <div className='back-card'>
                                    <img src={'/assets/newimg/'+cardd?.img} />
                                </div>
                            }
                        >
                            <Typography.Title level={2} className='brand-color'>
                                {
                                    cardd?.title
                                }
                            </Typography.Title>
                            <Typography.Text className='text-elip'>
                                {
                                    cardd?.desc
                                }
                            </Typography.Text>
                            <hr />
                            <Space size={20} style={{textAlign:'left'}} align='start'>
                                <Image src={'/assets/'+cardd?.authorimg} className='img-cir' preview={false} />
                                <div>
                                    <Typography.Text>
                                        {
                                            cardd?.authordesc
                                        }
                                    </Typography.Text>
                                    <Typography.Title title='5' className='fs-12'>
                                        {
                                            cardd?.authortitle
                                        }
                                    </Typography.Title>
                                </div>
                            </Space>
                        </Card>
                    </Col>
                    )
                }
            </Row>
        </div>
    </div>
  )
}

export {FocusProduct}