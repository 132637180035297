import { CheckCircleOutlined } from '@ant-design/icons'
export const DataProjectDetail = [
    {
        clientdetail:[
            {
                clienttitle: 'Clients:',
                clientname: 'Mike hardson'
            },
            {
                clienttitle: 'Category:',
                clientname: 'Technology, Ideas'
            },
            {
                clienttitle: 'Services:',
                clientname: 'IT consulting, Business'
            },
            {
                clienttitle: 'Web:',
                clientname: 'www.repla.com'
            },
        ],
        contentone:{
            onetitle:'Deployment service',
            onedescription:'Lorem ipsum is simply free text used by copytyping refreshing. Neque porro est qui dolorem ipsum quia quaed inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Aelltes port lacus quis enim var sed efficitur turpis gilla sed sit amet finibus eros. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the ndustry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries. Lorem Ipsum is simply dummy text of the new design printng and type setting Ipsum Take a look at our round up of the best shows coming soon to your telly box has been the is industrys. When an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing.'
        },

        contenttwo:{
            twotitle:'The challenge of project',
            twodescription:'Neque porro est qui dolorem ipsum quia quaed inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Aelltes port lacus quis enim var sed efficitur turpis gilla sed sit amet finibus eros. Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
        },

        contentthree:{
            threetitle:'The result of project',
            threedescription:'Neque porro est qui dolorem ipsum quia quaed inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Aelltes port lacus quis enim var sed efficitur turpis gilla sed sit amet finibus eros. Lorem Ipsum is simply dummy text of the printing and typesetting industry. When an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting.'
        },

        clientimg:'project/project-details-points-img.jpg',

        list:[
            {
                listicon:<CheckCircleOutlined />,
                listtext: 'Lorem Ipsum generators on the Internet tend uses a dictionary.'
            },
            {
                listicon:<CheckCircleOutlined />,
                listtext: 'The majority have alteration in some form of over 200 Latin words.'
            },
            {
                listicon:<CheckCircleOutlined />,
                listtext: 'There are many variations of passages of available slightly.'
            },
            {
                listicon:<CheckCircleOutlined />,
                listtext: 'We are providing across ome form our customer base.'
            },
        ],

        
    }
]