import {Row, Col, Typography, Space, Image} from "antd"
import './index.css'
const { Title, Text }=Typography
const ReplaIntro=()=>{

    const data = {
        textoverimg:"Repla is providing the best solution by our experts...",
        title:"Company Introduction",
        subtitle:"Repla - Innovative and Adaptable Digital Solutions for Modern Business",
        desciption:`
        Repla is a leading IT company that specializes in delivering top-quality services to clients across different industries. Our extensive experience in diverse sectors enables us to offer valuable insights and innovative solutions that guarantee customer satisfaction. At Repla we specialize in providing innovative and adaptable digital solutions that empower businesses to compete in today's fast-paced market and unlock new opportunities for growth.
        `,
        listpoint:[
            {
                icon:"checked.png",
                title:"Software Development"
            },
            {
                icon:"checked.png",
                title:"Website Development"
            },
            {
                icon:"checked.png",
                title:"Mobile App Development"
            },
            {
                icon:"checked.png",
                title:"Digital Marketing"
            },
            {
                icon:"checked.png",
                title:"Game development"
            },
            {
                icon:"checked.png",
                title:"Blockchains"
            },
            {
                icon:"checked.png",
                title:"UI/UX design"
            },
            {
                icon:"checked.png",
                title:"SEO"
            },

        ]
    }
    return (
            <div className="justify-center light-bg intro py-5">
                <Row gutter={[64,32]} style={{width:'93%',alignItems:"center"}} className='my-5' >
                    
                    <Col xl={{span:15}} lg={{span:24}} md={{span:24}} sm={{span:24}} xs={{span:24}} className='align-center'>
                        <Space direction='vertical' size={30}>
                            <div>
                                <Title level={2} className='brand-color'>{data?.title}</Title>
                                <Title level={4} className='my-0'>
                                    {data?.subtitle}
                                </Title>
                            </div>
                            <div>
                                {/* <Title level={4} className='brand-color'>
                                    {data?.subtitle2}
                                </Title> */}
                                <Text style={{textAlign:"justify",display:"block"}}>
                                    {data?.desciption}
                                </Text>
                            </div>
                            <Row gutter={[12,24]}>
                                {
                                    data?.listpoint?.map((listpt,pt)=>
                                        <Col sm={{span:12}} xs={{span:24}}>
                                            <Space>
                                                <img src={'/assets/'+listpt?.icon} width={'20px'}/>
                                                <Title level={5} className='my-0'>{listpt?.title}</Title>
                                            </Space>
                                        </Col>
                                    )
                                }
                            </Row>
                            {/* <Space className="align-center">
                                <div className='brand-border p-2' style={{borderRadius:'50%',borderWidth:'2px'}} >
                                    <Image 
                                        preview={false}
                                        src='/assets/ceo.jpg' 
                                        alt="repla-ceo"
                                        style={{borderRadius:'50%'}} 
                                    />
                                </div>
                                <Space direction='vertical' size={1}>
                                    <Title level={4} className='my-0'>Our CEO</Title>
                                    <p className="my-0">Co founder of Repla</p>
                                </Space>
                            </Space> */}
                        </Space>
                    </Col>
                    <Col xl={{span:9}} lg={{span:24}} md={{span:24}} sm={{span:24}} xs={{span:24}}>
                        <div className="borderVid">
                            {/* <Image src='/assets/intro.jpg' alt='repla' width={'100%'}/> */}
                            <video 
                                className='w-100 my-0'
                                autoplay="autoplay" 
                                loop="loop" 
                                muted="mute" controls='controls'>
                                <source 
                                    src='assets/videogallery/intro.mp4' 
                                    type="video/mp4" />
                            </video>
                            {/* <p className="py-4 px-3">
                                {data?.textoverimg}
                            </p> */}
                        </div>
                    </Col>
                </Row>
            </div>
    )
}
export default ReplaIntro