import {Row, Col, Typography} from "antd"
import './index.css'
import Slider from "react-slick";
const ReplaMission=()=>{
    const state=[
        {title:'Perfect solution for business demands',bg:'1-p.mp4'},
        {title:'Helping businesses around the world',bg:'2-p.mp4'},
        {title:'What is holding back the IT solution.',bg:'3-p.mp4'},
        {title:'Empowering youth through innovative technology',bg:'4-p.mp4'},
        {title:'Invest in research and development to stay ahead of emerging technologies',bg:'5-p.mp4'},
        {title:'Leverage technology to create social impact.',bg:'3-p.mp4'},
        {title:'Collaborate with innovative organizations to drive growth and mutual success',bg:'4-p.mp4'},
    ]

    var newslide = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 2000,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 900,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  initialSlide: 0
                }
              }
        ]
      }

    return (
            <div >
                {/* style={{backgroundImage:'url(/assets/technology.png), url(/assets/technology-2.png)',backgroundRepeat:'no-repeat',backgroundPosition:'10px 10px , center center'}} */}
                <div className="bg py-5" >
                    <div className="justify-center py-5 mb-5">
                        <Row gutter={[64,32]} style={{width:'93%'}} className='mission-top-section'>
                            <Col xl={{span:24}} sm={{span:24}} xs={{span:24}}>
                                <Typography.Title level={2} className='brand-color'>
                                    Technology Platform
                                </Typography.Title>
                                <Typography.Title level={4} className='my-0'>
                                    Repla delivering IT solutions with complete trust
                                </Typography.Title>
                                <Typography.Title level={5}  className="" style={{textAlign:"justify"}}>
                                    Repla innovates businesses with effective and adaptable digital solutions that satisfy the today’s competition and liberate the opportunities of the future. 
                                    Our innovative tools, team of professionals and years of word experience in software development gives us the benefit to face out from the crowd
                                </Typography.Title>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="justify-center">
                    <div style={{transform:'translateY(-160px)',width:'93%'}}>
                        <Slider {...newslide} >
                            {
                                state?.map((data,d)=>
                                    <div key={d}  className="my-5">
                                        <div  className='mission-box mx-3 py-5 h-100 flex-col-center rounded'>
                                            {/* <img src={'/assets/'+data?.bg} alt='repla' width={'100%'} className='one'/> */}
                                            <video 
                                                className='w-100 my-0'
                                                autoplay="autoplay" 
                                                loop="loop" 
                                                muted="muted" id="video" >
                                                <source 
                                                    src={'/assets/videogallery/'+data?.bg}
                                                    type="video/mp4" />
                                            </video>
                                            <div className="two animation"></div>
                                            <div className="mission-button px-5 py-4 justify-center shadow-sm bg-white">
                                                <div className="mission-button-effect animation"></div>
                                                <Typography.Title level={4} className='my-0 secondary-color mission-button-text'>
                                                    {
                                                        data?.title
                                                    }
                                                </Typography.Title>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </Slider>
                    </div>
                </div>
            </div>
    )
}
export default ReplaMission