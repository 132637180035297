import React from 'react'
import { Button, Image, Space, Typography } from 'antd'
import './index.css'
import Timer from '../Timer'
import { BreadCrumb } from '../../ui-elements'
import { NavLink } from 'react-router-dom'

const { Text, Title } = Typography

const ThankYou = () => {
  return (
    <>
      <BreadCrumb 
        title='Success' 
        description='Message submitted' 
        pageName='success' 
        breadcrumb={[{to:'/',name:'Home'}]}
        />
      <div className='py-5'>
          <Space className='w-100 text-center' direction='vertical' align='center' size={30}>
              <Image src='/assets/thank/thanku.png' preview={false} />
              <Title className='my-0' level={1}>Thank you for submitting!</Title>
              <Text>Your message has been sent!</Text>
              <NavLink to='/'>
                <Button 
                  type='primary' 
                  size='large'
                  className='brand-bg brand-border'
                  >
                    Go Home
                </Button>
              </NavLink>
              {/* <Timer /> */}
          </Space>
      </div>
    </>
  )
}


export default ThankYou