import React from 'react'
import { Image, Space, Typography, Card, Col, Row } from 'antd'
import './index.css'
import { NavLink } from 'react-router-dom'
const { Title, Text } = Typography
const CardNew = () => {
  return (
    <div className='justify-center my-5'>
        <Row gutter={24} style={{width:'93%'}}>
            <Col xs={24} sm={24} md={12} lg={8}>
                <Card className='shadow-c py-0 px-0 card-c'>
                    <Space className='w-100 py-3 px-3' align='start' size={20}>
                        <div className='bg-icon '>
                            <img src='/invoice.png' className='py-3 px-3' />
                        </div>
                        <div>
                            <Title level={2} className='my-1 text-main'>INVOICE</Title>
                            <Title level={5} className='my-2'>$ 256</Title>
                            <Title level={5} className='my-1'>Aug 10 2023</Title>
                        </div>
                    </Space>
                    <div className='justify-around w-100'>
                        <NavLink to={'/'}>
                            <Image src='/print.png' />
                        </NavLink>
                        <NavLink to={'/'}>
                            <Image src='/view.png' />
                        </NavLink>
                        <NavLink to={'/'}>
                            <Image src='/download.png' />
                        </NavLink>
                    </div>
                </Card>
            </Col>
        </Row>
    </div>
  )
}

export {CardNew}