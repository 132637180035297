import {Row, Col, Typography} from "antd"
import Slider from 'react-slick';
import {ArrowRightOutlined} from "@ant-design/icons"
import './index.css'
import { NavLink } from "react-router-dom";
const {Title}=Typography 
const ReplaProjects=()=>{
    const state=[
        {
            title:'Epic Chauffeur',
            description:'Platform Integration',
            bg:'project-1.png',
            path:'/',
        },
        {
            title:'NF Sound',
            description:'Platform Integration',
            bg:'project-2.png',
            path:'/',
        },
        {
            title:'Gangsta City',
            description:'Platform Integration',
            bg:'project-3.png',
            path:'/',
        },
        {
            title:'Golden Chauffeur',
            description:'Platform Integration',
            bg:'project-4.png',
            path:'/',
        },
        {
            title:'BDV',
            description:'Platform Integration',
            bg:'project-5.png',
            path:'/',
        },
        {
            title:'Kings Bet',
            description:'Platform Integration',
            bg:'project-6.png',
            path:'/',
        },
        {
            title:'Israel Tv',
            description:'Platform Integration',
            bg:'project-7.png',
            path:'/',
        },
        {
            title:'Fastreel By Movavi',
            description:'Platform Integration',
            bg:'project-8.png',
            path:'/',
        },
        {
            title:'Esquiretek',
            description:'Platform Integration',
            bg:'project-9.png',
            path:'/',
        },
        {
            title:'CRCST Exam Question',
            description:'Platform Integration',
            bg:'project-10.png',
            path:'/',
        },
        {
            title:'Bullion By Post',
            description:'Platform Integration',
            bg:'project-11.png',
            path:'/',
        },
        {
            title:'Amroz',
            description:'Platform Integration',
            bg:'project-12.png',
            path:'/',
        },
        {
            title:'Spot Lite',
            description:'Platform Integration',
            bg:'project-13.png',
            path:'/',
        },
        {
            title:'Sentinel Vaults',
            description:'Platform Integration',
            bg:'project-14.png',
            path:'/',
        },
        {
            title:'Tender Wise',
            description:'Platform Integration',
            bg:'project-15.png',
            path:'/',
        },
        {
            title:'Vector',
            description:'Platform Integration',
            bg:'project-16.png',
            path:'/',
        },
        {
            title:'Zoobla',
            description:'Platform Integration',
            bg:'project-17.png',
            path:'/',
        },
    ]
    var settings = {
        dots: true,
        autoplay: true,
        infinite: true,
        autoplaySpeed: 3000,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 0
            }
          }
        ]
      }
    return (
            <div style={{display:'flex',flexDirection:'column',alignItems:'center',overflowX:'hidden'}} className='py-5 light-bg w-100'>
                <div className="justify-center py-5 w-100">
                    <Row gutter={[64,32]} style={{width:'93%'}}>
                        <Col xl={{span:12}} lg={{span:24}} md={{span:24}} sm={{span:24}} xs={{span:24}}>
                            <Typography.Title level={2} className="brand-color">
                                Recently Completed Work
                            </Typography.Title>
                            <Typography.Title level={4} className='my-0'>
                                 Our recently completed projects list
                            </Typography.Title>
                        </Col>
                        <Col xl={{span:12}} lg={{span:24}} md={{span:24}} sm={{span:24}} xs={{span:24}} className='align-center'>
                            <Typography.Title level={5} className="text-justify">
                                We’ve excelled our experience in an extensive range of industries to deliver valuable insights and offer our clients with the best IT services. Our focus is product quality.
                                We’ve defied expectancies and embraced the insurmountable, and we’re nevertheless just getting warmed up. Repla combines leading-aspect technology with human focused designs to develop next-era digital experiences to ensure customer’s satisfaction.
                            </Typography.Title>
                        </Col>
                    </Row>
                </div>
                <div style={{width:'93%'}} className='py-5'>
                    <Slider {...settings}>
                        {
                            state?.map((data,d)=>
                                <div className="px-3">
                                    <NavLink to={'/projects'}>
                                        <div key={d} className='project-box flex-col-center rounded '>
                                            <img src={'/assets/project/'+data?.bg} alt='repla' width={'100%'} className='one'/>
                                            <div className="two animation"></div>
                                            <div className="project-button px-4 py-4 justify-center shadow-sm bg-white">
                                                <div className="project-button-effect animation">
                                                </div>
                                                <div  className="project-button-circle animation">
                                                    <ArrowRightOutlined className="brand-color"/>
                                                </div>
                                                <div className='my-0 project-button-text-cover text-center'>
                                                    <Title level={3} className='brand-color project-button-text my-0'>
                                                        {
                                                            data?.title
                                                        }
                                                    </Title>
                                                    {/* <Title level={3} className='project-button-text my-0'>
                                                        {
                                                            data?.description
                                                        }
                                                    </Title> */}
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            )
                        }
                    </Slider>
                </div>
            </div>
    )
}
export default ReplaProjects