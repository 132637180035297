import {Row, Col, Typography, Space, Image} from "antd"
import { BreadCrumb } from "../../ui-elements"
import Profiles from '../../components/profiles';
import './index.css'
const {Title}=Typography
const Team=()=>{
    return (
        <>
            <BreadCrumb 
                title='Repla Team'  
                pageName='Repla team' 
                breadcrumb={[{to:'/',name:'Home'}]}
                />
            <div className="justify-center intro py-5 my-5">
                <Row gutter={[32,64]} style={{width:'93%'}}>
                    <Col xl={{span:12}} lg={{span:24}} md={{span:24}} sm={{span:24}} xs={{span:24}} className='align-center'>
                        <Space direction='vertical' size={30}>
                            <div>
                                <Title level={5} className='brand-color'>Let's Introduce</Title>
                                <Title level={1} className='my-0'>
                                    Our Team
                                </Title>
                            </div>
                            <div>
                                <p style={{textAlign:"justify"}}>
                                    Repla is a leading software platform that specializes in managing datacenters, high-speed networks, and distributed systems. Our platform offers a comprehensive solution for managing your production, staging, and testing environments with guaranteed low latency to your users.
                                </p>
                                <p style={{textAlign:"justify"}}>
                                    At Repla, we understand the importance of reliable performance and support, which is why we offer instant and powerful team analytics to help you monitor and manage your platform's performance effectively. Whether you have 10 users today or a million users in the future, our team does the heavy lifting to maintain consistent performance levels.
                                </p>
                                <p style={{textAlign:"justify"}}>
                                    Our advanced routing and strategically positioned infrastructure provide the speed and reliability that you can depend on. We pride ourselves on being faster than our competitors in 65% of locations globally, making us the obvious choice for your business. So if you're looking for a software platform that can help you manage your infrastructure with ease, look no further than Repla. Our platform is designed to provide robust performance, reliability, and analytics features that will help your business succeed. So if you're looking for a software partner that can help you accelerate your growth and achieve your goals, look no further than our company. Contact us today to learn more about our software development services and how we can help you achieve success in the digital age.
                                </p>
                            </div>
                        </Space>
                    </Col>
                    <Col xl={{span:12}} lg={{span:24}} md={{span:24}} sm={{span:24}} xs={{span:24}}>
                            <Image src='/assets/team.png' alt='bct-team' width={'100%'}/>
                    </Col>
                </Row>
            </div>
            <Profiles/>
        </>
    )
}
export default Team