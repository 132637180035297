import React from "react"
import {Row, Col, Typography, Space, Image} from "antd"
import './index.css'
const {Title,Text}=Typography

const AboutRepla=()=>{

    const data = {
        title:  <Space direction='vertical'>
                    <Text>Engaging Digital Transformation for</Text>
                    <Text className='text-center dblock'>Fast-Growing Businesses </Text>
                </Space>,
        subtitle:<Space direction='vertical'>
                    <Text>Providing Innovative Technology Solutions and Expert</Text>
                    <Text className='text-center dblock'>Brand  Image to Achieve Business Goals</Text>
                </Space>,
        contentdesc:[
            {
                icon:'manufacturing-brand.png',
                title:"Developing Expert Brand Image to Enhance Recognition and Belief",
                desc:"Focus on providing high-quality technology solutions and services to showcase expertise and build a reputation as an industry leader"
            },
            {
                icon:'mobile-banking-brand.png',
                title:"Embracing Complexity to Gain a Competitive Edge",
                desc:"Develop tailored software solutions that enable businesses to embrace complexity, enhance productivity, and gain a competitive edge in their respective industries."
            },
        ]

    }
    return (
            <div className="justify-center intro py-5 my-5">
                <Row gutter={[32,32]} style={{width:'93%'}}>
                    <Col xl={{span:15}} lg={{span:24}} md={{span:24}} sm={{span:24}} xs={{span:24}} className='align-center'>
                        <Space direction='vertical' size={30}>
                            <div className="text-center">
                                <Title level={1} className='my-0'>
                                    {data?.title}
                                </Title>
                                <Title level={3}>
                                    {data?.subtitle}
                                </Title>
                            </div>

                            <Row gutter={[32,32]}>
                                {
                                    data?.contentdesc?.map((cont,c)=>
                                    <Col sm={{span:12}} xs={{span:24}}>
                                        <Space direction="vertical">
                                            <img src={'/assets/'+cont?.icon} width={'90px'}/>
                                            <Title level={5} style={{height: '50px'}}>{cont?.title}</Title>
                                            <p style={{textAlign:"justify"}}>{cont?.desc}</p>
                                        </Space>
                                    </Col>
                                    )
                                }
                            </Row>
                            {/* <Space className="align-center">
                                <div className='brand-border p-2' style={{borderRadius:'50%',borderWidth:'2px'}} >
                                    <Image 
                                        preview={false}
                                        src='/assets/ceo.jpg' 
                                        alt="bct-ceo"
                                        style={{borderRadius:'50%'}} 
                                    />
                                </div>
                                <Space direction='vertical' size={1}>
                                    <Title level={4} className='my-0'>Our CEO</Title>
                                    <p className="my-0">Co founder of BCT</p>
                                </Space>
                            </Space> */}
                        </Space>
                    </Col>
                    <Col xl={{span:9}} lg={{span:24}} md={{span:24}} sm={{span:24}} xs={{span:24}}>
                        <div className="about-left align-center">
                            <div className="brand-bg">
                            </div>
                            <Image src='/assets/intro-1.jpg' alt='bct' className="img" width={'95%'} preview={false}/>
                        </div>
                    </Col>
                </Row>
            </div>
    )
}
export default AboutRepla