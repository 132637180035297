import React, { useState } from 'react'
import { Row, Col, Space, Typography, Image } from 'antd'
import './index.css'
import { Link } from 'react-router-dom'

const TechnologyDomain = () => {

    const [currentButton,setCurrentButton]= useState(0)

    const bgimg = [
        {
            img:'ml-img.svg'
        },
        {
            img:'bi-img.svg'
        },
        {
            img:'ar-img.png'
        },
        {
            img:'iot-img.svg'
        },
        {
            img:'rtc-img.svg'
        },
        {
            img:'blk-img.svg'
        },
        {
            img:'nft-img.svg'
        },
        {
            img:'mv-img.svg'
        },
    ]

    const data= {
        head:[
            {
                title:"Machine Learning (ML)",
            },
            {
                title:"Business Intelligence (BI)",
            },
            {
                title:"AR/VR/MR",
            },
            {
                title:"Internet of Things (IoT)",
            },
            {
                title:"Real-time communication (RTC)",
            },
            {
                title:"Blockchain",
            },
            {
                title:"NFT",
            },
            {
                title:"Metaverse",
            },
        ],
        descriptiobody:[
            {
                title:'Machine Learning (ML)',
                cardcontent:[
                    {
                        icon:'ml-dl-1.svg',
                        title:'Deep Learning'
                    },
                    {
                        icon:'ml-ds-1.svg',
                        title:'Data Science'
                    },
                    {
                        icon:'ml-cv-1.svg',
                        title:'Computer Vision'
                    },
                    {
                        icon:'ml-nlp-1.svg',
                        title:'Natural Language Processing'
                    },
                ]
            },

            {
                title:'Business Intelligence (BI)',
                cardcontent:[
                    {
                        icon:'bi-dw-1.svg',
                        title:'Data warehouse (DWH)'
                    },
                    {
                        icon:'bi-ee-1.svg',
                        title:'ETL/ELT'
                    },
                    {
                        icon:'bi-dl-1.svg',
                        title:'Data lakes'
                    },
                    {
                        icon:'bi-oc-1.svg',
                        title:'OLAP cubes'
                    },
                    {
                        icon:'bi-da-1.svg',
                        title:'Data analytics'
                    },
                    {
                        icon:'bi-rtr-1.svg',
                        title:'Real-time reports'
                    },
                ]
            },

            {
                title:'AR/VR/MR',
                cardcontent:[
                    {
                        icon:'ar-vr-1.svg',
                        title:'VR online stores'
                    },
                    {
                        icon:'ar-vrt-1.svg',
                        title:'VR training apps'
                    },
                    {
                        icon:'ar-vrg-1.svg',
                        title:'VR games, tours and presentations'
                    },
                    {
                        icon:'ar-mb-1.svg',
                        title:'Marker-based / less AR'
                    },
                    {
                        icon:'ar-gps-1.svg',
                        title:'GPS-based AR'
                    },
                    {
                        icon:'ar-pa-1.svg',
                        title:'Projection AR'
                    },
                ]
            },
            {
                title:'Internet of Things (IoT)',
                cardcontent:[
                    {
                        icon:'iot-cd-1.svg',
                        title:'Connected devices'
                    },
                    {
                        icon:'iot-rs-1.svg',
                        title:'Remote sensors'
                    },
                    {
                        icon:'iot-sh-1.svg',
                        title:'Smart homes'
                    },
                    {
                        icon:'iot-t-1.svg',
                        title:'Telematics'
                    },
                    {
                        icon:'iot-iom-1.svg',
                        title:'IoMT'
                    },
                    {
                        icon:'iot-il-1.svg',
                        title:'IIoT'
                    },
                ]
            },
            {
                title:'Real-time communication (RTC)',
                cardcontent:[
                    {
                        icon:'rtc-vc-1.svg',
                        title:'Video conferencing'
                    },
                    {
                        icon:'rtc-rs-1.svg',
                        title:'Live streaming'
                    },
                    {
                        icon:'rtc-mm-1.svg',
                        title:'Mobile messaging'
                    },
                    {
                        icon:'rtc-fs-1.svg',
                        title:'File sharing'
                    },
                    {
                        icon:'rtc-vo-1.svg',
                        title:'Voice over Internet Protocol (VoIP)'
                    },
                ]
            },
            {
                title:'Blockchain',
                cardcontent:[
                    {
                        icon:'b-ce-1.svg',
                        title:'Crypto exchange'
                    },
                    {
                        icon:'b-cw-1.svg',
                        title:'Crypto wallets'
                    },
                    {
                        icon:'b-sc-1.svg',
                        title:'Smart contracts'
                    },
                    {
                        icon:'b-da-1.svg',
                        title:'Decentralized applications (dApps)'
                    },
                    {
                        icon:'b-de-1.svg',
                        title:'Decentralized exchanges (DEX)'
                    },
                    {
                        icon:'b-cb-1.svg',
                        title:'Custom blockchain solutions'
                    },
                ]
            },
            {
                title:'NFT',
                cardcontent:[
                    {
                        icon:'nft-nm-1.svg',
                        title:'NFT marketplace'
                    },
                    {
                        icon:'nft-na-1.svg',
                        title:'3D NFT avatars'
                    },
                    {
                        icon:'nft-wn-1.svg',
                        title:'Wearable NFT clothing & accessories'
                    },
                    {
                        icon:'nft-ng-1.svg',
                        title:'NFT gifts & merchandise'
                    },
                    {
                        icon:'nft-nc-1.svg',
                        title:'NFT crypto artworks'
                    },
                    {
                        icon:'nft-am-1.svg',
                        title:'NFT art museums'
                    },
                ]
            },
            {
                title:'Metaverse',
                cardcontent:[
                    {
                        icon:'meta-1.svg',
                        title:'Metaverse avatars'
                    },
                    {
                        icon:'meta-3d-1.svg',
                        title:'3D virtual replicas & digital twins'
                    },
                    {
                        icon:'meta-ai-1.svg',
                        title:'AI non-player characters (NPCs)'
                    },
                    {
                        icon:'meta-vp-1.svg',
                        title:'Metaverse virtual property'
                    },
                    {
                        icon:'meta-ec-1.svg',
                        title:'Metaverse events & concerts'
                    },
                    {
                        icon:'meta-market-1.svg',
                        title:'Metaverse marketplaces'
                    },
                ]
            },
        ],
        
        
    }

  return (
    <div className='justify-center bg-domain'>
        {
            bgimg?.map((bgi,i)=>
            <div className={i===currentButton?'bg-tech bgactive':'bg-tech'} 
                style={{backgroundImage: `url(/assets/newimg/${bgi?.img})`}}>
            </div>
            )
        }
        <div className='w-93'>
            <Row gutter={[24,24]}>
                <Col lg={{span:24}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <Typography.Title level={1} className='text-white mb-5'>
                        Technological domains
                    </Typography.Title>
                </Col>
                <Col lg={{span: 8}} md={{span: 8}} xs={{span: 24}} sm={{span: 24}}>
                {
                    data?.head?.map((button,b)=>
                        <div className={b===currentButton?"Repla-button-nw py-3 active-bordered":"Repla-button-nw py-3"} onClick={()=>{setCurrentButton(b)}}>
                            <div className="one brand-bg animation" style={b===currentButton?{height:'100%'}:{}}></div>
                            <Typography.Title level={5} className={b===currentButton?'my-0 two text-white':'my-0 two text-inactive'}>{button?.title}</Typography.Title>
                        </div>
                    )
                }  
                </Col>      
                <Col lg={{span: 16}} md={{span: 16}} xs={{span: 24}} sm={{span: 24}}>
                    {
                        data?.descriptiobody?.map((det,dp)=>
                            <Row gutter={[24,24]} key={dp} className={currentButton===dp? 'active-content':'inactive-content'}>
                                <Col lg={{span:24}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                                    <Typography.Title level={3}>
                                        <Link to={'/'} className='brand-color'>
                                            {
                                                det?.title
                                            }
                                        </Link>
                                    </Typography.Title>
                                </Col>
                                {
                                    det?.cardcontent?.map((cd,d)=>
                                    <Col lg={{span: 12}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}} key={d} >
                                        <Space style={{borderBottom:'1px solid #fff'}} className='w-100 p-3'>
                                            <Image src={'/assets/newimg/'+cd?.icon} preview={false} />
                                            <Typography.Title level={5} className='text-white m-0'>
                                                {
                                                    cd?.title
                                                }
                                            </Typography.Title>
                                        </Space>
                                    </Col>
                                    )
                                }
                            </Row>
                        )
                    }
                </Col>        
            </Row>
        </div>
    </div>
  )
}

export {TechnologyDomain}