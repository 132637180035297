import React from 'react';
import { NavLink } from 'react-router-dom';
import Slider from "react-slick";
import { Typography, Image, Space } from 'antd';
import {ClockCircleOutlined, UserOutlined} from "@ant-design/icons"
import "../index.css"

const { Title, Text } = Typography
const RecentBlog=()=>
{
    const news=[
        {
            banner:'ui-dev.jpg',
            date:10,
            mnth:'aug',
            title:'blog about Angular',
            desc:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Itaque voluptatum voluptas voluptatem labore.'
        },
    ]
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 0
            }
          }
        ]
      }
    return(
          <>
            <div className='py-3'>
            <Slider {...settings}>
                        {
                            news?.map((dat,n)=>{
                                return(
                                    <div className='px-2' key={'news-'+n}>
                                        <div className='my-3 news-slider rounded-1'>
                                            <div>
                                                <img src={'/assets/service/'+dat?.banner} className='w-100' />
                                            </div>
                                            <Space className='w-100 py-3' size={20} align='start'>
                                                <div className='datediv text-center'>
                                                    <Title level={5} className='my-0 p-2'>{dat?.date}</Title>
                                                    <div className='brand-bg'>
                                                        <Title level={5} className='my-0 p-2 px-3 text-white text-capitalize'>
                                                            {
                                                                dat?.mnth
                                                            }
                                                        </Title>
                                                    </div>
                                                    
                                                </div>
                                                <Space direction='vertical' size={10}>                        
                                                    <Title level={5} className='brand-color text-capitalize my-0'>{dat?.title}</Title>
                                                    <Text>
                                                        {
                                                            dat?.desc
                                                        }
                                                    </Text>
                                                    <NavLink to='/blogs'>
                                                        Learn more... 
                                                    </NavLink> 
                                                </Space>
                                            </Space>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </Slider>
            </div>
        </>
        )
    }
    
export default RecentBlog;
