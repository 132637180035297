import React from 'react'
import { Row, Col, Typography, Image, Space } from 'antd'
import './index.css'

const { Title } = Typography

const OurWork = () => {
  return (
    <div className="justify-center light-bg intro py-5">
        <Row gutter={[32,32]} style={{alignItems:"center",justifyContent:"space-around",width:'93%'}} className='my-5'>
            <Col xl={{span:12}} lg={{span:24}} md={{span:24}} sm={{span:24}} xs={{span:24}}>
                <div class="position-relative text-center">
                    <img src="assets/about/home-trust.jpg" class="img-fluid" />
                </div>
            </Col>
            <Col xl={{span:12}} lg={{span:24}} md={{span:24}} sm={{span:24}} xs={{span:24}}>
                <Space direction='vertical' size={10}>
                    <Title level={1} class="brand-color">Our Work</Title>
                    <Space direction='vertical'>
                        <p style={{textAlign: "justify"}}>
                            Our mission is to partner with innovative organizations at every level, providing them with the technology solutions they need to accelerate their growth and achieve their goals. We are committed to providing our clients with the best possible software products and services to help them succeed in today's fast-paced digital world.
                        </p>
                        <p style={{textAlign: "justify"}}>
                            At our company, we take pride in our ability to collaborate with our clients to understand their unique business needs and deliver customized software solutions that meet their requirements. Our team of experienced software developers, engineers, and designers work together to develop innovative software solutions that empower our clients to achieve their goals and stay ahead of the competition.
                        </p>
                    </Space>
                </Space >
            </Col>
        </Row>
    </div>
  )
}

export {OurWork}