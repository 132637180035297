const Dataindustry = [
    {
        firstimage: "business-from-two-img.jpg",
        title:"IT services customized for your industry",
        subtitle: "Industries we’re serving",
        shapicon:"section-title-shape.png",
        listItem:[
            {
                iconBrand:"software-red-1.png",
                iconWhite:"software-white-1.png",
                title: "Software Development",
                desc:"Repla provides custom software development services tailored to the specific needs of businesses, helping them create efficient and innovative solutions"
            },
            {
                iconBrand:"e-com-red.png",
                iconWhite:"e-com-white.png",
                title: "E-Commerce",
                desc:"Repla assists e-commerce businesses in building and optimizing their online platforms, enhancing user experiences and driving sales."
            },
            {
                iconBrand:"hospital-red.png",
                iconWhite:"hospital-w.png",
                title: "Healthcare",
                desc:"Repla offers IT solutions to healthcare providers, aiding in electronic health record management, telemedicine applications, and data security."
            },
            {
                iconBrand:"mobile-banking-brand.png",
                iconWhite:"mobile-banking-white.png",
                title: "Finance and Banking:",
                desc:"Repla's expertise helps financial institutions with fintech solutions, digital banking platforms, payment gateways, and risk management systems."
            },
            {
                iconBrand:"manufacturing-brand.png",
                iconWhite:"manufacturing-white.png",
                title: "Manufacturing",
                desc:"Repla provides custom software development services tailored to the specific needs of businesses, helping them create efficient and innovative solutions"
            },
            {
                iconBrand:"retail-red.png",
                iconWhite:"retail-white.png",
                title: "Retail",
                desc:"Repla helps retail businesses enhance customer engagement through mobile apps, POS systems, and inventory management tools"
            },
            {
                iconBrand:"hat-red.png",
                iconWhite:"hat-white.png",
                title: "Hospitality and Tourism",
                desc:"Repla assists in creating online booking systems, property management software, and customer engagement apps for the hospitality and tourism sectors."
            },
            {
                iconBrand:"education-brand.png",
                iconWhite:"education-white.png",
                title: "Education",
                desc:"Repla offers e-learning platforms, student management systems, and educational apps to institutions looking to modernize their teaching methods."
            },
            {
                iconBrand:"logistics-red.png",
                iconWhite:"logistics-white.png",
                title: "Logistics and Transportation",
                desc:"Repla provides logistics companies with solutions for route optimization, fleet management, and supply chain visibility."
            }, 
            {
                iconBrand:"utilities-engery-red.png",
                iconWhite:"utilities-engery-white.png",
                title: "Energy and Utilities",
                desc:"Repla supports energy companies in optimizing operations through IoT-enabled devices, energy management systems, and data analytics."
            }, 
            {
                iconBrand:"real-estate-red.png",
                iconWhite:"real-estate-white.png",
                title: "Real Estate",
                desc:"Repla develops real estate management software, property listing platforms, and virtual property tours to aid real estate businesses."
            }, 
            {
                iconBrand:"media-entertainment-red.png",
                iconWhite:"media-entertainment-white.png",
                title: "Media and Entertainment",
                desc:"Repla helps media companies create content distribution platforms, streaming services, and digital asset management systems."
            },            
        ]
    }
]

export default Dataindustry