import React from 'react'
import './index.css';
import {BreadCrumb} from "../../ui-elements/breadCrump"
import { OfferProduct, ProductBlock } from '../../components/ProductComponents'
import Feedback from '../../components/feedback';

const Products=()=>{
    return (
        <>
            <BreadCrumb 
                title='Products' 
                description='We Can Help You Succeed' 
                pageName={'products'}  
                breadcrumb={[{to:'/',name:'Home'}]}
                />
            <ProductBlock />
            <OfferProduct />
            <Feedback/>
        </>
    )
}
export default Products