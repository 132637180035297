import ReplaProjects from '../../components/projects';
import { BreadCrumb } from '../../ui-elements/index';
import Videoslider from '../../components/videoSlider/index'
import ProjectCards from '../../components/ProjectCards';
const Projects=()=>
    {
        
        return   <>
            <BreadCrumb title='Recent Projects'  pageName='About Us' breadcrumb={[{to:'/',name:'Home'}]}/>
                <Videoslider />
                <ProjectCards />
            {/* <ReplaProjects/> */}
        </>
    }
export default Projects;
