import {Typography} from "antd"
import { Link } from "react-router-dom"
import './index.css'
const {Title}=Typography
const ButtonOne=({title, to})=>{
    return (
        <Link to={to || '/'} className="btn-one">
            {title || 'Click Me'}
        </Link>
    )
}
export {ButtonOne}