import React,{ useState } from "react";
import {Row, Col, Typography,Space, Image} from "antd"
import './index.css'
import Slider from "react-slick";


const {Title} = Typography
const ChooseNew=()=>{
    const [currentButton,setCurrentButton]= useState(0)

    const state=[
        {title:'Perfect solution for business demands',bg:'mission-1.jpg'},
        {title:'Helping businesses around the world',bg:'mission-2.jpg'},
        {title:'What is holding back the IT solution.',bg:'mission-3.jpg'},
        {title:'Empowering youth through innovative technology',bg:'mission-4.jpg'},
        {title:'Invest in research and development to stay ahead of emerging technologies',bg:'mission-5.jpg'},
        {title:'Leverage technology to create social impact.',bg:'mission-6.jpg'},
        {title:'Collaborate with innovative organizations to drive growth and mutual success',bg:'mission-7.jpg'},
    ]

    var newslide = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 1000,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 900,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  initialSlide: 0
                }
              }
        ]
      }


      
    const data= {
        head:[
            {
                title:"Our Mission",
            },
            {
                title:"Our Vision",
            },
            {
                title:"Our History",
            }
        ],
        descriptiobody:[
            {
                description:"At Repla, we strive to provide top-notch IT services and products that guarantee customer satisfaction. We are dedicated to delivering innovative solutions that help businesses achieve their goals."
            },
            {
                description:"Our vision is to empower youth by giving them IT opportunities. We believe in using innovative technology to help businesses grow and stay competitive in the market."
            },
            {
                description:"Over the years, Repla has established itself as a leading IT company that offers quality products and services. Our innovative solutions have helped many businesses achieve remarkable growth and profit. We work with innovative organizations to help them accelerate their growth and achieve their goals."
            }
        ],
        
        
    }

    const slidedata = [
            {
                titleslid: 'Our Mission',
                desc:'At Repla, we strive to provide top-notch IT services and products that guarantee customer satisfaction. We are dedicated to delivering innovative solutions that help businesses achieve their goals.',
                img:'tab1.jpg',
                btp:
                [
                    {
                        pt: "Focus on providing high-quality IT services and products that exceed customer expectations"
                    },
                    {
                        pt: "Continuously innovate and improve IT solutions to meet evolving business needs"
                    },
                    {
                        pt: "Offer customized IT solutions that are tailored to each client's unique requirements and goals"
                    },
                    {
                        pt: "Invest in research and development to stay ahead of market trends and emerging technologies"
                    },
                ],
                
            },
            {
                titleslid: 'Our Vision',
                desc:'Our vision is to empower youth by giving them IT opportunities. We believe in using innovative technology to help businesses grow and stay competitive in the market.',
                img:'tab2.jpg',
                btp:
                [
                    {
                        pt: "Focus on providing high-quality IT services and products that exceed customer expectations"
                    },
                    {
                        pt: "Continuously innovate and improve IT solutions to meet evolving business needs"
                    },
                    {
                        pt: "Offer customized IT solutions that are tailored to each client's unique requirements and goals"
                    },
                    {
                        pt: "Invest in research and development to stay ahead of market trends and emerging technologies"
                    },
                ],
            },
            {
                titleslid: 'Our History',
                desc:'Over the years, Repla has established itself as a leading IT company that offers quality products and services. Our innovative solutions have helped many businesses achieve remarkable growth and profit. We work with innovative organizations to help them accelerate their growth and achieve their goals.',
                img:'tab3.jpg',
                btp:[
                    {
                        pt: "Continuously innovate and improve IT solutions to meet evolving business needs"
                    },
                    {
                        pt: "Collaborate with innovative organizations to drive growth and mutual success"
                    },
                    {
                        pt: "Expand service offerings to meet the needs of a broader range of businesses and industries"
                    },
                    {
                        pt: "Leverage past successes and case studies to attract new clients and build brand recognition"
                    },
                ],
            }
    ]

    return (
        <div className="justify-center">
            <div style={{width:'93%'}}>
                <div>
                    <div className="justify-center">
                        <Row gutter={[32,32]} style={{width:'93%'}} className='mission-top-section'>
                            <Col xl={{span:24}} lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}} className='align-center'>
                                <Space direction='vertical' className="w-100" size={40}>
                                    <div className="text-center">
                                        <Title level={2} className='brand-color'>Why Prioritize Repla?</Title>
                                        <Title level={4} className='my-0'>
                                        Repla - Providing Top-Notch IT Services - Empowering Business through Innovative Technology
                                        </Title>
                                    </div>
                                    <Row gutter={[8,0]}>
                                        {
                                            data?.head?.map((button,b)=>
                                            
                                            <Col sm={{span:8}} xs={{span:24}} md={{span:8}} key={b}>
                                                <div className="Repla-button py-3" onClick={()=>{setCurrentButton(b)}}>
                                                    <div className="one brand-bg animation" style={b===currentButton?{height:'100%'}:{}}></div>
                                                    <Title level={5} className='my-0 two' style={b===currentButton?{color:'white'}:{}}>{button?.title}</Title>
                                                </div>
                                            </Col>
                                            )
                                        }
                                    </Row>
                                    <div>
                                        {
                                            data?.descriptiobody?.map((descrp,dp)=>
                                                <p key={dp} className={currentButton===dp? 'active-tabs':'inactive-tabs'}>
                                                    {descrp?.description}
                                                </p>
                                            )
                                        }
                                    </div>
                                </Space>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="justify-center mb-5">
                    <div style={{transform:'translateY(-40px)',width:'93%'}}>
                        <Slider {...newslide} >
                            {
                                slidedata?.map((im,d)=>
                                    <div key={d}  className="my-5">
                                        <div className="text-center">
                                            <Title level={2}  className='my-0'>{im?.titleslid}</Title>
                                            <p>
                                                {im?.desc}
                                            </p>
                                        </div>
                                        <div className="spacewidth">
                                            <img src={'/assets/'+im?.img} className="w-200"/>
                                            <div className="w-100 mt-2">
                                                {
                                                    im?.btp?.map((pts,p)=>
                                                    <Space style={{alignItems:"flex-start",flexWrap:'nowrap'}} key={p}>
                                                        <Image src='/assets/tick.png' alt="bct" style={{width: "15px"}}/>
                                                        <p className="my-0 fs-12" style={{display:"inline-block"}}>
                                                            {
                                                                pts.pt
                                                            }
                                                        </p>
                                                    </Space>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    )
}
export {ChooseNew}