import React from 'react'
import 'antd/dist/antd.less'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
// import TopSlip from '../components/topslip'
import Navbar from '../components/navbar/index'
import Footer from "../components/footer"
import FeatureBrand from "../components/feature/FeatureBrand"
import HomePage from '../pages/homePage'
import AboutUs from '../pages/about';
import Faq from '../pages/faq'
import Projects from '../pages/projects'
import Services from '../pages/services'
import Products from '../pages/product'
import ServicesDetail from '../pages/services/services detail'
import Gallery from "../pages/gallery/Gallery"
import NotFound404 from '../pages/notFound404'
import Offering from '../pages/offering/Offering'
// import Feedback from '../components/feedback'
import NewsArtical from '../pages/newsartical/NewsArtical'
import ContactUs from '../pages/contactUs'
import Blogs from '../pages/blogs'
import Team from '../pages/team'
import ProjectDetail from '../pages/projectDetail/ProjectDetail'
import ServiceFirstBlock from '../components/servicefirst/ServiceFirstBlock'
import Question from '../components/questions'
import Sher from '../components/sher'
import ProductDetails from '../pages/product/ProductDetails'
import { InterneeForm } from '../pages/InterneeForm'
import ThankYou from '../components/ThankYou'
import { NewFooter } from '../components'
function Routing(){    

    return (
        <Router>
            {/* <TopSlip/> */}
            <Navbar/>
                <Routes>
                    <Route path="/" element={<HomePage />}/>
                    <Route path='about-us' element={<AboutUs/>}/>
                    <Route path='faqs' element={<Faq/>}/>
                    <Route path='projects' element={<Projects/>}/>
                    <Route path='bct-team' element={<Team/>}/>
                    <Route path='services' element={<Services/>}/>
                    <Route path="services">
                        <Route path=":serviceID" element={<ServicesDetail />} />
                    </Route> 
                    <Route path='products' element={<Products/>}/>
                    <Route path="products">
                        <Route path=":productID" element={<ProductDetails />} />
                    </Route>    
                    <Route path='contact-us' element={<ContactUs/>}/> 
                    <Route path='message-success' element={<ThankYou/>}/> 
                    <Route path='interneeform' element={<InterneeForm/>}/> 
                    <Route path='*' element={<NotFound404/>}/>
                    <Route path='gallery' element={<Gallery/>}/>
                    <Route path='offering' element={<Offering/>}/>
                    <Route path='newsartical' element={<NewsArtical/>}/>
                    <Route path='blogs' element={<Blogs />} />
                    <Route path='projectdetail' element={<ProjectDetail/>} />
                    <Route path='servicefirstblock' element={<ServiceFirstBlock/>} />
                    <Route path='question' element={<Question/>} />
                    <Route path='sher' element={<Sher/>} />
                </Routes>
            {/* <FeatureBrand/> */}
            <NewFooter/>
        </Router>    
    )
}

export default Routing;