import React from 'react'
import { Row, Col, Image, Typography, Space } from 'antd'
import './index.css'

const { Title } = Typography

const WhoWe = () => {
  return (
    <div className="justify-center intro py-5 my-5">
        <Row gutter={[32,32]} style={{width:'93%'}}>
            <Col xl={{span:9}} lg={{span:24}} md={{span:24}} sm={{span:24}} xs={{span:24}}>
                <div className="about-left-1 align-center">
                    <Image src='/assets/intro.jpg' alt='bct' className="img" width={'95%'}/>                    
                    <div className="brand-bg">
                    </div>
                </div>
            </Col>
            <Col xl={{span:15}} lg={{span:24}} md={{span:24}} sm={{span:24}} xs={{span:24}} className='align-center'>
                <Space direction='vertical' size={30}>
                    <div>
                        <Title level={1} className='my-0'>
                            Who we are
                        </Title>
                    </div>
                    <div>
                        <p style={{textAlign:"justify"}}>
                            Repla a leading software development company delivering customized digital solutions to clients worldwide. Our team of over 200+ expert developers, designers, and innovators has designed and delivered over 2000+ digital solutions. This allows us to launch next-generation applications and solutions that are both efficient and secure. Our team of developers, designers, and innovators are located across the globe, ensuring that we can deliver exceptional solutions to our clients.
                        </p>
                    </div>
                    <Row gutter={[12,24]}>
                        <Col span={24}>
                            <Title level={3} className='brand-color m-0'>Progressive Activities</Title>
                        </Col>
                        <Col sm={{span:12}} xs={{span:24}}>
                            <Space direction="vertical">
                                <img src='/assets/manufacturing-brand.png' width={'90px'}/>
                                <Title level={5} className='my-0'>Network </Title>
                                <p>
                                    We sponsor enterprise events to facilitate networking and innovation. Our corporation culture is firmly rooted in diversity and innovation
                                </p>
                            </Space>
                        </Col>
                        <Col sm={{span:12}} xs={{span:24}}>
                            <Space direction="vertical">
                                <img src='/assets/mobile-banking-brand.png' width={'90px'}/>
                                <Title level={5} className='my-0'>Embracement</Title>
                                <p>
                                    We hire regardless of age, gender, race, religion, or gender orientation, believing that diversity is intrinsic to success. 
                                </p>
                            </Space>
                        </Col>
                    </Row>
                    {/* <Space className="align-center">
                        <div className='brand-border p-2' style={{borderRadius:'50%',borderWidth:'2px'}} >
                            <Image 
                                preview={false}
                                src='/assets/ceo.jpg' 
                                alt="bct-ceo"
                                style={{borderRadius:'50%'}} 
                            />
                        </div>
                        <Space direction='vertical' size={1}>
                            <Title level={4} className='my-0'>Our CEO</Title>
                            <p className="my-0">Co founder of BCT</p>
                        </Space>
                    </Space> */}
                </Space>
            </Col>
            
        </Row>
    </div>
  )
}

export {WhoWe}