import React from 'react'
import { Row, Col, Typography } from 'antd'
import Slider from 'react-slick'
import './index.css'

const { Title } = Typography;
const BusinessPartner = () => {

    const brand = [
        {
            brandimg: 'brand/brand-1.jpg',
        },
        {
            brandimg: 'brand/brand-2.jpg',
        },
        {
            brandimg: 'brand/brand-3.png',
        },
        {
            brandimg: 'brand/brand-4.png',
        },
        {
            brandimg: 'brand/brand-5.jpg',
        },
        {
            brandimg: 'brand/brand-3.png',
        },
        {
            brandimg: 'brand/brand-2.jpg',
        },
    ]

    var businesspartner = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        autoplay:true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 0
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 0
            }
          }
        ]
      }


  return (
    <div>
        <Row gutter={[32,32]} justify='center' style={{marginTop:'5rem',marginBottom:'8rem'}} className='text-center'>
            <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                <Title level={3}>
                    The business partners
                </Title>
            </Col>
            <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                <Slider {...businesspartner}>
                    {
                        brand?.map((brand, b)=>
                            <div className='px-3 brandimg'>
                                <img src={"assets/"+brand?.brandimg} alt="" />
                            </div>
                        )
                    }
                </Slider>
            </Col>
        </Row>
    </div>
  )
}

export default BusinessPartner