export const data = 
    {
        title: 'Products we’re providing to our customers',
        subtitle: 'What we’re offering',
        description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. quis nostrud exercitation ullamco laboris.',
        separatBlock:[
            {
                topicon: 'sale.png',
                path:"/products/0",
                blocktitle: 'Point Of Sale',
                blockdescription: 'Develop Quality Software with Innovative Techniques',
            },
            {
                topicon: 'software.png',
                path:"/products/1",
                blocktitle: 'Cement Industry Software',
                blockdescription: 'We specialize in developing websites for various industries',
            },
            {
                topicon: 'accounting.png',
                path:"/products/2",
                blocktitle: 'Accounting Software',
                blockdescription: 'Your Ultimate Partner for Mobile App Development',
            },
            
        ]
    }
