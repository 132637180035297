import AboutRepla from '../../components/aboutRepla'
import Profiles from '../../components/profiles';
import BusinessPartner from '../../components/businessPartner';
import { BreadCrumb } from '../../ui-elements/index';
import { CoreValue, OurWork, WhoWe } from '../../components';
import WhyBCT from '../../components/whyBCT';
const AboutUs=()=>
    {
        
        return   <>
            <BreadCrumb title='About Repla' description='Your Business Partner' pageName='About Us' breadcrumb={[{to:'/',name:'Home'}]}/>
            <AboutRepla/>
            <BusinessPartner />
            <WhoWe />
            <Profiles/>
            <CoreValue />
            <WhyBCT />
            <OurWork />
        </>
    }
export default AboutUs;
