import { Col, Row } from 'antd'
import React from 'react'
import LeftBlogDetail from './LeftBlogDetail'
import RightBlogBlock from './RightBlogBlock'

const BlogDetail = () => {
  return (
    <div className='justify-center py-5'>
        <Row gutter={[24,24]}  style={{width:'93%'}}>
          <Col xs={24} sm={24} md={24} lg={18}>
              <LeftBlogDetail />
          </Col>
          <Col xs={24} sm={24} md={24} lg={6}>
              <RightBlogBlock />
          </Col>
        </Row>
    </div>
  )
}

export {BlogDetail}