import './index.css'
import {Row, Col, Typography, Space, Image} from "antd"
import { useState } from 'react'
const Moto=({noTranslate})=>
    {
        const state=
            [
                {
                    title:'Warranty',
                    description:'We provide warranty. The fulfillment of our obligations has been repeatedly confirmed by the feedback of our customers',
                    imgage1:'/warranty-brand-color.png',
                    imgage2:'/warranty-white.png',
                },
                {
                    title:'Approach',
                    description:'Starting with negotiations and until the completion of the process, you communicate with a personal project manager',
                    imgage1:'/approach-brand-color.png',
                    imgage2:'/approach-white.png',
                },
                {
                    title:'Creative',
                    description:'We guarantee an exclusive design. We do our best to attract the attention of our customers and using the world’s leading strategies',
                    imgage1:'/creative-brand-color.png',
                    imgage2:'/creative-white.png',
                }
            ]
        return(
            <div className='justify-center py-5 light-bg'>
                <Row style={{width:noTranslate?'100%':'93%',marginLeft:0}} gutter={[2,2]} className='my-5'>
                    {
                        state?.map((data, d)=>
                            <Col key={'moto-'+d} xl={{span:8}} lg={{span:8}} md={{span:12}} className='borddiv'>
                                <div className="moto animation bg-light-gray h-100">
                                    <img src='/assets/moto-bg.png' className='one animation' width={'100%'} height='auto'/>
                                    <div className='two animation'></div>
                                    <div className='three strong' >
                                        <div className='text-center'>
                                            <img src={'/assets'+data?.imgage1} alt='repla' className='mb-2 icon-1' width='90px'/>
                                            <img src={'/assets'+data?.imgage2} alt='repla' className='mb-2 icon-2' style={{margin:"auto"}} width='90px'/>
                                            <Typography.Title level={4} className='headm text-center'>{data?.title}</Typography.Title>
                                        </div>
                                        <p className='headp my-0 text-center'>
                                            {
                                                data?.description
                                            }
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            )
                    }
                    {/*  */}  
                </Row>
            </div>
        )
    }
export default Moto;