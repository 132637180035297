import { useNavigate } from "react-router-dom";
import { useState } from "react"
import {SectionTop} from '../../ui-elements/index';
import {Row, Col, Form, Input, Typography, Button, message} from 'antd'
import './index.css'

const {Text}= Typography
const ContactUsFrom=()=>
{
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [loading, setLoading]= useState(false)

    const contacts=[
        {img:'map-1.png',description:'Gold Point Plaza, Murree Rd, Rawalpindi'},
        {img:'phone-1.png',description:<span>+92 336 3334161 <br></br>+923369441595</span>},
        {img:'mail-1.png',description:'info@replatechnologies.co.uk'},
    ] 
        
    const addMessage=()=>{
        setLoading(true)
        let data= form.getFieldsValue()
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json")
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body:JSON.stringify(data),
            redirect: 'follow'
        }
        fetch('https://repla-backend.replatechnologies.com/api/contactUs', requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result?.success)
                {
                    form.resetFields()
                    setLoading(false)
                    navigate("/message-success")
                }
            else
            {
                setLoading(false)
                throw  'error'
            }
        })
        .catch(error => {message.error(error)})
      }

        return (
            <div className="flex-col-center py-5 w-100">
            <SectionTop title='OUR CONTACTS' description='We’re Here to Help You'/>
            <Row gutter={[64,0]}  style={{width:'93%'}}>
                <Col lg={{span:10}} sm={{span:24}} xs={{span:24}} className="brand-bg flex-col-center-center py-5">
                    <a 
                        href="https://calendly.com/contact-repla"  
                        target='_blank'
                        className="align-center p-3 border w-100 rounded-1 mb-5 btnzo"
                    >
                        <img src='/assets/calendly.svg' alt='repla' width='40px' height='auto' className='me-4'/>
                        <div>
                            <h5 className='m-0 mb-1'>Meet on Calendly</h5>
                        </div>
                    </a>
                    {
                        contacts?.map((contact,c)=>
                            <div className={c===2?"align-center p-3 border w-100 rounded-1 btnzo":"align-center p-3 border w-100 mb-5 rounded-1 btnzo"}>
                                <img src={'/assets/'+contact?.img} alt='repla' width='40px' height='auto' className='me-4'/>
                                <div>
                                    <h5 className='m-0 mb-1 fontsrobo'>{contact?.description}</h5>
                                </div>
                            </div>
                        )
                    }
                </Col>
                <Col lg={{span:14}} sm={{span:24}} xs={{span:24}} className='py-5 light-bg'>
                    <Form 
                        layout="vertical"
                        onFinish={addMessage}
                        form={form}
                    >
                        <Row gutter={[16,16]}>
                            <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                <Form.Item
                                    name="name" 
                                    label={<Text strong>Name</Text>}
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your name',
                                    },
                                    ]}
                                >
                                    <Input 
                                        size='large'
                                        placeholder="Please enter your name" 
                                        value={form.getFieldValue("name") || ''} 
                                        />
                                </Form.Item>
                            </Col>
                            <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                <Form.Item
                                    name="email"
                                    label={<Text strong>Email</Text>}
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your email',
                                    },
                                    ]}
                                >
                                <Input 
                                    size='large'
                                    placeholder="Please enter your email" 
                                    value={form.getFieldValue("email") || ''} 
                                    />
                                </Form.Item>
                            </Col>
                            <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                <Form.Item
                                    name="company" 
                                    label={<Text strong>Company/ Oraganization</Text>}
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your company/ oraganization',
                                    },
                                    ]}
                                >
                                    <Input 
                                        size='large'
                                        placeholder="Please enter your company/ oraganization" 
                                        value={form.getFieldValue("company") || ''} 
                                        />
                                </Form.Item>
                            </Col>
                            <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                <Form.Item
                                    name="subject"
                                    label={<Text strong>Subject</Text>}
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your subject',
                                    },
                                    ]}
                                >
                                <Input 
                                    size='large'
                                    placeholder="Please enter your subject" 
                                    value={form.getFieldValue("subject") || ''} 
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="message"
                                    label={<Text strong>Message</Text>}
                                    rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your message',
                                    },
                                    ]}
                                >
                                <Input.TextArea
                                    rows={4}
                                    size='large'
                                    placeholder="Please enter your message" 
                                    value={form.getFieldValue("message") || ''} 
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className='justify-end'>
                            <Button 
                                htmlType='submit' 
                                size='large' 
                                type='primary'
                                className='brand-bg brand-border'
                                loading={loading}
                            >
                                Submit Message
                            </Button>
                        </Row>
                        <Button className='d-done' htmlType='submit'></Button>
                    </Form>
                </Col>
            </Row>
         </div>
        )
}
export default ContactUsFrom