import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import {useParams } from 'react-router-dom';
import {Row, Col, Collapse, Typography,Space, Image} from 'antd';
import './index.css';
import { PlusCircleOutlined, SwapRightOutlined, CaretRightOutlined,} from '@ant-design/icons'
import ReplaButton from '../../components/buttons';
import data from "./data"
import {BreadCrumb} from "../../ui-elements/breadCrump"
const { Title, Text} = Typography;
const { Panel } = Collapse;
const genExtra = () => (
    <PlusCircleOutlined
      onClick={event => {
        // If you don't want click extra trigger collapse, you can prevent this:
        event.display="hide";
      }}
    />
  );
const ServicesDetail=()=>{
    const services=[
        {name:'Software Development',path:'/services/0'},
        {name:'Web Development',path:'/services/1'},
        {name:'Mobile App Development',path:'/services/2'},
        {name:'UI/UX Design',path:'/services/3'},
        {name:'Digital Marketing',path:'/services/4'},
        {name:'Seo',path:'/services/5'},
        {name:'Block Chain',path:'/services/6'},
        {name:'Game Development',path:'/services/7'}
    ]
    const [currentPanel,setCurrentPanel]=useState([''])
    const [state, setState]= useState(data)
    const {serviceID}=useParams()
    console.log(data[serviceID])
    return (
        <>
        <BreadCrumb title='Services' description='We Can Help You Succeed' pageName={state[serviceID]?.title}  breadcrumb={[{to:'/',name:'Home'}]}/>
        <div style={{margin:"30px",padding:"10px"}}>
        <Row gutter={[64,64]} justify="center">
        <Col className="gutter-row" xs={24} sm={24} md={24} lg={17}>
                <div>
                   <Space direction='vertical' size={20}>
                        <Image src={"/assets/service/"+state[serviceID]?.img} alt="repla-services" width={'100%'} className='imgban'/>
                        <Space direction='vertical' className='py-2' space={20}>
                            {
                                state[serviceID]?.paras?.map((para,p)=>
                                    <div key={'para-'+p}>
                                        <Title level={p==0?3:4} className='brand-color'>
                                        {
                                            para?.title
                                        }
                                        </Title>
                                        <Title level={5} className=''>
                                        {
                                            para?.subhead
                                        }
                                        </Title>
                                        <Typography.Text style={{textAlign:"justify",display:"block"}}>
                                            {
                                                para?.description
                                            }
                                        </Typography.Text>
                                    </div>
                                )
                            }
                        </Space>
                        
                        <Row gutter={[32,16]}>
                            <Col xs={24} sm={24} md={12} className='center'>
                                <Image src='/assets/images/service-details-benefits-img.jpg' width={'100%'} alt='repla'/>
                            </Col>
                            <Col xs={24} sm={24} md={12}  className='py-3 align-center'>
                                <Space direction='vertical' size={20}>
                                    <div>
                                        <Typography.Title level={3} className='brand-color my-0'>
                                            {
                                                state[serviceID]?.benefits?.title
                                            }
                                        </Typography.Title>
                                        <Text strong>
                                            {
                                                state[serviceID]?.benefits?.description
                                            }
                                        </Text>
                                    </div>
                                    <Space direction='vertical' size={20}>
                                        {
                                            state[serviceID]?.benefits?.list?.map((item,i)=>
                                            <Space key={i} size={20}>
                                                <img src='/assets/checked.png' width={'20px'}/>
                                                <Text  className='my-0'>{item}</Text>
                                            </Space>
                                            )
                                        }
                                    </Space>
                                </Space>
                            </Col>
                        </Row>
                   </Space>
                </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={7}>
                <Space direction='vertical' size={30} className='w-100'>
                    <Space direction='vertical p-4' style={{width:'100%',background:'#F7F7F9'}}>
                        <Title level={3} className='brand-color'> 
                            {state[serviceID]?.title}
                        </Title>
                        <Space direction='vertical' size={10} style={{width:'100%'}}>
                            {
                                services?.map((service,s)=>
                                <Link to={service?.path} key={'service-'+s} className={(s==serviceID?'services-active ':'services')+ ' animation align-center-space-between px-3 py-2'}>
                                    <Text className='my-0' strong={s==serviceID}>{service?.name}</Text>
                                    <span className='center' style={{width:'20px'}}>
                                        <SwapRightOutlined  className={(s==serviceID?'services-arrow-active':'services-arrow')+' animation brand-color'}/>
                                    </span>
                                </Link>
                                )
                            }
                        </Space>
                    </Space>
                    <div className="py-5 service-contact-box" style={{backgroundImage:"linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(/assets/images/card.jpg)"}}>
                        <Space direction='vertical w-100 flex-col-center px-3 my-3' size={30}>
                            <Image src='/assets/logo.png' alt='repla' className='brand-border' preview={false} style={{width:'190px',borderRadius:'45%',borderWidth:'2px',overflow:'hidden',backgroundColor:'rgba(0,0,0,0.6)'}}/>
                            <Space direction='vertical flex-col-center'>
                                <Title level={4} className='text-white text-center'>Easy solutions to your tech problem</Title>
                                <Text className='text-white'>Have any question?</Text>
                                <Space direction='vertical' size={3}>
                                    <Text className='text-white' strong>+92 336 3334161</Text>
                                    <Text className='text-white' strong>+92 336 9441595</Text>
                                </Space>
                            </Space>
                        </Space>
                    </div>
                    <div>
                        <ReplaButton title={'Contact Repla!!!'} to='/contact-us'/>
                    </div>
                    <div className="my-0">
                        <Row gutter={16}>
                        {
                            state[serviceID]?.slider?.map((slid,s)=>
                            <Col className='gutter-row' xs={24} sm={24} md={24}>
                                <div className="service-details__points-two-single">
                                    <div className="service-details__points-two-content">
                                        <h4>{slid?.title}</h4>
                                        <Typography.Text style={{textAlign:"justify",display:"block"}}>
                                            {
                                                slid?.description
                                            }
                                        </Typography.Text>
                                    </div>
                                </div>
                            </Col>
                                )
                        }
                        </Row>
                    </div>
                    <Space direction='vertical' size={0}>
                        <div className='borderfa'>
                            <h4 className='my-0 fw-semibold brand-color'>FAQs</h4>
                        </div>
                        <Collapse
                            defaultActiveKey={['']}
                            onChange={(keys)=>{setCurrentPanel(keys)}}
                            expandIcon={
                                ({ isActive }) => 
                                // <CaretRightOutlined style={{transform:'translateY(-3px)'}} className={isActive?'brand-color':''} rotate={isActive ? 90 : 0} />
                                <img src='/assets/carot.png' style={{width:"12px"}} className={isActive?'brand-color rotate':''} />
                            }
                        >
                            {
                                state[serviceID]?.accordin?.map((accor,a)=>
                                    <Panel 
                                        header={<Title level={5} className={currentPanel==a?'my-0':'my-0'}>{accor?.title}</Title>} 
                                        key={a}

                                    >
                                        <div>{accor?.description}</div>
                                    </Panel>
                                )
                            }
                        </Collapse>
                    </Space>
                </Space>
            </Col>
        </Row>
    </div>
    </>
    )
}
export default ServicesDetail