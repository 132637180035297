import React, { useState } from 'react'
import {BreadCrumb} from '../../ui-elements/index';
import {Row, Col, Form, Input, Typography, Button, notification,Radio, Space,DatePicker, Image, Select, Checkbox, message, Upload} from 'antd'
import './index.css'
import 'antd/dist/antd.css';
import { InboxOutlined } from '@ant-design/icons';
const {Text, Title}= Typography

const { Dragger } = Upload;
const InterneeForm=()=>
{   
    const btnRef = React.useRef() 
    const selectFile = () => {
        btnRef.current.click()
    }
    const [form] = Form.useForm()
    const [state,setState]=useState({
        name:"",
        dob:"",
        email:"",
        phone:"",
        address:"",
        currentcity:"",
        message:"",
        university:"",
        fieldofstudy:"",
        graduationdate:"",
        company:"",
        positionincompany:"",
        pastinternship:"",
        internshipposition:"",
        relevantskill:"",
        paidunpaid:"",
        studentstatus:"",
        expduration:"",
    })
    const handleChange=(key,value)=>{
        setState({...state,[key]:value})
    }

    
        
    const addMessage=()=>{
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json")
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body:JSON.stringify(state),
            redirect: 'follow'
        }
        fetch('https://repla-backend.replatechnologies.com/api/contactUs', requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result?.success===true)
                {
                    console.log("result", result)   
                }
            else
            {
                
                throw  'error'
            }
        })
        .catch(error => {alert(error)})
      }

      const props = {
        name: 'file',
        multiple: true,
        action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
        onChange(info) {
          const { status } = info.file;
          if (status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
          } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
        onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
        },
      };

        return (
            <div>    
                <BreadCrumb title='Apply For Intership' description='We Can Help You Succeed' pageName='For Internship' breadcrumb={[{to:'/',name:'Home'}]}/>
                <div className="flex-col-center py-5 w-100">
                    <div style={{justifyContent:"center",border:"1px solid gray"}} className='w-w'>
                        <Row style={{justifyContent:"center"}}>
                            <Col lg={{span:14}} sm={{span:24}} xs={{span:24}}>
                                <Title level={2} className='m-0 brand-color text-center'>Internship Form</Title>
                            </Col>
                            <Col lg={{span:24}} sm={{span:24}} xs={{span:24}} className='py-5'>
                                <Form 
                                    layout="vertical"
                                    onFinish={()=>{notification['success']({
                                        message: <Space align='center'>
                                                    <Image src='/assets/check.png' />
                                                    <Text>Item has been Added Successfully</Text>
                                                </Space>
                                });setState({name:"",email:"",company:"",subject:"",message:""})}} 
                                    form={form}
                                    >
                                    <Row gutter={[16,16]}>
                                        <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                            <Form.Item
                                                name="name" 
                                                label={<Text strong>Full Name</Text>}
                                                rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter your name',
                                                },
                                                ]}
                                            >
                                                <Input 
                                                    id='name'
                                                    size='large'
                                                    placeholder="Please enter your name" 
                                                    value={state?.name || ''} 
                                                    onChange={(e)=>{handleChange('name',e.target.value)}}/>
                                            </Form.Item>
                                        </Col>
                                        <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                            <Form.Item
                                                name="age"
                                                label={<Text strong>Age</Text>}
                                                rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter your age',
                                                },
                                                ]}
                                            >
                                            <Input 
                                                size='large'
                                                placeholder="Please enter your age" 
                                                value={state?.age || ''} 
                                                onChange={(e)=>{handleChange('age',e.target.value)}}/>
                                            </Form.Item>
                                        </Col>
                                        <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                            <Form.Item
                                                name="email"
                                                label={<Text strong>Email</Text>}
                                                rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter your email',
                                                },
                                                ]}
                                            >
                                            <Input 
                                                size='large'
                                                placeholder="Please enter your email" 
                                                value={state?.email || ''} 
                                                onChange={(e)=>{handleChange('email',e.target.value)}}/>
                                            </Form.Item>
                                        </Col>
                                        <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                            <Form.Item
                                                name="number"
                                                label={<Text strong>Phone</Text>}
                                                rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter your phone no',
                                                },
                                                ]}
                                            >
                                            <Input 
                                                size='large'
                                                placeholder="Please enter your phone no" 
                                                value={state?.phone || ''} 
                                                onChange={(e)=>{handleChange('phone',e.target.value)}}/>
                                            </Form.Item>
                                        </Col>
                                        <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                            <Form.Item
                                                name="address" 
                                                label={<Text strong>Current Address</Text>}
                                                rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter your current address',
                                                },
                                                ]}
                                            >
                                                <Input 
                                                    size='large'
                                                    placeholder="Please enter your current address" 
                                                    value={state?.address || ''} 
                                                    onChange={(e)=>{handleChange('address',e.target.value)}}/>
                                            </Form.Item>
                                        </Col>
                                        <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                            <Form.Item
                                                name="currentcity" 
                                                label={<Text strong>Current City</Text>}
                                                rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter your current city',
                                                },
                                                ]}
                                            >
                                                <Input 
                                                    size='large'
                                                    placeholder="Please enter your current city" 
                                                    value={state?.address || ''} 
                                                    onChange={(e)=>{handleChange('currentcity',e.target.value)}}/>
                                            </Form.Item>
                                        </Col>
                                        <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                            <Form.Item
                                                name="university"
                                                label={<Text strong>University</Text>}
                                                rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter your university',
                                                },
                                                ]}
                                            >
                                            <Input 
                                                size='large'
                                                placeholder="Please enter your university" 
                                                value={state?.university || ''} 
                                                onChange={(e)=>{handleChange('university',e.target.value)}}/>
                                            </Form.Item>
                                        </Col>
                                        <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                            <Form.Item
                                                name="fieldofstudy"
                                                label={<Text strong>Major Field of Study</Text>}
                                                rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter your field of study',
                                                },
                                                ]}
                                            >
                                            <Input 
                                                size='large'
                                                placeholder="Please enter your field of study" 
                                                value={state?.fieldofstudy || ''} 
                                                onChange={(e)=>{handleChange('fieldofstudy',e.target.value)}}/>
                                            </Form.Item>
                                        </Col>
                                        <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                            <Form.Item
                                                name="graduationdate"
                                                label={<Text strong>Graduation Date</Text>}
                                                rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter your Graduation date',
                                                },
                                                ]}
                                            >
                                                <DatePicker 
                                                    size='large'
                                                    style={{width:'100%'}}
                                                    placeholder="Please enter your Graduation date" 
                                                    value={state?.graduationdate || ''} 
                                                    onChange={(e)=>{handleChange('graduationdate',e.target.value)}}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                            <Form.Item
                                                name="internshipposition"
                                                label={<Text strong>Internship Position <small>(laravel internship, react etc)</small></Text>}
                                                rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter your internship postion',
                                                },
                                                ]}
                                            >
                                            <Input 
                                                size='large'
                                                placeholder="Please enter your internship postion" 
                                                value={state?.internshipposition || ''} 
                                                onChange={(e)=>{handleChange('internshipposition',e.target.value)}}/>
                                            </Form.Item>
                                        </Col>
                                        <Col md={{span:24}} sm={{span:24}} xs={{span:24}}>
                                            <Form.Item
                                                name="relevantskill"
                                                label={<Text strong>Relevant Skills</Text>}
                                                rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter your relevant skills',
                                                },
                                                ]}
                                            >

                                                <Select
                                                    mode="multiple"
                                                    allowClear
                                                    size='large'
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                    placeholder="Please Relevant Skills"
                                                    value={state?.relevantskill || ''} 
                                                    onChange={(e)=>{handleChange('relevantskill',e.target.value)}}
                                                    options={
                                                        [
                                                            
                                                            {
                                                                value:"Angular",
                                                                label:"Angular",
                                                            },
                                                            {
                                                                value:"Bootstrap",
                                                                label:"Bootstrap",
                                                            },
                                                            {
                                                                value:"Css",
                                                                label:"Css",
                                                            },
                                                            {
                                                                value:"Flutter",
                                                                label:"Flutter",
                                                            },
                                                            {
                                                                value:"Graphic",
                                                                label:"Graphic",
                                                            },
                                                            {
                                                                value:"Html",
                                                                label:"Html",
                                                            },
                                                            {
                                                                value:"Laravel",
                                                                label:"Laravel",
                                                            },
                                                            {
                                                                value:"Marketing",
                                                                label:"Marketing",
                                                            },
                                                            {
                                                                value:"Node Js",
                                                                label:"Node Js",
                                                            },
                                                            {
                                                                value:"Php",
                                                                label:"Php",
                                                            },
                                                            {
                                                                value:"Python",
                                                                label:"Python",
                                                            },
                                                            {
                                                                value:"QA Engineer",
                                                                label:"QA Engineer",
                                                            },
                                                            {
                                                                value:"React Native",
                                                                label:"React Native",
                                                            },
                                                            {
                                                                value:"React Js",
                                                                label:"React Js",
                                                            },
                                                            {
                                                                value:"Software Project Management",
                                                                label:"Software Project Management",
                                                            },                                                    
                                                            {
                                                                value:"UI/UX Designer",
                                                                label:"UI/UX Designer",
                                                            },
                                                            {
                                                                value:"Others",
                                                                label:"Others",
                                                            },
                                                        ]
                                                    }
                                                    />
                                            </Form.Item>
                                        </Col>
                                        <Col md={{span:8}} sm={{span:24}} xs={{span:24}}>
                                            <Form.Item
                                                name="company"
                                                label={<Text strong>Company</Text>}
                                            >
                                            <Input 
                                                size='large'
                                                placeholder="Please enter your company" 
                                                value={state?.company || ''} 
                                                onChange={(e)=>{handleChange('company',e.target.value)}}/>
                                            </Form.Item>
                                        </Col>
                                        <Col md={{span:8}} sm={{span:24}} xs={{span:24}}>
                                            <Form.Item
                                                name="Position"
                                                label={<Text strong>Position</Text>}
                                            >
                                            <Input 
                                                size='large'
                                                placeholder="Please enter your position" 
                                                value={state?.positionincompany || ''} 
                                                onChange={(e)=>{handleChange('positionincompany',e.target.value)}}/>
                                            </Form.Item>
                                        </Col>
                                        <Col md={{span:8}} sm={{span:24}} xs={{span:24}}>
                                            <Form.Item
                                                name="expduration"
                                                label={<Text strong>Experience duration</Text>}
                                            >
                                                <Input 
                                                    size='large'
                                                    placeholder="Please enter your experience duration" 
                                                    value={state?.expduration || ''} 
                                                    onChange={(e)=>{handleChange('expduration',e.target.value)}} />
                                            </Form.Item>
                                        </Col>
                                        <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                            <Form.Item
                                                name="studentstatus"
                                                label={<Text strong>Status</Text>}
                                            >
                                                <Radio.Group onChange={(e)=>{handleChange('studentstatus',e.target.value)}}
                                                    value={state?.studentstatus || ''}
                                                >
                                                    <Radio value={1}>Student</Radio>
                                                    <Radio value={2}>Fresh Graduate</Radio>
                                                    <Radio value={3}>Already Graduated</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                        <Col md={{span:24}} sm={{span:24}} xs={{span:24}}>
                                            <Form.Item
                                            >
                                                <Checkbox onChange={(e)=>{handleChange('studentstatus',e.target.value)}}
                                                    value={state?.studentstatus || ''}>
                                                    Are you available for an onsite morning shift internship ?
                                                </Checkbox>
                                            </Form.Item>
                                        </Col>
                                        <Col md={{span:24}} sm={{span:24}} xs={{span:24}}>
                                            <div className='centerupload'>
                                                <Form.Item
                                                    name="uploadfile"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please Upload Your Cv/Resume',
                                                        },
                                                        ]}
                                                >
                                                    <Dragger {...props}>
                                                        <p className="ant-upload-drag-icon">
                                                        <InboxOutlined className='brand-color' />
                                                        </p>
                                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                                    </Dragger>
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='justify-end'>
                                        <Button htmlType='submit' danger size='large' onClick={addMessage}>Send</Button>
                                        {/* <div style={{width:'230px'}} onClick={(e)=>{e.preventDefault();selectFile()}}>
                                            <ReplaButton title={'Submit Feedback'}/>
                                        </div> */}
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
}
export {InterneeForm}