import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import {useParams } from 'react-router-dom';
import {Row, Col, Collapse, Typography,Space, Image} from 'antd';
import './index.css';
import {BreadCrumb} from "../../ui-elements/breadCrump"
import ServiceFirstBlock from '../../components/servicefirst/ServiceFirstBlock';
import ServiceOffering from '../../components/serviceoffering';
import AboutServices from '../../components/aboutServices';
import Feedback from '../../components/feedback';
const { Title, Text} = Typography;
const Services=()=>{
    return (
        <>
            <BreadCrumb 
                title='Services' 
                description='We Can Help You Succeed' 
                pageName={'services'}  
                breadcrumb={[{to:'/',name:'Home'}]}
                />
            <ServiceFirstBlock/>
            <ServiceOffering/>
            <AboutServices/>
            <Feedback/>
        </>
    )
}
export default Services