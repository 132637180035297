import React, { useState} from 'react'
import {Row,Col,Typography, Space} from  'antd'
import  "./index.css"
import { useEffect } from 'react';
import ReplaButton from '../buttons';
import { NavLink } from 'react-router-dom';
const {Title, Text}=Typography;
const HomeTopSection =()=>
{
    const state=[
        {
            bg:'slider-bg-1a.jpg',
            title: 'Innovative Digital Solutions for Modern Business',
            title1:"Repla Security and Technology",
            subtitle:"Empowering Business through Innovative Technology",
            description:"We help you figure out what you existing and potential clients exactly want, and then equip you with the tool to provide whats's necessary"

        },
        {
            bg:'slider-bg-2.jpg',
            title:'Empowering Business Through Innovative Technology',
            title1:"Repla Security and Technology",
            description:"We help you figure out what you existing and potential clients exactly want, and then equip you with the tool to provide whats's necessary"
        },
        {
            bg:'slider-bg-3.jpg',
            title:"Repla innovates businesses with effective and adaptable digital solutions",
            title1:"Repla Security and Technology",
            description:"We help you figure out what you existing and potential clients exactly want, and then equip you with the tool to provide whats's necessary"
        },
    ]
    const [currentState, setCurrentState]=useState()
    useEffect(()=>{
        setCurrentState(0)
    },[])
    console.log(currentState)
    useEffect(()=>{
        let left=document.getElementsByClassName("slider-left")[0]
        let right=document.getElementsByClassName("slider-right")[0]
        let sliderBg=document.getElementsByClassName("slider-bg")[0]
        let one=document.getElementsByClassName("text-one")[0]
        let two=document.getElementsByClassName("text-two")[0]
        setTimeout(()=>{
            left.setAttribute("class",'slider-left slider-left-animation')
        right.setAttribute("class",'slider-right slider-right-animation animations')
        sliderBg.setAttribute("class",'slider-bg slider-bg-animation')
        one.setAttribute("class",'text-one text-one-animation text-animation')
        two.setAttribute("class",'text-two text-two-animation text-animation')
        },10)
    },[currentState])
    const myfun=()=>{
        let left=document.getElementsByClassName("slider-left")[0]
        let right=document.getElementsByClassName("slider-right")[0]
        let sliderBg=document.getElementsByClassName("slider-bg")[0]
        let one=document.getElementsByClassName("text-one")[0]
        let two=document.getElementsByClassName("text-two")[0]
        left.setAttribute("class",'slider-left');
        right.setAttribute("class",'slider-right');
        sliderBg.setAttribute("class",'slider-bg');
        one.setAttribute("class",'text-one')
        two.setAttribute("class",'text-two')

    }
    const bg={src:`${process.env.PUBLIC_URL+'/assets/slider-bg-1.jpg'}`}
        return(
           <div>
                <div  className='home-slider'>
                        <div className='slider-bg' style={{backgroundImage:`linear-gradient(rgba(1,1,1,0.7),rgba(1,1,1,0.7)),url(/assets/${state[currentState]?.bg})`}}>
                            {/* <img src={'/assets/'+state[currentState]?.bg} style={{ filter: 'brightness(100%)'}}/> */}
                        </div>
                        <img src='/assets/slider-left.png' className='slider-left'/>
                        <img src='/assets/slider-right.png' className='slider-right'/>
                        <div className='slider-text'>
                            Repla
                        </div>
                        <div className='center' style={{position:'relative',width:'100%',height:'100%' ,justifyContent:"center",alignItems:"center"}}>
                            <Row style={{width:'93%',borderWidth:'4px'}}>
                                <Col lg={{span:24}} md={{span:24}}>
                                    <Space size={25} direction='vertical' className='w-100'>
                                        <div className='text-one'>
                                            <Title level={1} className='text-white text-center' style={{fontWeight: "900"}}>
                                                {
                                                    state[currentState]?.title1
                                                }
                                            </Title>
                                            <Title level={2} className='text-white mb-0 home-top-section-title text-center'>
                                                {
                                                    state[currentState]?.title
                                                }
                                            </Title>
                                            <Title level={4} className='text-white mt-0 home-top-section-title text-center' style={{marginTop:'50px'}}>
                                                {
                                                    state[currentState]?.subtitle
                                                }
                                            </Title>
                                        </div>
                                        <div style={{margin:"auto"}} className='text-two widthtwo'>
                                            <ReplaButton title='Discover more About Repla' to='/about-us'/>
                                            <a 
                                                href='https://calendly.com/contact-repla' 
                                                target='_blank'
                                                className="align-center p-2 border w-100 rounded-1 my-2 btnzo justify-center"
                                            >
                                                <img src='/assets/calendly.svg' alt='repla' width='40px' height='auto' className='me-4'/>
                                                <div>
                                                    <h5 className='m-0 mb-1'>Meet on Calendly</h5>
                                                </div>
                                            </a>
                                        </div>
                                    </Space>
                                </Col>
                                <Col lg={{span:24}} md={{span:24}} sm={{span:24}} xs={{span:24}} className='align-end-center'>
                                        <Space size={12} className='home-top-section-dots'>
                                            <div className={(currentState===0?'brand-bg':'bg-white')+' slider-circle animation'} onClick={()=>{myfun();setCurrentState(0)}}></div>
                                            <div className={(currentState===1?'brand-bg':'bg-white')+' slider-circle animation'} onClick={()=>{myfun();setCurrentState(1)}}></div>
                                            <div className={(currentState===2?'brand-bg':'bg-white')+' slider-circle animation'} onClick={()=>{myfun();setCurrentState(2)}}></div>
                                        </Space>
                                </Col>
                            </Row>
                        </div>
                </div>
           </div>
        )
    }

export default HomeTopSection;