
import { BreadCrumb } from '../../ui-elements/index';
import { BlogDetail } from '../../components/BlogDetail';
const Blogs=()=>
    {
        
        return   <>
            <BreadCrumb title='News & blogs' description='Your Business Partner' pageName='News & blogs' breadcrumb={[{to:'/',name:'Home'}]}/>
            <BlogDetail />
        </>
    }
export default Blogs;
