import React, { useState } from 'react'
import { Col, Row, Image, Space, Typography } from 'antd'
import './index.css';
import Dataindustry from './Dataindustry';
const {Title, Text}= Typography

function Industrywe() {

    const [industrydata, setIndustryData] = useState(Dataindustry)
    const [selectindustry, setSelectIndustry] = useState(0)
  return (
    <div> 
        {/* style={{backgroundImage:"linear-gradient(rgba(0,0,0,0.9),rgba(0,0,0,0.9)), url(/assets/industry-bg.jpg)"}} */}
        <Row className='industry-cover bg-white' >
            <Col lg={{span:24}} xs={{span:24}} className='flex-col-center-end mb-5'>
                <div style={{width:'93%',transform:'translateY(50px)'}} className='text-center' >
                    <Title level={2} className=' mt-2 brand-color '>Industries we’re serving</Title>
                    <Space>
                        {/* <Image src={"/assets/dot-arrow.png"} alt="BCT"/> */}
                        <Title level={4} className='my-0 text-center'>IT services customized for your industry</Title>
                    </Space>
                </div>
            </Col>
            {/* <Col lg={{span:12}} xs={{span:0}} className='mb-5'>
                <Image src={"/assets/business-from-two-img.jpg"} alt="BCT" style={{maxHeight:'calc(100% - 20px)',maxWidth:'100%'}}/>
            </Col> */}
            <Col span={24} className='justify-center pb-5'>
                <Row gutter={[32,32]} className='py-5' style={{width:'93%'}}>
                    {
                        industrydata[selectindustry]?.listItem?.map((industry,i)=>
                        <Col lg={{span:8}} md={{span:12}} xs={{span:24}} key={i}>
                            <div className='industries rounded' style={{height:"100%"}}>
                                <div className='one animation brand-bg'></div>
                                <div className='two animation brand-bg'></div>
                                <div className='three flex-col-center py-5 animation'>
                                    <img src={'/assets/'+industry?.iconBrand} width='90px' className='iconBrand animation mb-3'/>
                                    <img src={'/assets/'+industry?.iconWhite} width='90px' className='iconWhite animation mb-3'/>
                                    <Title level={4} className='texthover'>{industry?.title}</Title>
                                    <Text className='px-3 text-center texthover'>
                                        {
                                            industry?.desc
                                        }
                                    </Text>
                                </div>
                            </div>
                        </Col>
                        )
                    }
                </Row>
            </Col>
        </Row>
    </div>
  )
}

export default Industrywe