const data=[
    {
        title:'Software development',
        img:"soft-dev.jpg",
        paras:[
            {
                title:"Repla's Efficient Software Solutions Drive Positive Customer Impressions",
                subhead:"Develop Quality Software with Innovative Tools and Techniques",
                description:`Repla Technologies offers efficient software solutions that are functional and intrusive, driving a positive impression for your potential customers. Our team of experienced developers designs software with quality programming code, utilizing innovative tools and techniques to ensure a smooth and seamless user experience.                `

            },
            {
                description:`We adopt a strategic project mapping technique to represent your project requirements, priorities, and potential challenges, streamlining the existing and future strategies. Our technical design is centered on collecting project requirements. With the acquired data, we design the prototype and perform its user testing. At development stage, our developers perform coding and programming on the approved design. Our developers deploy the modified and optimized software, making your software extensively accessible. To ensure your software functional as intended, our team performs considerable tracking and maintenance.
                Choose Repla Technologies for efficient and innovative software solutions that will drive positive impressions for your potential customers.`
            },
            {
                description:`We create software with long-term business value – tailored uniquely to your business processes and adjustable to future needs. We ensure smooth functioning and relevancy of your software via continuous performance monitoring, proactive optimization and fast issue resolution, delivery of new features and integrations.`
            },
            {
                description:`We have full -stack developers who are skilled in web-based apps with expertise in all web technologies including Laravel, PHP, NodeJS, Angular, Bootstrap, ASP.NET and ReactJS. We are also professional in desktop-based applications with expertise in C#. Repla develops innovative and intuitive apps that ensure product quality and customer satisfaction, all resulting in a high conversion rate. With expertise advanced tools, frameworks, APIs, and programming languages, our developers cater to diverse many industries.`
            },
            {
                description:`We understand the importance of developing software that is tailored to your unique business needs, and we work closely with our clients to ensure that our solutions meet their requirements. Our goal is to provide you with software that not only meets your functional needs but also drives positive customer impressions.
                At Repla, we use the latest programming languages, frameworks, and APIs to develop software that is robust and reliable. Our quality assurance team ensures that all code is thoroughly tested to ensure that it meets industry standards and is free from bugs or errors.`
            },
            {
                title:'Field and Technologies',
                description:` At Repla, we specialize in developing high-quality apps for a variety of industries, including Education, Manufacturing, Healthcare, Banking and Finance, E-commerce, MLM, Games, and Entertainment. Our team of professional developers has the skills and expertise to create custom apps using a range of languages and technologies such as Laravel, PHP, NodeJS, Angular, Bootstrap, and ASP.NET, ReactJS, and C #.
                We pride ourselves on being experts in React.js, a technology that enables us to speed up the development process and create lightning-fast applications. Additionally, we use Angular to develop complex functionalities such as progressive, single-page, and native software apps. We also utilize Bootstrap to create responsive apps quickly and efficiently.
                At Repla, we have a dedicated team of professionals who specialize in developing websites using Laravel, a flexible and innovative platform for creating software apps. Our team is committed to delivering exceptional results for our clients and providing top-notch service every step of the way. Contact us today to learn more about how we can help you develop the perfect app for your business.
                
                `,
            }
        ],
        slider:[
            {
                title:"Innovative Technologies",
                description:"Finding innovative solutions and technologies to improve the processes and value of software often uses qualifying research activities that can reap the benefits."
            },
            {
                title:"Research",
                description:"Software developers perform research used for implementing a new system or process. "
            },
            {
                title:'R&D Solutions',
                description:"R&D Solutions has proven track record developing custom software from scratch for enterprises"
            }
        ],
        benefits:{
            title:"Our Significance",
            description:"",
            list:[
                "Repla provides efficient software which are functional and intrusive.",
                "Repla gives positive impression to your potential customers.",
                "Repla develops software with quality programming code using innovative tools and techniques."
            ]
        },
        accordin:[
            {
                title:'How do Repla give your company a competitive advantage?',
                description:"We harness the most promising software technologies and tech stacks to give you a competitive advantage.  Repla works on Laravel, PHP, NodeJS, Angular, Bootstrap, ASP.NET, ReactJS and C#."
            },
            {
                title:'How do you create a a standardized software quality assurance process?',
                description:"To create a standardized software quality assurance process Repla undergoes various testing processes under design and development stages. We ensure that our customers get a functional website."
            }
        ]
    },
    {
        title:'Website development',
        img:"web-dev.jpg",
        paras:[
            {
                title:"Innovative and Intuitive Website Development Services from Repla Technologies",
                subhead:"Customized Websites for Diverse Industries Developed by Experienced Developers",
                description:`
                At Repla Technologies, we specialize in developing innovative and intuitive websites that ensure product quality and customer satisfaction. Our experienced website development team is dedicated to designing and developing functional, well-designed, and interactive websites that are tailored to meet the unique needs of diverse industries. With expertise advanced tools, frameworks, APIs, and programming languages, our developers cater to diverse many industries.               
                `
            },
            {
                description:`We specialize in developing websites for various industries, including Education, Manufacturing, Healthcare, Banking and Finance, E-commerce, MLM, Games, and Entertainment. We understand that each industry has unique requirements, and our team is dedicated to designing websites that meet those needs.`
            },
            {
                description:`From single-page applications to complex business enterprise networks, Repla develops functional, reliable, high-quality custom websites exactly the way you envision them. Our team of professional developers is skilled in a wide range of languages and technologies, including React, Angular, Bootstrap, PHP, Laravel, Node Js, and ASP.NET MVC.`
            },
            {
                description:`We adopt a strategic project mapping technique to represent your project requirements, priorities, and potential challenges, streamlining the existing and future strategies. Our technical design process centers on collecting project requirements, designing a prototype, and performing user testing. At the development stage, our developers perform coding and programming on the approved design, ensuring that your website is optimized and extensively accessible. Our team also performs considerable tracking and maintenance to ensure that your website functions as intended. `
            },
            {
                title:'Field and Technologies',
                description:`
                We develop websites for different industries like Education, Manufacturing, Healthcare, Banking and Finance, E-commerce, MLM, Games and Entertainment. Repla has a team of professional developers who are skilled to create websites with expertise in a lot of languages and technologies like React, Angular, Bootstrap, PHP, Laravel, Node Js (asp.net, asp.net mvc) 
                We use React.js to develop user interfaces because it helps to speed up the development process and creates applications that work fast. Angular is perfect for complex functionalities like progressive, single-page, and native web apps. Bootstrap allows us to create responsive websites quickly, and Laravel is flexible and rich in creating unique websites.
                At Repla Technologies, we are committed to providing our clients with websites that are not only functional but also aesthetically pleasing and easy to use. Contact us today to learn more about how we can help you develop a website that meets your specific needs and goals.                               
                `,
            }
        ],
        slider:[
            {
                title:"Innovative Technologies",
                description:"Finding innovative solutions and technologies to improve the processes and value of a website often uses qualifying research activities that can reap the benefits."
            },
            {
                title:"Research",
                description:"Website developers perform research used for implementing a new system or process."
            },
            {
                title:'R&D Solutions',
                description:"R&D Solutions has proven track record developing custom software from scratch for enterprises."
            }
        ],
        benefits:{
            title:"Our Significance",
            description:"",
            list:[
                "Repla provides efficient websites which are functional and intrusive.",
                "Repla gives positive impression to your potential customers.",
                "Repla develops websites with quality programming code using innovative tools and techniques."
            ]
        },
        accordin:[
            {
                title:'How do Repla give your company a competitive advantage?',
                description:"We harness the most promising web technologies and tech stacks to give you a competitive advantage.  Repla works on React, Angular, Bootstrap, PHP, Laravel, Node Js (asp.net, asp.net mvc)."
            },
            {
                title:'How do you create a a standardized website quality assurance process?',
                description:"To create a standardized website quality assurance process Repla undergoes various testing processes under design and development stages. We ensure that our customers get a functional website."
            }
        ]
    },
    {
        title:'Mobile App Development',
        img:"app-dev.jpg",
        paras:[
            {
                title:'Your Ultimate Partner for Mobile App Development',
                subhead:"Professional Mobile App Development Services by Repla: A Winning Combination of Expertise, Tools, and Innovation",
                description:`
                If you are looking for a professional team with advanced tools and formidable information of the crowded mobile landscape to develop the perfect mobile app, Repla is the right choice for you. With over 1000+ mobile apps developed, Repla provides top-notch mobile app development services, including building iOS & Android applications that efficiently complement web solutions.               
                `
            },
            {
                description:`In today's world, where around 6.37 billion people around the world use smartphones, and 90% of the time spent on them is on mobile apps, having a mobile app for your business is crucial. The marketplace for mobile apps is growing and getting larger day by day, with about 230 billion mobile app downloads last year. Mobile apps are becoming the preferred way for customers to interact with businesses, and most companies/brands are demanding mobile apps to lure maximum customers.`
            },
            {
                description:`At Repla, we understand the importance of mobile apps for businesses and develop mobile applications that accumulate and analyze user data to help you acquire insights on user engagement. Our mobile apps offer customers the ability to customize information, including messages, tips, and offers based on the user. We integrate third-party tools to enhance the user experience, providing your business with a competitive edge. Our successful mobile apps focus not only on appropriate design and friction-free UX, but also on a keen understanding of the fierce competition within your app store category.
                Trust Repla for your mobile app development needs and take your business to the next level. Contact us today to learn more about our services. `
            },
            {
                title:"Field and Technologies",
                description:"We develop mobile applications for different industries like Education, Manufacturing, Healthcare, Banking and Finance, E-commerce, MLM, Games and Entertainment. Repla has a team of professional developers who are skilled on mobile apps with expertise in a lot of languages and technologies like React Native and Flutter. We always focus on our client’s sales and profits. Our highly expert team develops mobile apps in React Native which gives more revenue to your business in less time. World’s most famous apps like Facebook and Instagram, Skype, Pinterest use React Native as their mobile app development framework. React Native enhance the business productivity in less time. In short React Native gives a significant boost to your business. Repla has a remarkable professional team to build mobile apps in Flutter also which allows you to launch apps faster as in this digital world there comes a lot of market competition, so brands and businesses must maintain their pace to keep a reputed position in the market.  Flutter plays a significant role in your business growth."
            },
        ],
        slider:[
            {
                title:'Performance Optimization',
                description:"The app should be optimized for speed, responsiveness, and efficiency."
            },
            {
                title:'Cross-platform compatibility',
                description:'One key parameter in mobile app development is ensuring that the app works seamlessly across multiple platforms, such as iOS and Android.'
            },
            {
                title:'Regular updates',
                description:'It include bug fixes, performance improvements, and new features that enhance the user experience.'
            }
        ],
        benefits:{
            title:"Our Significance",
            list:[
                "Mobile apps efficiently complement web solutions, offering a comprehensive solution for clients",
                "The marketplace for mobile apps is growing rapidly",
                "Mobile apps are becoming the preferred way for customers to interact with businesses",
                "Mobile apps offer a personalized experience for users."
            ]
        },
        accordin:[
            {
                title:'How do Repla give your company a competitive advantage?',
                description:"We harness the most promising mobile apps to give you a competitive advantage. Repla has a team of professional developers with expertise in a lot of languages and technologies like React Native and Flutter which gives a boost to your business."
            },
            {
                title:'How do you create a a standardized mobile app quality assurance process?',
                description:"To create a standardized mobile app quality assurance process Repla undergoes various testing processes under design and development stages. We ensure that our customers get a functional mobile app."
            }
        ]
    },
    {
        title:'UI/UX Design',
        img:"ui-dev.jpg",
        paras:[
            {
                title:"Repla's Innovative Approach to UI/UX Design Ensures Customer Satisfaction",
                subhead:"Create Visually Attractive Digital Interfaces with Our Expertise",
                description:`At Repla Technologies, we understand the importance of UI/UX design in creating visually attractive digital interfaces that attract customers. Our innovative approach to UI/UX design ensures customer satisfaction by developing designs that strengthen your brand's emblem and enhance your user engagement.
                We begin our UI/UX design process by understanding the full scope of the project and the technical requirements to create a digital product. Our team of experts conducts extensive research and gathers information on customers' requirements from scratch. With this information, we can create innovative designs by thinking out of the box, ensuring that your product stands out from the competition.
                `
            },
            {
                description:`Our team creates engaging front-end designs, creates user flows, and conceptualizes products to provide a complete user engagement evaluation. By developing aesthetically appealing UI, we can enhance your customers' user experience and satisfaction.
                Partner with Repla Technologies for expert UI/UX design services that are tailored to your unique business needs. Contact us today to learn more about how we can help you create visually attractive digital interfaces that attract customers and strengthen your brand.`
            }
        ],
        slider:[
            {
                title:'Branding effect',
                description:"Branding effect develops user friendly user-interface design that reflects brand qualities for seamless customer experience."
            },
            {
                title:'Technological Advancement',
                description:'There is a fundamental shift in the way we interact with technology. Latest designing with new technologies differentiates you from others in the market.'
            },
            {
                title:'Marketing Role of Designers',
                description:'UI/UX designers engage customers with content and brand.'
            }
        ],
        benefits:{
            title:"Our Significance",
            list:[
                "Repla's UI/UX design services enhance customer satisfaction and maintain brand loyalty.",
                "Our innovative design solutions are tailored to your unique business needs.",
                "We create interactive, engaging, and navigable content that keeps people on your site longer.",
                "Our intuitive UI/UX designs can increase your productivity and streamline your processes.",
                "Our design solutions are optimized for search engines, helping you rank higher in search results.",
                "Partner with Repla Technologies for expert UI/UX design services that will boost your SEO and retain customers."
            ]
        },
        accordin:[
            {
                title:'How do Repla understand products and user goals?',
                description:"Through research Repla understands product and user requirements. Our research phase includes to examine the product vertically, user interviews, creating user personas, competitive analysis, and evaluating & integrating the results."
            },
            {
                title:'What is our design procedure?',
                description:"The design technique starts with writing use cases, to better see how users use your product. Then we start UX, where we smash down complex queries into their most effective forms. Next, we develop a visual language to ensure integration across the product and platforms. Eventually, we create prototypes to test our design theories."
            }
        ]
    },
    {
        title:'Digital Marketing',
        img:"digital-market.jpg",
        paras:[
            {
                title:'Repla - The Digital Marketing Revolutionary',
                Subheading:"Fast, Helpful, and Proactive Digital Customer Service",
                description:`Repla takes a unique and innovative approach to digital advertising and marketing that is focused on reaching targeted customers at every level of the digital marketing process. With the right digital marketing strategy, a business can go beyond simple transactions and take its brand to new heights. At Repla, we understand the power of effective digital marketing, and we specialize in creating customer-focused strategies that drive engagement and provide a superior customer experience.
                `
            },
            {
                description:`Our team is dedicated to helping businesses create fast, helpful, and proactive digital customer service that resonates with their audience. We specialize in digital engagement and multitouch customer journeys, allowing our clients to stand out in the competitive digital landscape. Our goal is to help businesses increase brand loyalty and drive online sales in a cost-effective and measurable way. By leveraging the latest digital marketing techniques, we help our clients reach a wider audience and achieve their business goals.
                Our team of experts has years of experience working with businesses of all sizes and industries to help them grow and succeed. We understand the unique challenges that businesses face in the digital age, and we have the skills and expertise to overcome them. Our team is our strength, and their skills are our product.`
            },
            {
                description:"In summary, Repla is your trusted partner in the ever-evolving world of digital marketing. Our customer-focused approach and expertise in digital engagement, multitouch customer journeys, and proactive digital customer service sets us apart from the competition. Contact us today to learn how we can help your business achieve its goals and stand out in the digital landscape."
            },
        ],
        slider:[
            {
                title:'Lead Generation',
                description:"We innovate and execute a lead generation strategy using SEO, social ads, and SEM to help engage your customers to your brand."
            },
            {
                title:'Lead Capture',
                description:'We optimize your digital presence to drive conversion leads using effective websites, landing pages and forms.'
            },
            {
                title:'Lead Nurturing',
                description:'We provide insightful content to drive your customers closer to your brand and persuade them to buy.'
            }
        ],
        benefits:{
            title:"Our Significance",
            description:"choose the best one’s",
            list:[
                "Repla is positioned as a trusted partner in the ever-evolving world of digital marketing.",
                "Repla offers an innovative approach to digital advertising and marketing.",
                "Repla provides a great user experience to your website.",
                "Repla targets audience in a cost effective and measurable way.",
                "We positively impact digital business with our innovative tools and techniques."
            ]
        },
        accordin:[
            {
                title:'What are our digital marketing techniques?',
                description:"We tackle digital marketing through a universal strategy focused on impact. We build a solid digital marketing framework. We use effective and innovative digital marketing tactics like SEO, PPC, E-mail Marketing, Social Media Marketing, Content Marketing, Marketing Automation and PR."
            },
            {
                title:'How Repla uses innovation in Digital Marketing?',
                description:"Repla focus on brand reputation. Innovation is the main factor which differentiates you from your competitors in the market. With the help of research and development to apprehend the crucial needs of your marketplace and its ongoing traits, we effortlessly place your commercial enterprise in a role to cater important needs of the marketplace."
            }
        ]
    },
    {
        title:'SEO',
        img:"seo.jpg",
        paras:[
            {
                title:"Repla's Top-Rated SEO Services for the Software Market",
                subhead:"Our Effective SEO Strategies: Elevating Your Brand Reputation",
                description:`At Repla, we offer the most effective SEO (search engine optimization) service in the software market. Our white-hat strategies have proven successful for over five years, even though Google's regular algorithm adjustments.              
                `
            },
            {
                description:`But why does your company need SEO? To increase customer engagement, Repla provides viable and cost-effective SEO services. Organic search is a crucial part of most companies' website performance and the buyer funnel. Repla builds a strong foundation for a professional website with an impressive user experience that's easily accessible through search. Our effective SEO services consider the macro marketplace, your company's credibility, and its digital properties. Every brand wants maximum organic rankings and visibility to be at the top of the list. With our SEO strategies, we relay your messaging for the right offers, innovative services and products, and the importance and dependability of what you provide customers, revolutionizing your brand. Higher ratings and placement elevate your company's overall reputation.`
            },
            {
                description:`As your outsourced SEO partner, we provide a complete-scale assessment of your website's growth, rate analysis, lead monitoring, and reporting network. We then work with a professional SME from your industry to provide two unique pieces of content. Your inbound marketing will be nearly automated, attracting efficient leads, potential employees, business growth opportunities, and press.
                Experience matters in business enterprise SEO, and in our five years in the industry, we've helped businesses of various sizes meet their goals. Trust us to elevate your company's online presence and drive more conversions. Contact us today to learn more about our SEO services.  `
            },
            {
                title:"Fields and Technologies",
                description:<div>
                    <h6 className="brand-color">Fields</h6>
                    <ul>
                        <li>Search Engine Optimization (SEO)</li>
                        <li>Software Market</li>
                        <li>Inbound Marketing</li>
                        <li>Lead Generation</li>
                    </ul>
                    <h6 className="brand-color">Technologies</h6>
                    <ul>
                        <li>White-hat SEO strategies</li>
                        <li>Google algorithm</li>
                        <li>Macro marketplace</li>
                        <li>Credibility assessment</li>
                        <li>Digital properties analysis</li>
                        <li>Content creation</li>
                        <li>Lead monitoring and reporting network</li>
                    </ul>
                </div>
            },
        ],
        slider:[
            {
                title:'Effective White-Hat SEO Strategies',
                description:"Repla utilizes white-hat SEO strategies that have proven successful for over five years, even though Google's regular algorithm adjustments, to build a strong foundation for a professional website with an impressive user experience that's easily accessible through search."
            },
            {
                title:'Elevating Brand Reputation',
                description:` Repla's effective SEO strategies consider the macro marketplace, your company's credibility, and its digital properties to elevate your brand reputation. Every brand wants maximum organic rankings and visibility to be at the top of the list, and our strategies relay you’re messaging for the right offers, innovative services and products, and the importance and dependability of what you provide customers.`
            },
            {
                title:'Comprehensive SEO Services',
                description:`Repla provides a complete-scale assessment of your website's growth, rate analysis, lead monitoring, and reporting network. We then work with a professional SME from your industry to provide two unique pieces of content. Our comprehensive SEO services ensure that your company's online presence is elevated and drives more conversions.`
            }
        ],
        benefits:{
            title:"Our Significance",
            list:[
                "Increased Customer Engagement",
                "Cost-Effective SEO Services",
                "Higher Conversion Rates",
                "Expert Credibility Assessment",
                "Comprehensive Rate Analysis",
                "Professional Content Creation",
                "Efficient Lead Monitoring",
                "Effective Reporting Network",
                "Elevates Brand Reputation"
            ]
        },
        accordin:[
            {
                title:'How Repla helps to increases traffic with SEO?',
                description:"Repla focus to increase rankings. Once your rankings begin to increase, you will also begin to see gradual increases in traffic to your website."
            },
            {
                title:'How will Repla communicate changes made on sites and how often?',
                description:"Our effective SEO tactics translate several changes to your site, all occur with your approval. Whether your SEO strategy is being updated or created from scratch, it’s important to know what adjustments are being made on each page."
            }
        ]
    },
    {
        title:'Block Chain',
        img:"block-chain.jpg",
        paras:[
            {
                title:'Repla Technologies Your Trusted Partner for Blockchain Development Services',
                subhead:"Repla Technologies provides world-class blockchain development services with expertise in customized solutions, cutting-edge technology, and seamless integration.",
                description:"In today's digital age, blockchain technology is revolutionizing industries across the world. From finance to healthcare, blockchain is transforming the way businesses operate and interact with their customers. At Repla Technologies, we understand the importance of blockchain technology and its potential to change the world. That's why we offer cutting-edge blockchain development services that help businesses leverage the power of this revolutionary technology."
            },
            {
                title:"Why Choose Repla Technologies for Blockchain Development Services?",
                description:"At Repla Technologies, we are committed to delivering world-class blockchain development services that meet the unique needs of our clients. Our team of blockchain developers has extensive experience in developing blockchain solutions for businesses across various industries. We understand that every business has unique requirements, and that's why we offer customized blockchain solutions that are tailored to meet the specific needs of our clients. We use the latest technologies and tools to ensure that our blockchain solutions are up-to-date and meet the highest standards of quality. We ensure that our blockchain solutions seamlessly integrate with your existing systems, making it easy for you to adopt this revolutionary technology."
            },
            {
                title:"Fields and Technologies",
                description:`Repla Technologies works in various fields and technologies in blockchain development              Blockchain Consulting: Our team of experts provides blockchain consulting services to help businesses understand how blockchain technology can benefit their operations. Smart Contract Development: We develop smart contracts that automate processes and enable secure transactions on blockchain networks. Private Blockchain Development: We develop private blockchain networks that enable businesses to control access to their data and ensure its security. Decentralized Application (DApp) Development: We develop DApps that enable businesses to interact with their customers in new and innovative ways. If you're looking for a trusted partner for blockchain development services, look no further than Repla Technologies. With our expertise, customized solutions, cutting-edge technology, and seamless integration, we are your one-stop-shop for all your blockchain development needs.
                Contact us today to learn more about how we can help your business leverage the power of blockchain technology.
                `
            }
        ],
        slider:[
            {
                title:'Scalability',
                description:"Implement scalable solutions like sharding, sidechains, or layer-2 solutions."
            },
            {
                title:'Security',
                description:'Ensure strong security measures, including encryption, multi-factor authentication, and regular security audits.'
            },
            {
                title:'Flexibility and Adaptability',
                description:'Develop the blockchain application with flexibility and adaptability in mind'
            }
        ],
        benefits:{
            title:"Our Significance",
            list:[
                "Innovation",
                "Efficiency",
                "Transparency",
                "Security",
                "Decentralization"
            ]
        },
        accordin:[
            {
                title:'How can blockchain be used in the finance industry?',
                description:"Blockchain can be used in the finance industry to streamline payment processing, reduce fraud and cyberattacks, and improve compliance and regulatory reporting."
            },
            {
                title:'What are the benefits of using blockchain in healthcare?',
                description:"Blockchain can be used in healthcare to improve patient privacy and data security, streamline data sharing and interoperability, and reduce healthcare fraud and administrative costs."
            }
        ]
    },
    {
        title:'Game Development',
        img:"game-dev.jpg",
        paras:[
            {
                title:'Repla Technologies Creating Immersive and Engaging Games for All Platforms',
                subhead:"Leveraging the Latest Technologies and Best Practices",
                description:"Welcome to Repla Technologies, a leading game development company that creates immersive, engaging, and entertaining games for a variety of platforms. Our team of experienced designers, programmers, and artists are passionate about bringing your ideas to life, and delivering games that captivate and engage audiences of all ages. At Repla Technologies, we leverage the latest technologies and industry best practices to create games that stand out from the crowd. Whether you need a mobile game, a PC game, or a console game, our team has the skills and expertise to make it happen.We take pride in our ability to work closely with our clients to understand their unique needs, goals, and preferences, and then deliver customized solutions that exceed their expectations. Our approach is collaborative, transparent, and iterative, ensuring that you are always kept informed and involved in the development process. Thank you for considering Repla Technologies for your game development needs. We look forward to working with you to create unforgettable gaming experiences that engage, entertain, and inspire"
            },
            {
                title:"Fields and Technologies",
                description:"Repla Technologies uses various fields and technologies in game development, including game design with tools like Adobe Photoshop, Illustrator, and Unity, programming in languages such as C++, C#, and Java, art and animation with software like Maya, Blender, and Photoshop, sound design using digital audio workstations, virtual and augmented reality game development with Unity and Unreal Engine, and cloud and multiplayer gaming technologies like AWS and Microsoft Azure. The company is dedicated to using the latest technologies and methods to create innovative, immersive, and engaging games for clients."
            }
        ],
        slider:[
            {
                title:'Game Mechanics',
                description:"The rules, actions, and systems that define how the game is played, including gameplay mechanics, user interfaces, and controls."
            },
            {
                title:'Game Design',
                description:'The overall design of the game, including level design, character design, art style, and sound effects.'
            },
            {
                title:'Game Engine',
                description:'The software engine that powers the game, including the programming languages, tools, and frameworks used to develop the game.'
            }
        ],
        benefits:{
            title:"Our Significance",
            list:[
                "Access to skilled developers",
                "Customized game development",
                "Expertise in game engine development",
                "Incorporating emerging technologies"
            ]
        },
        accordin:[
            {
                title:'Why should I choose Repla for game development?',
                description:"Repla technologies has a team of skilled developers with expertise in various programming languages, game engines, and development tools. We have experience in developing games for various platforms, including PC, mobile, and console."
            },
            {
                title:'What kind of games can Repla develop?',
                description:"Repla can develop a wide range of games, including 2D and 3D games, casual games, mobile games, console games, multiplayer games, and virtual reality (VR) and augmented reality (AR) games. Our team is dedicated to providing customized solutions tailored to the specific needs of a game project."
            }
        ]
    },

    
]
export default data