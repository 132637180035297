import React, { useState } from 'react'
import {Row, Col, Typography,Space, Image} from 'antd';
import './gallery.css';
import GalleryData from './GalleryData.js'
import {BreadCrumb, SectionTop } from '../../ui-elements/index';



function Gallery() {

const [galleryList, setGalleryList] = useState(GalleryData)
  return (
    <div className='flex-col-center' style={{margin:"30px"}}>
        {/* <BreadCrumb title='FAQs' description='We Can Help You Succeed' pageName='FAQs' breadcrumb={[{to:'/',name:'Home'}]}/> */}
        <SectionTop title='OUR Gallery' description='We’re Here to Help You'/>
        <Row gutter={[32,32]} align="center" className="mb-5">
        {
            galleryList?.map((gallery,g)=>
            <Col xl={6} lg={6} md={8} sm={12} xs={12} className='text-center'>
                <Space className="gallery-page__single" key={'gallery-'+g}>
                    <Space className="gallery-page__img">
                        <Image src={'/assets/gallery/'+gallery?.galleryImage} alt="repla-bct"/>
                        <Space className="gallery-page__icon">
                            <a className="img-popup" href={'/assets/gallery/'+gallery?.galleryImage}>
                                <span className="icon-plus-symbol">
                                    {gallery.icon}
                                </span>
                            </a>
                        </Space>
                    </Space>
                </Space>
            </Col>
            )
        }
        </Row>
    </div>
  )
}

export default Gallery