import React, { Component } from 'react'
import HomeTopSection from '../../components/HomeTopSection'
import Services from './services'
import Moto from './moto'
import Industrywe from '../../components/industry'
import ReplaMission from '../../components/mission'
import ReplaIntro from '../../components/intro'
import Choose from '../../components/choose'
import Videoslider from '../../components/videoSlider'
import Technology from '../../components/technology'
import ReplaProjects from '../../components/projects'
import Feedback from '../../components/feedback'
import NewsSlider from '../../components/newsSlider'
import WhyBCT from '../../components/whyBCT'
import { ChooseNew, FocusProduct, ConceptSolution, PartnerComponent, TechnologyDomain, TechnologyComponent, CounterComponent, SuccessStories, ContactFormComponent } from '../../components'
class HomePage extends Component
{
    render()
    {
        return (
            <>
                <HomeTopSection/>
                <ReplaIntro/>
                <ReplaMission/>
                <ChooseNew/>
                <Moto/>
                {/* <Counter/>  */}
                {/* <Videoslider/> */}
                <Services/> 
                <Industrywe/>
                <Technology/>
                <WhyBCT/>
                <Feedback/>
                <NewsSlider/>
                <ReplaProjects/>
                <FocusProduct />
                <ConceptSolution />
                <PartnerComponent />
                <TechnologyDomain />
                <TechnologyComponent />
                <CounterComponent />
                <SuccessStories />
                <ContactFormComponent />
            </>
        )
    }
}
export default HomePage;
