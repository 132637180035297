import React from 'react'
import {Typography} from "antd"
import { Link } from "react-router-dom"
import './index.css'
const {Title}=Typography

const WaveButton = ({title, to}) => {
  return (
    <div className='wave my-5'>
      <div className="waves-block">
        <div className="waves wave-1"></div>
        <div className="waves wave-2"></div>
        <div className="waves wave-3"></div>
        <Link to={to || '/'}  className="talk-button">{title || 'Click Me'}</Link>
      </div>
    </div>
  )
}

export {WaveButton}