import React, { useState } from 'react'
import { Row, Col, Image, Space, Typography } from 'antd'
import { Link } from 'react-router-dom'
import './index.css'

const TechnologyComponent = () => {

    const [currentButton,setCurrentButton]= useState(0)


    const data= {
        head:[
            {
                title:"Front-end",
            },
            {
                title:"Back-end",
            },
            {
                title:"Mobile",
            },
            {
                title:"Cloud & platforms",
            },
        ],
        descriptiobody:[
            {
                title:'Front-end',
                cardcontent:[
                    {
                        icon:'react.svg',
                        title:'React',
                        desc:'React, Redux, Redux-Saga, RxJS, React-Native, Three.JS, NextJS',
                    },
                    {
                        icon:'angular.svg',
                        title:'Angular',
                        desc: 'Angular, NgRx, NGXS, MobX, Formly, Ionic'
                    },
                    {
                        icon:'vue.svg',
                        title:'Vue.js',
                        desc: 'Vue.js, Vuex, Vue Router, NuxtJS, Vue CLI, Vue Meta'
                    },
                    {
                        icon:'js.svg',
                        title:'Javascript',
                        desc: 'Material, Ant Design, Apollo GraphQL, Protractor, Jasmine, Jest, Enzyme, Mocha, Electron, SSR, Lodash, Date-FNS, CSS, SASS/SCSS, LESS, Lerna, TypeScript'
                    },
                ]
            },

            {
                title:'Back-end',
                cardcontent:[
                    {
                        icon:'java.svg',
                        title:'Java',
                        desc:'Java, Spring Framework (Core, Data, Boot, Batch, Cloud, Security, Retry, MVC, AOP, Reactor), MapStruct, Lombok, WebFlux, Hibernate, JPA2, Kafka, Flyway, Microservices Architecture, Docker, Kubernetes, PostgreSQL, MongoDB, Redis',
                    },
                    {
                        icon:'dot-net.svg',
                        title:'.Net',
                        desc: '.NET Core, ASP.NET Core MVC, ASP.NET Web API, ASP.NET MVC, Xamarin.Forms, WPF, UWP, Stateless Architecture, Microservices, Azure Cloud (App Service, IoT Hub, Event Hub)'
                    },
                    {
                        icon:'python.svg',
                        title:'Python',
                        desc: 'Django, Django Rest Framework, Flask, FastAPI, AsyncIo, AioHttp, Tornado, Celery, Microservices Architecture, Docker/Kubernetes, PostgreSQL, MySQL, MongoDB, Redis, DynamoDB'
                    },
                    {
                        icon:'php.svg',
                        title:'PHP',
                        desc: 'DUKPT, Laravel, Symfony, Yii2, WordPress, Drupal, MySQL, MongoDB, PostgreSQL, REST/SOAP/GraphQL API, OAuth, HTML, Twig, Swagger, Redis, ElasticSearch, Docker, Stripe, PayPal'
                    },
                    {
                        icon:'node.svg',
                        title:'Node.js',
                        desc: 'NestJS, Koa, MongoDB, PostgreSQL, AWS'
                    },
                    {
                        icon:'unity.svg',
                        title:'Unity',
                        desc: 'Unity, Godot, Blender, Krita, Amplify Shader Editor, MapBox SDK, Unity IAP;  Firebase SDK; ARCore, AR Foundation, ARKit, Vuforia; Google VR SDK,  Steam VR SDK;  Unity XR Platform SDK'
                    },
                    {
                        icon:'ruby.svg',
                        title:'Ruby',
                        desc: 'Ruby on Rails, Sinatra, Hanami, Padrino, Volt, EventMachine, Rack, Sidekiq, GraphQL, Web Socket, Swagger, JWT, OAuth'
                    },
                    {
                        icon:'go.svg',
                        title:'Go',
                        desc: 'Go, gORM, Gin,  protobuf, gRPC, AWS, GCP,  Docker, Redis, Mongo DB, PostgreSQL'
                    },
                    {
                        icon:'rust.svg',
                        title:'Rust',
                        desc: 'Rust, Rustup, Cargo, clap, structopt, argh, actix-web, gotham, nickel, rocket, axum, tide, warp, yew, wasm-bindgen, js-sys, web-sys, gloo. Games: amethyst, bevy, fyrox, piston, nannou, ggez, godot, raylib, SFML'
                    },
                    {
                        icon:'c++.svg',
                        title:'C/C++',
                        desc: 'STL/C++11/14/17/20, GCC, CLang, MSVC++, WinDBG, GDB, Visual Studio, QTCreator, VIM, VSCode, CMake, Makefile, Ninja, Conan, Vcpkg, Boost, opencv, poco, protobuf, gRPC, fmt, pybindll, spdlog, ranges_v3, tensorflow, opencl, Asio++, gtest/gmock, cppunit, QT, catch2, google profiler, pytorch c++, chromium'
                    },
                    {
                        icon:'unreal.svg',
                        title:'Unreal Engine',
                        desc: 'Unreal Engine, C++, C, C#, Blueprints, UAT, UE4 Shader Graph, Unity URP HLSL, OpenGL ES'
                    },
                ]
            },

            {
                title:'Mobile',
                cardcontent:[
                    {
                        icon:'ios.svg',
                        title:'IOS',
                        desc:'Objective-C/Swift, MVP, MVVM, Clean Swift, SnapKit, GCD, Alamofire, URLSession, MapKit, CoreLocation, XCTest, CoreData',
                    },
                    {
                        icon:'android.svg',
                        title:'Android',
                        desc: 'Java/Kotlin, MVP, MVVM, Clean Architecture, AAC (ViewModel, LiveData, Lifecycle, Navigation Component, Paging), Dagger2, Hilt, RxJava 2, Coroutines, Retrofit, ViewBinding, JUnit, Mockito, Robolectric, detekt, SQLite + Room'
                    },
                    {
                        icon:'flutter.svg',
                        title:'Flutter',
                        desc: 'Bloc, Clean Architecture, Flutter, Dart async, RxDart, Dart Streams, Navigator 2.0, GetIt, Mocktail, SQFlite'
                    },
                    {
                        icon:'react.svg',
                        title:'React',
                        desc: 'React-Native, React-Navigation, Redux, Redux-Saga, TypeScript, StyleSheet, Firebase, Styled-Components'
                    },
                    {
                        icon:'xam.svg',
                        title:'Xamarin',
                        desc: 'Xamarin.Native (Xamarin.iOS, Xamarin.Android, Xamarin.Mac), Xamarin.Forms, Xamarin.UWP, Visual Studio, Electron,  .NET Core, REST API backend'
                    },
                    {
                        icon:'net-maui.svg',
                        title:'.NET MAUI',
                        desc: '.NET MAUI, .NET MAUI Blazor, Visual Studio, .NET Core, REST/SOAP API backend, SQLite, XAML, Autofac'
                    },
                ]
            },
            {
                title:'Cloud & platforms',
                cardcontent:[
                    {
                        icon:'aws.svg',
                        title:'AWS',
                        desc:'Amazon  Elastic Compute Cloud (EC2),  Amazon Simple Storage Service (S3), Amazon Elastic Container Service (Amazon ECS), DynamoDB, Relational Database Service (RDS), AWS Lambda, ElastiCache, CloudSearch',
                    },
                    {
                        icon:'azure.svg',
                        title:'Azure',
                        desc: 'Azure Virtual Machines, Azure Web Apps, Azure Functions, Azure Blob Storage, Azure Notification Hub, Azure AI, Azure IoT Hub, Azure Event Hub, Azure Tables, Azure Queue, Azure DevOps Pipelines, Azure Content Delivery Network, Azure Application Insights, Azure Kubernetes, Azure SQL'
                    },
                    {
                        icon:'gcp.svg',
                        title:'GCP',
                        desc: 'Google Compute Engine, Google App Engine, Google Kubernetes Engine, Google Cloud Functions, Google Cloud SQL, Google Cloud Storage, Google Cloud Datastore, Google Cloud Bigtable'
                    },
                    {
                        icon:'sap.svg',
                        title:'SAP',
                        desc: 'SAP ABAP 7.5+, SAP UI5, SAP Fiori, JavaScript, HTML, XML, JSON, SAP S/4Hana(HANA DB, AMDP, CDS-extractors), SAP S/4Hana Cloud Public, SAP FI, SAP SD, SAP RE, SAP SCM, SAP HCM, SAP BTP ,SAP RAP and CAP,ADT Eclipse, SOAP, OData APIs, ABAP Proxy, FTP, SFTP, IDOC, DBOC,PBF Adobe form, XSTL, MWB, S/4Hana migration, ALV Grid, ALV Tree'
                    },
                    {
                        icon:'saleforce.svg',
                        title:'Salesforce',
                        desc: 'Salesforce, Apex code, Visualforce, Site.com, Customer 360 Platform, Lightning Web Components, REST/SOAP API'
                    },
                ]
            },
        ],
    }

  return (
    <div className='justify-center bg-tech-top'>
        <div className='w-93'>
            <Row gutter={[24,24]}>
                <Col lg={{span:24}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <div className='space-between w-100 mb-5'>
                        <Typography.Title level={1} className=''>
                            Technologies
                        </Typography.Title>
                        <Link to={'/'}>
                            <Typography.Title level={5} className='brand-color underline'>
                                All technologies
                            </Typography.Title>
                        </Link>
                    </div>
                </Col>
                <Col lg={{span: 8}} md={{span: 8}} xs={{span: 24}} sm={{span: 24}}>
                {
                    data?.head?.map((button,b)=>
                        <div className={b===currentButton?"Repla-button-nw-1 py-3 active-bordered":"Repla-button-nw-1 py-3"} onClick={()=>{setCurrentButton(b)}}>
                            <div className="one brand-bg animation" style={b===currentButton?{height:'100%'}:{}}></div>
                            <Typography.Title level={5} className={b===currentButton?'my-0 two text-active':'my-0 two text-inactive-1'}>{button?.title}</Typography.Title>
                        </div>
                    )
                }  
                </Col>      
                <Col lg={{span: 16}} md={{span: 16}} xs={{span: 24}} sm={{span: 24}}>
                    {
                        data?.descriptiobody?.map((det,dp)=>
                            <Row gutter={[24,24]} key={dp} className={currentButton===dp? 'active-content':'inactive-content'}>
                                <Col lg={{span:24}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                                    <Typography.Title level={3}>
                                        <Link to={'/'} className='brand-color'>
                                            {
                                                det?.title
                                            }
                                        </Link>
                                    </Typography.Title>
                                </Col>
                                {
                                    det?.cardcontent?.map((cd,d)=>
                                    <Col lg={{span: 8}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}} key={d} >
                                        <Link to={'/'} className='techblock p-3' style={{borderBottom:'1px solid gray'}} >
                                            <Space className='w-100 space-between' align='start' style={{flexWrap:'nowrap'}}>
                                                <div>
                                                    <Typography.Title level={5} className='m-0 pb-2'>
                                                        {
                                                            cd?.title
                                                        }
                                                    </Typography.Title>
                                                    <Typography.Text level={5}>
                                                        {
                                                            cd?.desc
                                                        }
                                                    </Typography.Text>
                                                </div>
                                                <div>
                                                    <Image src={'/assets/newimg/'+cd?.icon} preview={false} className='tech-icon' />
                                                    <div className='arr-c'>
                                                        <img src="/assets/newimg/arrow-red.svg" alt="" />
                                                    </div>
                                                </div>
                                            </Space>
                                            
                                            
                                        </Link>
                                    </Col>
                                    )
                                }
                            </Row>
                        )
                    }
                </Col>        
            </Row>
        </div>
    </div>
  )
}

export {TechnologyComponent}