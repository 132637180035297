import React from 'react'
import { Col, Row, Space, Typography } from 'antd'
import { Link } from 'react-router-dom'
import './index.css'

const SuccessStories = () => {

    const btndata = [
        {
            path:'/',
            btntext: 'Healthcare'
        },
        {
            path:'/',
            btntext: 'Education'
        },
        {
            path:'/',
            btntext: 'Medtech'
        },
        {
            path:'/',
            btntext: 'Java'
        },
        {
            path:'/',
            btntext: 'Rest'
        },
        {
            path:'/',
            btntext: 'Kotline'
        },
        {
            path:'/',
            btntext: 'Swift'
        },
    ]

    const btndata2 = [
        {
            path:'/',
            btntext: 'Blockchain'
        },
        {
            path:'/',
            btntext: 'NFT'
        },
        {
            path:'/',
            btntext: 'Smart contracts'
        },
        {
            path:'/',
            btntext: 'Marketplace'
        },
        {
            path:'/',
            btntext: 'Whitelabel'
        },
        {
            path:'/',
            btntext: 'Web'
        },
        {
            path:'/',
            btntext: 'Mobile'
        },
        {
            path:'/',
            btntext: 'PHP'
        },
        {
            path:'/',
            btntext: 'Laravel'
        },
        {
            path:'/',
            btntext: 'Vue.js'
        },
        {
            path:'/',
            btntext: 'Flutter'
        },
    ]


  return (
    <div className='justify-center mt-5 bg-story'>
        <div className='w-93'>
            <Row gutter={[24,24]}>
                <Col lg={{span: 24}} md={{span: 24}}>
                    <Typography.Title level={1} className='text-white'>
                        Success stories
                    </Typography.Title>
                </Col>
                <Col lg={{span: 24}} md={{span: 24}}>
                    <Link to={'/'} className='card-st-bg'>
                        <Row gutter={[24,24]} align='middle'>
                            <Col lg={{span: 16}} md={{span: 14}}>
                                <video 
                                    class="case-img"
                                    autoplay="autoplay" 
                                    loop="loop" 
                                    muted="muted" id="video" >
                                    <source 
                                        src="assets/newimg/voka.webm"
                                        type="video/mp4" />
                                </video>
                            </Col>
                            <Col lg={{span: 8}} md={{span: 10}}>
                                <Space direction='vertical' size={10} className='w-100 py-5'>
                                    <Typography.Title level={2} className='text-white'>
                                        VOKA Pathology 3D
                                    </Typography.Title>
                                    <Space className='btnSpace'>
                                        {
                                            btndata?.map((bdata,bd)=>
                                            <Link to={bdata?.path} className='btnSm'>
                                                {
                                                    bdata?.btntext
                                                }
                                            </Link>
                                            )
                                        }
                                    </Space>
                                    <div className='arrow-btn3'>
                                        <img src="/assets/newimg/white-arr.svg" alt="" />
                                    </div>
                                </Space>
                            </Col>
                        </Row>
                    </Link>
                </Col>
                <Col lg={{span: 10}} md={{span: 12}}>
                    <Link to={'/'} className='card-st-bg'>
                        <Row gutter={[24,24]} align='middle'>
                            <Col lg={{span: 24}} md={{span: 24}}>
                                <video 
                                    class="case-img"
                                    autoplay="autoplay" 
                                    loop="loop" 
                                    muted="muted" id="video" >
                                    <source 
                                        src="assets/newimg/nft-vedio.webm"
                                        type="video/mp4" />
                                </video>
                            </Col>
                            <Col lg={{span: 24}} md={{span: 24}}>
                                <Space direction='vertical' size={10} className='w-100 pb-5'>
                                    <Typography.Title level={2} className='text-white'>
                                        A whitelabel NFT marketplace solution for Web3 Pro™
                                    </Typography.Title>
                                    <Space className='btnSpace'>
                                        {
                                            btndata2?.map((bdat,bd)=>
                                            <Link to={bdat?.path} className='btnSm'>
                                                {
                                                    bdat?.btntext
                                                }
                                            </Link>
                                            )
                                        }
                                    </Space>
                                    <div className='arrow-btn3'>
                                        <img src="/assets/newimg/white-arr.svg" alt="" />
                                    </div>
                                </Space>
                            </Col>
                        </Row>
                    </Link>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export {SuccessStories}