import React from 'react'
import { Image, Space, Typography, List, Avatar } from 'antd'
import './index.css'
import {
    RightOutlined
} from '@ant-design/icons'
import { NavLink } from 'react-router-dom'

const { Title, Text } = Typography

const RightBlogBlock = () => {

    const data = [
        {
          title: <NavLink to={'/'} className='text-black'>Articles</NavLink>,
        },
        {
          title: <NavLink to={'/'} className='text-black'>Blog</NavLink>,
        },
        {
          title: <NavLink to={'/'} className='text-black'>Technologies</NavLink>,
        },
        {
          title: <NavLink to={'/'} className='text-black'>Softwares</NavLink>,
        },
      ];

  return (
    <div className='py-2'>
        <Space direction='vertical'>
            <Title level={5}>BLOG CATEGORIES</Title>
            <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item, index) => (
                <List.Item>
                    <List.Item.Meta
                    className='margin-0'
                    avatar={<Avatar  src={<Image src='/assets/next.png' />} />}
                    title={item.title}
                    />
                </List.Item>
                )}
            />
        </Space>
    </div>
  )
}

export default RightBlogBlock