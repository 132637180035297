import React from 'react'
import { Col, Form, Row, Space, Typography, Input, Button, Select, Image, Card } from 'antd'
import { Link } from 'react-router-dom'
import './index.css'
import { WaveButton } from '../WaveButton'
import { ButtonOne } from '../CustomButtons/ButtonOne'

const { Option } = Select
const ContactFormComponent = () => {

    const data = [
        {
            count: '1',
            desc:'Having received and processed your request, we will get back to you shortly to detail your project needs and sign an NDA to ensure the confidentiality of information.'
        },
        {
            count: '2',
            desc:' After examining requirements, our analysts and developers devise a project proposal with the scope of works, team size, time and cost estimates.'
        },
        {
            count: '3',
            desc:'We arrange a meeting with you to discuss the offer and come to an agreement.'
        },
        {
            count: '4',
            desc:'We sign a contract and start working on your project as quickly as possible.'
        },
    ]
  return (  
    <div className='justify-center my-5'>
        <div className='w-93'>
            <Row gutter={[64,24]} style={{justifyContent:'space-between'}}>
                <Col lg={{span: 12}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                    <Space direction='vertical' size={60}>
                        <Space direction='vertical' size={0}>
                            <Typography.Title level={1} className='secondary-color'>
                                Contact us!
                            </Typography.Title>
                            <Typography.Title level={5}>
                                <Link to={'/'} className='brand-color f-weight-normal'>Book a call</Link> or fill out the form below and we’ll get back to you once we’ve processed your request.
                            </Typography.Title>
                        </Space>
                        <Form layout="vertical"
                            >
                                <Row gutter={[16,16]}>
                                    <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                        <Form.Item
                                            name="name" 
                                            rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your name',
                                            },
                                            ]}
                                        >
                                            <Input 
                                                size='large'
                                                placeholder="Name" 
                                                className='border-0-bottom'
                                                value='' 
                                                />
                                        </Form.Item>
                                    </Col>
                                    <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                        <Form.Item
                                            name="email"
                                            rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your email',
                                            },
                                            ]}
                                        >
                                        <Input 
                                            size='large'
                                            placeholder="Email" 
                                            className='border-0-bottom'
                                            value=''
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                        <Form.Item
                                            name="coremail"
                                            rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your corporate email',
                                            },
                                            ]}
                                        >
                                        <Input 
                                            size='large'
                                            placeholder="Corporate Email"
                                            className='border-0-bottom' 
                                            value=''
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                        <Form.Item
                                            name="phone" 
                                            rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your phone no',
                                            },
                                            ]}
                                        >
                                            <Input 
                                                size='large'
                                                placeholder="Phone" 
                                                className='border-0-bottom'
                                                value='' 
                                                />
                                        </Form.Item>
                                    </Col>
                                    <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                        <Form.Item
                                            name="subject"
                                            rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your subject',
                                            },
                                            ]}
                                        >
                                            <Select
                                                placeholder="Subject"
                                                allowClear
                                                className='select-cs border-0-bottom'
                                            >
                                                <Option value="">IT staff augmentation</Option>
                                                <Option value="">Turnkey product development</Option>
                                                <Option value="">Support and enhancement</Option>
                                                <Option value="">Careers</Option>
                                                <Option value="">Other</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col md={{span:12}} sm={{span:24}} xs={{span:24}}>
                                        <Form.Item
                                            name="productbudget"
                                            rules={[
                                            {
                                                required: true,
                                                message: 'Select your product budget',
                                            },
                                            ]}
                                        >
                                            <Select
                                                placeholder="Product budget (min. $15K)"
                                                allowClear
                                                className='select-cs border-0-bottom'
                                            >
                                                <Option value="">$15K-$30K</Option>
                                                <Option value="">$15K-$100K</Option>
                                                <Option value="">$100K-$250K</Option>
                                                <Option value="">$250K-$500K</Option>
                                                <Option value="">more than $500K</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="message"
                                            rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your detail description',
                                            },
                                            ]}
                                        >
                                        <Input.TextArea
                                            rows={4}
                                            size='large'
                                            placeholder="Describe your need in detail" 
                                            className='border-0-bottom'
                                            value='' 
                                            />
                                        </Form.Item>
                                        <Typography.Text className='gray-sm-text'>
                                            Please include project details, duration, tech stack, IT professionals needed, and other relevant info
                                        </Typography.Text>
                                    </Col>
                                    <Col span={12}>
                                        <div className='block-border'>
                                            <Typography.Text>
                                                Record a voice message about your project to help us understand it better
                                            </Typography.Text>
                                            <Button className='c-button'>
                                                <Image src='/assets/microphone.png' width={'20px'} preview={false}/>
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className='block-border'>
                                            <Typography.Text>
                                                Record a voice message about your project to help us understand it better
                                            </Typography.Text>
                                            <Space align='center' className='w-100' style={{justifyContent:"center"}}>
                                                <Button className='c-button'>
                                                    Upload file
                                                </Button>
                                                <Image src='/assets/question.png' width={'20px'} preview={false} />
                                            </Space>
                                        </div>
                                    </Col>
                                </Row>
                                <Row gutter={[24,24]} className='justify-end mt-4'>
                                    <Col lg={{span: 18}} md={{span: 16}} xs={{span: 24}} sm={{span: 24}}>
                                        <Typography.Text className='gray-sm-text'>
                                            Please be informed that when you click the Send button Innowise will process your personal data in accordance with our Privacy Policy for the purpose of providing you with appropriate information.
                                        </Typography.Text>
                                    </Col>
                                    <Col lg={{span: 6}} md={{span: 8}} xs={{span: 24}} sm={{span: 24}}>
                                        <Button 
                                            htmlType='submit' 
                                            size='large' 
                                            type='primary'
                                            className='brand-bg brand-border w-100'
                                        >
                                            Send
                                        </Button>
                                    </Col>
                                </Row>
                        </Form>
                    </Space>
                </Col>
                <Col lg={{span: 11}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}}>
                    <Card className='card-block'>
                        <Space size={40} direction='vertical'>
                            <Typography.Title level={1} className='mt-5'>What happens next?</Typography.Title>
                            <div className='list-circle'>
                                <ul>
                                    {
                                        data?.map((datlst,ds)=>
                                        <li key={ds}>
                                            <div className='circle-count'>{datlst?.count}</div>
                                            {
                                                datlst?.desc
                                            }
                                        </li>
                                        )
                                    }
                                </ul>
                            </div>
                        </Space>
                    </Card>
                </Col>
            </Row>
        </div>
    </div>
  )
}

export {ContactFormComponent}