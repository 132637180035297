const Datafeed = [
    {
        topicon:"testimonial-two-icon.png",
        description:"We have been looking for a reliable tech business enterprise to develop our website and mobile app, and we were extremely satisfied with the services provided by Repla. Their team of skilled professionals delivered exactly what we were looking for, and we have continued to work with them on more and more projects",
        name:"Kevin martin",
        jobtitle: "Customer",
        imageu:"testimonial-2-1.jpg"
    },
    {
        topicon:"testimonial-two-icon.png",
        description:"Repla is one of the most professional IT companies we've worked with. Their proactive approach and ability to suggest improvements in architectural and enterprise ranges have impressed us. We know we can always rely on Repla's numerous competencies to deliver quality software that facilitates our commercial enterprise achievement.",
        name:"Jessica brown",
        jobtitle: "Customer",
        imageu:"testimonial-2-2.jpg"
    },
    {
        topicon:"testimonial-two-icon.png",
        description:"Repla's team comprises proficient engineers across Web UI, mobile, backend APIs, and system designs to digital marketing and beyond. Their commitment to customer satisfaction is evident in their clear and comprehensive approach to project challenges, budgeting, customer needs, and project revisions.",
        name:"Mike hardson",
        jobtitle: "Customer",
        imageu:"testimonial-2-3.jpg"
    },
    {
        topicon:"testimonial-two-icon.png",
        description:"We are fully satisfied with the services provided by Repla. They assisted us in developing web and mobile apps, complex UI/UX, and digital marketing services from scratch. Repla's innovative approach differentiates them from others, and we get exactly what we look for.",
        name:"Kevin martin",
        jobtitle: "Customer",
        imageu:"testimonial-2-1.jpg"
    },
    {
        topicon:"testimonial-two-icon.png",
        description:"We look forward to Repla because of their reputation and dedication towards their work. Their innovative and advanced technology and team of experts have played an important role in making Repla the number one IT Company. Repla is setting benchmarks in the IT field from software designing to development and digital solutions. We see a brighter future for Repla.",
        name:"Jessica brown",
        jobtitle: "Customer",
        imageu:"testimonial-2-2.jpg"
    },
    {
        topicon:"testimonial-two-icon.png",
        description:`What stood out to me the most was their commitment to understanding our unique business
        needs. Repla took the time to engage in comprehensive discussions, actively listening to our
        requirements and concerns. This personalized approach laid a solid foundation for a successful
        partnership.`,
        name:"Alexander",
        jobtitle: "Customer",
        imageu:"testimonial-2-4.jpg"
    },
    {
        topicon:"testimonial-two-icon.png",
        description:`Throughout the software development process, Repla's team demonstrated exceptional
        technical prowess. They transformed our ideas into a meticulously crafted and innovative
        solution that exceeded our expectations. Their attention to detail and dedication to producing
        high-quality work was evident in every aspect of the project.`,
        name:"Robert",
        jobtitle: "Customer",
        imageu:"testimonial-2-5.jpg"
    },
    {
        topicon:"testimonial-two-icon.png",
        description:`The final product delivered by Repla not only met our immediate needs but also showcased a
        forward-looking approach, ensuring scalability and adaptability for future growth. The smooth
        implementation and transition process further emphasized Repla's commitment to ensuring a
        seamless user experience.`,
        name:"Patricia",
        jobtitle: "Customer",
        imageu:"testimonial-2-6.jpg"
    },
    {
        topicon:"testimonial-two-icon.png",
        description:`Overall, my experience with Repla has been exceptional. Their team's technical competence,
        customer-centric approach, and dedication to excellence make them a standout partner in the IT
        industry. I wholeheartedly recommend Repla to any business seeking top-notch IT solutions and
        services.`,
        name:"Henary",
        jobtitle: "Customer",
        imageu:"testimonial-2-7.jpg"
    },
    
    
]
export default Datafeed