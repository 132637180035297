import React from 'react'
import { Space, Typography } from 'antd'
import './index.css'

const { Title } = Typography

const ComingSoon = () => {

  return (
    <div>
            <Space direction='vertical' className='center css-space' style={{textAlign:"center"}}>
              <Title level={1} className='brand-color'>Coming <br/> Soon</Title>
              <Title level={3}>
                See you Soon!!!
              </Title>
            </Space>
    </div>
  )
}

export {ComingSoon}
