export const DataVideo = [
    {
        video:'videogallery/1.mp4',
        title:'Product',
        linkvideo:'Our New Product',
    },
    {
        video:'videogallery/2.mp4',
        title:'Shoes',
        linkvideo:'Our New Shoes',
    },
    {
        video:'videogallery/3.mp4',
        title:'Sneakers',
        linkvideo:'Our New Sneakers',
    },
    {
        video:'videogallery/4.mp4',
        title:'Jumpsuit',
        linkvideo:'Our New Jumpsuit',
    },
    {
        video:'videogallery/5.mp4',
        title:'Product',
        linkvideo:'Our New Product',
    },
    {
        video:'videogallery/6.mp4',
        title:'Product',
        linkvideo:'Our New Product',
    },
    {
        video:'videogallery/7.mp4',
        title:'Product',
        linkvideo:'Our New Product',
    },
    {
        video:'videogallery/8.mp4',
        title:'Product',
        linkvideo:'Our New Product',
    },
    {
        video:'videogallery/9.mp4',
        title:'Product',
        linkvideo:'Our New Product',
    },
    {
        video:'videogallery/10.mp4',
        title:'Product',
        linkvideo:'Our New Product',
    },
]