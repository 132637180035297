import React from 'react'
import './index.css'
import {Row, Col, Space, Image, Typography} from 'antd'
import { data } from './data'
import { NavLink } from 'react-router-dom'
const {Title, Text}= Typography

const OfferProduct = () => {
  return (
    <div
        className="services-one py-5" 
        style={{backgroundImage: "url(assets/service/service-bg-1-1.png)"}}
    >
        <section className='py-5 flex-col-center'>
                <Row gutter={[64,32]} style={{width:'93%'}} className='mission-top-section mb-5'>
                    <Col xl={{span:12}} sm={{span:24}} xs={{span:24}}>
                        <Space>
                            <Image src='/assets/double-arrow.png' alt='blockchain technologies'/>
                            <Typography.Title level={3} className='brand-color my-0'>
                                {
                                    data?.subtitle
                                }
                            </Typography.Title>
                        </Space>
                        <Typography.Title leve={5} className='my-0 text-white'>
                            {
                                data?.title
                            }
                        </Typography.Title>
                    </Col>
                    <Col xl={{span:12}} sm={{span:24}} xs={{span:24}} className='align-center'>
                        <Typography.Title level={5}  className="text-white">
                            {
                                data?.description
                            }
                        </Typography.Title>
                    </Col>
                </Row>
                <Row style={{width:'93%',justifyContent:"center"}} gutter={[32,32]}>
                    {
                        data?.separatBlock?.map((data,d)=>
                        <Col xxl={{span:6}} xl={{span:6}} lg={{span:8}} md={{span:12}} sm={{span:24}} xs={{span:24}} key={d}>
                            <NavLink to={data?.path}>
                                <Space direction='vertical' className='w-100 align-center py-5 service-block animation' size={25}>
                                    <Image src={'assets/product/'+data?.topicon} alt='bct-services' preview={false}/>
                                    <Title level={4} className='text-white one my-0'>
                                        {
                                            data?.blocktitle
                                        }
                                    </Title>
                                    <Text className='text-white one' style={{textAlign:"center",display:"block",padding:"0px 5px"}}>
                                        {
                                            data?.blockdescription
                                        }
                                    </Text>
                                </Space>
                            </NavLink>
                        </Col>
                        )
                    }
                </Row>
        </section>
    </div>
  )
}

export {OfferProduct}