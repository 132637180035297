import React, { useState } from 'react'
import {Row, Col, Collapse, Typography,Space, Image} from 'antd';
import './index.css';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'
import faqData from './data';
import {BreadCrumb, SectionTop } from '../../ui-elements/index';
import {barndBorderColor, barndBorder} from "../../shared/index"
const { Title} = Typography;
const { Panel } = Collapse;
    function Faq() {
        const [faqList,setFatList]= useState(faqData)
        const [selectedFaq,setSelectedFaq]=useState(0)
        const [currentPanel,setCurrentPanel]=useState(['0'])
        return (
            <div>
                <BreadCrumb title='FAQs' description='We Can Help You Succeed' pageName='FAQs' breadcrumb={[{to:'/',name:'Home'}]}/>
                <div className='flex-col-center py-5'>
                    <SectionTop title='OUR FAQs' description='We’re Here to Help You'/>
                    <Row gutter={[32,8]} style={{width:'93%'}}>
                        <Col xl={6} lg={8} md={10} sm={24} xs={24} className='py-5'>
                            <Space direction='vertical' size={20} className='w-100'>
                                {
                                    faqList?.map((faq,f)=>
                                        <div key={'faq-'+f} className={(selectedFaq==f?'shadow shadow-sm p-3 border rounded-1':'p-3 border rounded-1')} style={{width:'100%',cursor:'pointer'}}  onClick={()=>{setSelectedFaq(f)}}>
                                            <Space size={15} className='w-100'>
                                                <Image src={'/assets/'+faq?.icon} alt="repla-bct"/>
                                                <Title level={5} className='my-0'>{faq?.name}</Title>
                                            </Space>
                                        </div>
                                    )
                                }
                            </Space>
                        </Col>
                        <Col xl={18} lg={16} md={14} sm={24} xs={24} className='py-5'>
                            <Space direction='vertical' style={{width:'100%'}} size={25}>
                                <Space size={20}>
                                    <Image src={'/assets/'+faqList[selectedFaq]?.icon}/>
                                    <Space direction='vertical' size={5}>
                                        <Title level={4} className='my-0'>{faqList[selectedFaq]?.name}</Title>
                                        <Typography.Text>{faqList[selectedFaq]?.description}</Typography.Text>
                                    </Space>
                                </Space>
                                <Collapse
                                    defaultActiveKey={['0']}
                                    onChange={(keys)=>{setCurrentPanel(keys)}}
                                >
                                    {
                                        faqList[selectedFaq]?.faqs?.map((faq,f)=>
                                            <Panel showArrow='false' header={faq?.name} key={f} extra={((currentPanel?.findIndex(x=>x==f))>-1)?<MinusCircleOutlined/>:<PlusCircleOutlined/>}>
                                                <div>{faq?.description}</div>
                                            </Panel>
                                        )
                                    }
                                </Collapse>
                            </Space>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }

export default Faq