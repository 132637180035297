import './index.css'
import { SectionTop } from '../../ui-elements'
import {Row, Col, Typography, Space, Image} from "antd"
import { FacebookFilled, LinkedinFilled, TwitterSquareFilled, InstagramFilled } from '@ant-design/icons'
const {Title}= Typography
const Profiles=()=>
    {
        const state=
            [
                {
                    name:'Muhammad Usman',
                    position:'Project Mananger',
                    profilePicture:'profile-1.jpg'
                },
                {
                    name:'Abdul Hanan',
                    position:'MERN Stack Developer',
                    profilePicture:'profile-2.jpg'
                },
                {
                    name:'Sofia',
                    position:'Graphic Designer',
                    profilePicture:'profile-3.jpg'
                }
            ]
        return(
            <div className='flex-col-center'>
                <div className='justify-center profile-bg'
                 style={{width:'100%',height:'350px',backgroundImage:`url(${'/assets/our-team-bg.jpg'})`}}
                 >
                    <div className='flex-col-center-center' style={{width:'97%'}}>
                        <Typography.Title level={2} className='brand-color my-0'>
                            Our Team
                        </Typography.Title>
                        <Typography.Title level={1} className='my-0 text-white text-center'>
                            Team of experienced professionals
                        </Typography.Title>
                    </div>
                </div>
                <div className='justify-center' style={{width:'97%',transform:'translateY(-100px)'}}>
                    <Row style={{width:'93%'}} gutter={[32,32]}>
                        {
                            state?.map((data, d)=>
                                <Col key={'profile-'+d} xl={{span:8}} lg={{span:8}} sm={{span:12}}>
                                    <div className="profile animation secondary-bg">
                                            <img 
                                                src={'/assets/team/'+data?.profilePicture} 
                                                alt='repla-team'
                                                className='one animation' 
                                                width={'100%'} 
                                                height='auto'/>
                                            <div className='two animation center'>
                                                <Space size={15} className='profile-info' direction='vertical'>
                                                    <div className='flex-col-center'>
                                                    <Title level={3} className='text-white mb-1'>{data?.name}</Title>
                                                    <Title level={5} className='text-white mt-0'>{data?.position}</Title>
                                                    </div>
                                                    <Space className='text-white justify-center w-100'>
                                                        <FacebookFilled style={{fontSize:'22px'}}
                                                            onClick={()=>{window.location.href='https://www.facebook.com/replatechechnologies'}}
                                                        />
                                                        <LinkedinFilled style={{fontSize:'22px'}}
                                                            onClick={()=>{window.location.href='https://www.linkedin.com/company/replatech/'}}
                                                        />
                                                        <TwitterSquareFilled style={{fontSize:'22px'}}
                                                            onClick={()=>{window.location.href='https://twitter.com/replatech'}}
                                                        />
                                                        <InstagramFilled style={{fontSize:'22px'}}
                                                            onClick={()=>{window.location.href='https://www.instagram.com/replatechnologies/'}}
                                                        />
                                                    </Space>
                                                </Space>
                                            </div>
                                    </div>
                                </Col>
                                )
                        } 
                    </Row>
                </div>
            </div>
        )
    }
export default Profiles;