import React,{useEffect} from 'react'
import { BreadCrumb } from '../../ui-elements/index';
import ContactUsForm from './contactus form'
import ThankYou from '../../components/ThankYou';
const ContactUs=()=>
    {
        return    (
            <>
              <BreadCrumb title='Contact Us' description='We Can Help You Succeed' pageName='Contact Us' breadcrumb={[{to:'/',name:'Home'}]}/>
              <div className="flex-col-center"> 
                <ContactUsForm/>
                <iframe title="pakolive-location" style={{width:"100%",height:"70vh",border:'none'}} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13287.87505581608!2d73.0652489!3d33.6320519!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8a237df750ecb3fc!2sRepla%20Security%20and%20Technology!5e0!3m2!1sen!2s!4v1664823529432!5m2!1sen!2s"  allowfullscreen="" loading="lazy"></iframe>
              </div>
          </>
        )
    }
export default ContactUs;
