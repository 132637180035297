import React from 'react'
import {Row, Col, Typography, Space, Image} from "antd"
const { Title, Text }=Typography

const CoreValue = () => {

    const data = {
        textoverimg:"Repla Core Value Here!",
        title:"Our core values",
        listpoint:[
            {
                icon:"checked.png",
                title:"Advance development",
                desc:"Our software development company prioritizes growth and innovation, encouraging our team members to continuously learn and grow. We believe that our daily pursuit of simple and elegant solutions sets us apart and leads to long-term success."
            },
            {
                icon:"checked.png",
                title:"Supremacy",
                desc:"At our company, we prioritize supremacy and strive for the best possible outcome in every task and interaction. We understand that clarity is key to achieving excellence, and we provide context to every task, including supporting evidence, convictions, stakeholders, and logic/strategy used to reach a conclusion."
            },
            {
                icon:"checked.png",
                title:"Enthusiasm",
                desc:"We know that all of the preceding principles are best well worth anything if you're focused on the mission, the team and the work itself. Every team member at Repla is dedicated to making Repla the absolute best experience, both internally and externally."
            },
        ]
    }

  return (
    <div className="justify-center light-bg intro py-5">
                <Row gutter={[64,32]} style={{width:'93%'}} className='my-5'>
                    <Col xl={{span:9}} lg={{span:24}} md={{span:24}} sm={{span:24}} xs={{span:24}}>
                        <div className="intro-left">
                            <Image src='/assets/intro.jpg' alt='repla' width={'100%'} preview={false}/>
                            <p className="py-4 px-3">
                                {data?.textoverimg}
                            </p>
                        </div>
                    </Col>
                    <Col xl={{span:15}} lg={{span:24}} md={{span:24}} sm={{span:24}} xs={{span:24}} className='align-center'>
                        <Space direction='vertical' size={30}>
                            <div>
                                <Title level={1} className='my-0'>
                                    {data?.title}
                                </Title>
                            </div>
                            <Row gutter={[12,24]}>
                                {
                                    data?.listpoint?.map((listpt,pt)=>
                                        <Col span={24}>
                                            <Space direction='vertical' size={30}>
                                                <Space>
                                                    <img src={'/assets/'+listpt?.icon} width={'20px'}/>
                                                    <Title level={5} className='my-0'>{listpt?.title}</Title>
                                                </Space>
                                                <Text style={{textAlign:"justify",display:"block"}}>
                                                    {listpt?.desc}
                                                </Text>
                                            </Space>
                                        </Col>
                                    )
                                }
                            </Row>
                        </Space>
                    </Col>
                </Row>
            </div>
  )
}

export {CoreValue}