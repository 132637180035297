import {Row, Col, Typography, Space, Image, Descriptions} from "antd"
import { useState } from "react"
import './index.css'
const {Title}=Typography
const Choose=()=>{
    const [currentButton,setCurrentButton]= useState(0)
    const state= {
        head:[
            {
                title:"Our Mission",
            },
            {
                title:"Our Vision",
            },
            {
                title:"Our History",
            }
        ],
        descriptiobody:[
            {
                description:"At Repla, we strive to provide top-notch IT services and products that guarantee customer satisfaction. We are dedicated to delivering innovative solutions that help businesses achieve their goals."
            },
            {
                description:"Our vision is to empower youth by giving them IT opportunities. We believe in using innovative technology to help businesses grow and stay competitive in the market."
            },
            {
                description:"Over the years, Repla has established itself as a leading IT company that offers quality products and services. Our innovative solutions have helped many businesses achieve remarkable growth and profit. We work with innovative organizations to help them accelerate their growth and achieve their goals."
            }
        ],
        imgetb:[
            {
                img:'tab1.jpg',
            },
            {
                img:'tab2.jpg',
            },
            {
                img:'tab3.jpg',
            },
        ],
        bulletpoints:
            [
                [
                    {
                        pt: "Focus on providing high-quality IT services and products that exceed customer expectations"
                    },
                    {
                        pt: "Continuously innovate and improve IT solutions to meet evolving business needs"
                    },
                    {
                        pt: "Offer customized IT solutions that are tailored to each client's unique requirements and goals"
                    },
                    {
                        pt: "Invest in research and development to stay ahead of market trends and emerging technologies"
                    },
                ],
                [
                    {
                        pt: "Offer IT training and education programs to youth"
                    },
                    {
                        pt: "Provide internship and job opportunities in the IT industry for young people"
                    },
                    {
                        pt: "Leverage technology to create social impact by addressing community needs and challenges."
                    },
                    {
                        pt: "Foster a culture of creativity and collaboration to encourage innovative thinking and problem-solving"
                    },
                ],
                [
                    {
                        pt: "Continuously innovate and improve IT solutions to meet evolving business needs"
                    },
                    {
                        pt: "Collaborate with innovative organizations to drive growth and mutual success"
                    },
                    {
                        pt: "Expand service offerings to meet the needs of a broader range of businesses and industries"
                    },
                    {
                        pt: "Leverage past successes and case studies to attract new clients and build brand recognition"
                    },
                ],
            ],
        
    }
    return (
            <div className="justify-center py-5">
                <Row gutter={[32,32]} style={{width:'93%'}} className='my-5'>
                    <Col xl={{span:24}} lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}} className='align-center'>
                        <Space direction='vertical' className="w-100" size={40}>
                            <div className="text-center">
                                <Title level={2} className='brand-color'>Why Prioritize Repla?</Title>
                                <Title level={4} className='my-0'>
                                Repla - Providing Top-Notch IT Services - Empowering Business through Innovative Technology
                                </Title>
                            </div>
                            <Row gutter={[8,0]}>
                                {
                                    state?.head?.map((button,b)=>
                                    
                                    <Col sm={{span:8}} xs={{span:24}} md={{span:8}} key={b}>
                                        <div className="Repla-button py-3" onClick={()=>{setCurrentButton(b)}}>
                                            <div className="one brand-bg animation" style={b===currentButton?{height:'100%'}:{}}></div>
                                            <Title level={5} className='my-0 two' style={b===currentButton?{color:'white'}:{}}>{button?.title}</Title>
                                        </div>
                                    </Col>
                                    )
                                }
                            </Row>
                            <div>
                                {
                                    state?.descriptiobody?.map((descrp,dp)=>
                                        <p key={dp} className={currentButton===dp? 'active-tabs':'inactive-tabs'}>
                                            {descrp?.description}
                                        </p>
                                    )
                                }
                            </div>
                        </Space>
                    </Col>
                    <Col xl={{span:24}} lg={{span:12}} md={{span:24}} sm={{span:24}} xs={{span:24}} className='align-center'>
                        <Space direction='vertical' size={30} className="light-bg px-2 py-2 w-100">    
                            <div className="text-center">
                                
                                {
                                    state?.head?.map((button,b)=>
                                     <Title level={2}  className={currentButton===b? 'active-tabs my-0':'inactive-tabs my-0'}>{button?.title}</Title>
                                    )
                                }
                                <br />
                                {
                                    state?.descriptiobody?.map((descrp,dp)=>
                                        <p key={dp} className={currentButton===dp? 'active-tabs':'inactive-tabs'}>
                                            {descrp?.description}
                                        </p>
                                    )
                                }
                            </div>
                            <Row gutter={[12,24]} className="align-center justify-center">
                                <Col xl={{span:16}} lg={{span:16}} md={{span:12}} sm={{span:24}}>
                                    <Space align="center">
                                        {
                                            state?.imgetb?.map((im,i)=>
                                            <img src={'/assets/'+im?.img} alt='repla-mission' key={i} className={currentButton===i?'active-tabs w-200':'inactive-tabs w-200'}/>
                                            )
                                        }

                                        <Space direction="vertical">
                                            {
                                                state?.bulletpoints[currentButton]?.map((pts,p)=>
                                                <Space key={p} className={currentButton===p? 'active-tabs ':'inactive-tabs'} style={{alignItems:"flex-start"}}>
                                                    <Image src='/assets/tick.png' alt="bct" style={{width: "15px"}}/>
                                                    <p className="my-0 fs-12">
                                                        {
                                                            pts?.pt
                                                        }
                                                    </p>
                                                </Space>
                                                )
                                            }
                                        </Space>
                                    </Space>
                                </Col>
                            </Row>
                        </Space>
                    </Col>
                </Row>
            </div>
    )
}
export default Choose