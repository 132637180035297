import React from 'react'
import { Col, Row, Space, Typography } from 'antd'
import CountUp from "react-countup";
import './index.css'

const CounterComponent = () => {

    const data = [
        {
            count:'17 ',
            sign:'+',
            title:'years of expertise'
        },
        {
            count:'1600',
            sign:'+',
            title:'IT professionals'
        },
        {
            count:'93',
            sign:'%',
            title:'recurring customers'
        },
        {
            count:'1100',
            sign:'+',
            title:'successful projects'
        },
    ]

  return (
    <div className='justify-center my-5 bg-count'>
        <div className='w-93'>
            <Row gutter={[32,24]}>
                {
                    data?.map((data,d)=>
                    <Col lg={{span: 6}} md={{span: 12}} xs={{span: 24}} sm={{span: 24}} >
                        <Space direction='vertical'  align='center' className='countspace'>
                            <Typography.Title level={1} className='text-white fontst m-0'>
                                <CountUp duration={10} end={data?.count}  /> 
                                {
                                    data?.sign
                                }
                            </Typography.Title>
                            <Typography.Text className='text-white'>
                                {
                                    data?.title
                                }
                            </Typography.Text>
                        </Space>
                    </Col>
                    )
                }
            </Row>
        </div>
    </div>
  )
}

export {CounterComponent}