import React,{ useState } from 'react'
import { Typography } from 'antd'
import { Link } from 'react-router-dom'
import './index.css'

const { Text } = Typography

const ZoomBtn = () => {

    const [textshow, setTextShow] = useState(false)

    const handleMouseOver = () => {
        setTextShow(true);
      };
    
      const handleMouseOut = () => {
        setTextShow(false);
      };

  return (
    <div>
        <Link to={'/'} className={textshow?'hoverzoombtn active':'hoverzoombtn'} onMouseOver={handleMouseOver}
         onMouseOut ={handleMouseOut} >
            <Text className={textshow?'showtex fs-text':"hidetex fs-text"}>Zoom Meeting Room</Text>
            <img src="/assets/zoom.png" alt="" style={{width: '45px'}} />
        </Link>
    </div>
  )
}

export {ZoomBtn}