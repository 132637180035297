import './index.css'
import {Row, Col, Typography, Space, Image} from "antd"
import { SectionTop } from '../../ui-elements'
import AllServices from './allServices'
const Services=()=>
    {
        return(
            <>
             <div className="py-5 w-100">
                <div className='flex-col-center my-5'>
                    <SectionTop title=' Our Services' description='We provide innovative & creative services.'/>
                    <div style={{width:'93%'}}>
                        <AllServices/>
                    </div>
                </div>
             </div>
            </>
        )
    }
export default Services;